import React from "react";
import { CRow, CCol, CWidgetProgressIcon } from "@coreui/react";
import CIcon from "@coreui/icons-react";

const WidgetsDropdown = ({ data, loading }) => {
  return (
    <div className="flex-col-center">
      <div>
        <h4>Coach Activity</h4>
        <CRow>
          <CCol sm="4" lg="3">
            <CWidgetProgressIcon
              header={
                loading ? (
                  <div className="spinner-border text-dark" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  `${data ? data.totalFreeSessions : 0}`
                )
              }
              text={<h6>Total Free Session</h6>}
              color="gradient-success"
              inverse
            >
              <CIcon name="cil-people" height="36" />
            </CWidgetProgressIcon>
          </CCol>
          <CCol sm="4" lg="3">
            <CWidgetProgressIcon
              header={
                loading ? (
                  <div className="spinner-border text-dark" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  `${data ? data.totalPaidSessions : 0}`
                )
              }
              text={<h6>Total Paid Session</h6>}
              color="gradient-success"
              inverse
            >
              <CIcon name="cil-people" height="36" />
            </CWidgetProgressIcon>
          </CCol>
          <CCol sm="4" lg="3">
            <CWidgetProgressIcon
              header={
                loading ? (
                  <div className="spinner-border text-dark" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  `${
                    data
                      ? data.avgConversionRate === null
                        ? 0
                        : data.avgConversionRate.toFixed(2)
                      : 0
                  }`
                )
              }
              text={<h6>Conversion rate</h6>}
              color="gradient-success"
              inverse
            >
              <CIcon name="cil-people" height="36" />
            </CWidgetProgressIcon>
          </CCol>
        </CRow>
      </div>
      <div>
        <h4>Users</h4>
        <CRow>
          <CCol sm="4" lg="3">
            <CWidgetProgressIcon
              header={
                loading ? (
                  <div className="spinner-border text-dark" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  `${data ? data.totalCoach : 0}`
                )
              }
              text={<h6>Total Coach</h6>}
              color="gradient-success"
              inverse
            >
              <CIcon name="cil-people" height="36" />
            </CWidgetProgressIcon>
          </CCol>

          <CCol sm="4" lg="3">
            <CWidgetProgressIcon
              header={
                loading ? (
                  <div className="spinner-border text-dark" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  `${data ? data.avgPaidSession===null || data.avgPaidSession===0?0: data.avgPaidSession.toFixed(2) : 0}`
                )
              }
              text={<h6>Average Paid Session</h6>}
              color="gradient-success"
              inverse
            >
              <CIcon name="cil-people" height="36" />
            </CWidgetProgressIcon>
          </CCol>
          <CCol sm="4" lg="3">
            <CWidgetProgressIcon
              header={
                loading ? (
                  <div className="spinner-border text-dark" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  `${data ? data.avgFreeSession===null || data.avgFreeSession===0?0:data.avgFreeSession.toFixed(2) : 0}`
                )
              }
              text={<h6>Average Free Session</h6>}
              color="gradient-success"
              inverse
            >
              <CIcon name="cil-people" height="36" />
            </CWidgetProgressIcon>
          </CCol>
        </CRow>
      </div>
    </div>
  );
};

export default WidgetsDropdown;
