 import React, { useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';

const callAfterStop = (sec, min, hrs) => {
    
    let str;
    if (hrs) {
        str = hrs + "hr " + min + "min "
        return str;
    }
    if (min) {
        str = min + "min " + sec + "s"
        return str;
    }

    return sec + "s"

}

function Timer(props) {
    const {

        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({ autoStart: true, });

    useEffect(() => {
        if (props.stop === true) {
            pause();
            const res = callAfterStop(seconds, minutes, hours);
            
            props.sendChatInfo("Call " + res, props.startCallTime)
        }
    }

        , [props.stop])


    return (
        !props.isVideo ?
            <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '15px' }}>
                    <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
                </div>
            </div>
            : <div></div>);
}

export default Timer