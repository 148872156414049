import {
    chatNodeExist,
    createChatNode,
    addMessage,
    getMessageForRoom,
} from "../components/Queries";

class ChatsMsg {

    createChatNode = (node, members) => {
        const executor = async (resolve, reject) => {
            try {
                const nodeExists = await chatNodeExist(node);
                if (!nodeExists) {
                    await createChatNode(node, members);
                }
                resolve({ result: "done", value: "now added" });
            } catch (error) {
                console.log("Error inside addPartner ", error);
                reject(error);
            }
        };
        return new Promise(executor);
    }

    sendMsgOnChatNode = (node, message, members) => {
        const executor = async (resolve, reject) => {
            try {
                
                const messageAdded = await addMessage(node, message);
                resolve({ result: "done", value: "now added", id: messageAdded.id });
            } catch (error) {
                console.log("Error inside addPartner ", error);
                reject(error);
            }
        };
        return new Promise(executor);
    }

    loadEarlier = (node) => {
        const executor = async (resolve, reject) => {
            try {
                const messageAdded = await getMessageForRoom(node);
                resolve(messageAdded);
            } catch (error) {
                console.log("Error inside addPartner ", error);
                reject(error);
            }
        };
        return new Promise(executor);
    };
}

export default ChatsMsg;