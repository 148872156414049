import * as actions from '../type.action'
const initialState = {
    data: null
}

const profileData = (state, action) => {
    if (state === undefined) {
        return (state = initialState)
    }
    switch (action.type) {
        case actions.PROFILE_DATA:
            return {
                ...state,
                data: action.payload
            }
        default:
            return state
    }
}
export default profileData
