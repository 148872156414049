import { compose,createStore } from 'redux';
import rootReducer from '../src/redux/reducer/index';

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);

        localStorage.setItem('state', serializedState);
    } catch (err) {
        
        console.log(err)
    }
};

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

const persistedState = loadState();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;



const configureStore = createStore(rootReducer, persistedState, composeEnhancers())
configureStore.subscribe(() => saveState(configureStore.getState()))

export default configureStore