const firebaseServerKey = process.env.REACT_APP_FIREBASE_SERVER_KEY
const BASE_URL = "https://fcm.googleapis.com/";
const ENDPOINTS = "fcm/send";

function serviceRequest(url, method = "GET", headers = {}, body = undefined) {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: method,
            headers: headers,
            body: body
        }).then(processResponse)
            .then((responseJson) => {
                if (responseJson.statusCode == 200) {
                    resolve(responseJson.data);
                }
                else {
                    reject(responseJson.data);
                }
            }).catch((error) => {
                console.log(error);
                reject({ error: { message: error.message } });
            });
    });
}

function processResponse(response) {
    return response.json()
        .then((responseJson) => {
            const statusCode = response.status;
            const data = responseJson;
            return Promise.all([statusCode, data]).then(res => ({
                statusCode: res[0],
                data: res[1]
            }))
        }).catch((error) => {
            return { statusCode: 201, data: { error: { message: "Unable to process your request. Please try again later." } } }
        });
}

/**
 * This method is used to send fcm notification to a user with given token.
 * @param {String} fcmToken 
 * @param {String} title 
 * @param {String} body 
 * @param {Object} data 
 */
export function sendFcmNotification(fcmToken, title = "title", body = "body", data = {}) {
    return serviceRequest(
        BASE_URL + ENDPOINTS,
        "POST",
        {
            "Content-Type": "application/json",
            "Authorization": `key=${firebaseServerKey}`
        },
        JSON.stringify({
            to: fcmToken,
            notification: {
                title,
                body,
            },
            data: {
                ...data,
                title,
                body,
            },
        }),
    );
}

