import React, { useEffect, useState } from "react";
import {
  CFormGroup,
  CLabel,
  CInput,
  CSwitch,
  CCardBody,
  CForm,
  CRow,
  CCol,
  CCard,
  CButton,
  CCardHeader,
  CModal,
  CModalHeader,
} from "@coreui/react";

import { useFormik } from "formik";
import { roleValidation } from "../../reusable/validation";
import { useHistory, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { getRequest, putRequest } from "src/utils/AxiosUtils";

export default function ViewRole() {
  const params = useParams();

  const history = useHistory();
  const [fields, setFields] = useState([{ name: "", email: "" }]);
  const [fieldsError, setFieldsError] = useState([{ name: "", email: "" }]);
  const [dashboard, setDashboard] = useState(null);
  const [coachAdministration, setCoachAdministration] = useState(null);
  const [administrationManagement, setAdministrationManagement] =
    useState(null);
  const [staticContent, setStaticContent] = useState(null);
  const [sessionContent, setSessionContent] = useState(null);
  const [errorModal, setErrorModal] = useState(null);
  const [roleName, setRoleName] = useState("");

  const initialValues = {
    name: "",
  };

  const onSubmit = async (values, actions) => {
    let error = false;

    let module_wise_permissions = [];

    if (dashboard?.module === "Dashboard") {
      module_wise_permissions.push(dashboard);
    }
    if (coachAdministration) {
      module_wise_permissions.push(coachAdministration);
    }
    if (administrationManagement) {
      module_wise_permissions.push(administrationManagement);
    }
    if (staticContent) {
      module_wise_permissions.push(staticContent);
    }
    if (sessionContent) {
      module_wise_permissions.push(sessionContent);
    }

    let body = {
      id: params.id,
      admins: fields,
      role_name: values.name,
      module_wise_permissions,
    };

    if (body.role_name === roleName) {
      delete body.role_name;
    }

    body.admins.forEach((item) => {
      if (!item?.name || !item?.email) {
        setErrorModal("Please complete all the users details");
        error = true;
     
      }
    });

    if (error) return;

    body.module_wise_permissions.forEach((item) => {
      if (item?.permissions?.length === 0) {
        setErrorModal("Please add permissions for all enabled modules");
        error = true;
      }
    });
    if (error) return;

    try {
      const result = await putRequest(`updateAdminAndRole`, body);
      if (result.status === 200) {
  

        if (result.data.body.data.Already_exist_admins.length > 0) {
          let alreadyExistData = result?.data?.body?.data?.Already_exist_admins;
          fields.forEach((field, index) => {
            if (alreadyExistData[0].email === field.email) {
              const errors = [...fieldsError];
              errors[index].email = "This email already exist";
              setFieldsError(errors);
           
            }
          });
          return;
        }
      }
    } catch (err) {
   console.log(err)
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema: roleValidation,
  });

  useEffect(() => {
    const getRole = async () => {
      const res = await getRequest(`viewRoleDetails?role_id=${params.id}`);
      if (res.status === 200) {
        formik.setFieldValue("name", res?.data?.body?.data?.role_name);
        setRoleName(res?.data?.body?.data?.role_name);
        setFields(res?.data?.body?.data?.bluetango_admins);
        res?.data?.body?.data?.bluetango_admins.forEach((item, index) => {
          if (index === 0) {
            return;
          }
          setFieldsError((state) => [...state, { name: "", email: "" }]);
        });
        res?.data?.body?.data?.module_wise_permissions.forEach((perm) => {
          if (perm.module === "Dashboard") {
            setDashboard(perm);
          }
          if (perm.module === "Coach Administration") {
            setCoachAdministration(perm);
          }
          if (perm.module === "Administration Management") {
            setAdministrationManagement(perm);
          }
          if (perm.module === "Session Content") {
            setSessionContent(perm);
          }
          if (perm.module === "Static Content") {
            setStaticContent(perm);
          }
        });
      }
    };
    getRole();
  }, [params.id]);

  function handleChange(i, event) {
    const values = [...fields];
    const errors = [...fieldsError];
    errors[i].name = "";

    setFieldsError(errors);

    if (event.target.value === "" || event.target.value === null) {
      errors[i].name = "Please enter this user's name ";
      setFieldsError(errors);
    }

    if (event.target.value.length > 50) {
      errors[i].name = "Description must be less than 50 characters";
      setFieldsError(errors);
    }

    values[i].name = event.target.value;
    setFields(values);
  }

  function handleEmailChange(i, event) {
    const values = [...fields];
    const errors = [...fieldsError];
    errors[i].email = "";

    setFieldsError(errors);

    if (event.target.value === "" || event.target.value === null) {
      errors[i].email = "Please enter this user's email";
      setFieldsError(errors);
    }

    if (event.target.value > 50) {
      errors[i].name = "Email must be less than 50 characters";
      setFieldsError(errors);
    }

    values[i].email = event.target.value;
    setFields(values);
  }



  return (
    <div>
      <CRow>
        <CCol xl="12" lg="12">
          <CModal
            show={errorModal}
            backdrop={false}
            color="warning"
            onClose={() => setErrorModal(false)}
          >
            <CModalHeader closeButton>{errorModal}</CModalHeader>
          </CModal>
          <CForm onSubmit={formik.handleSubmit} autoComplete="off">
            <CCard>
              <CCardHeader
                className="admin-management-ccardheader"
              
              >
                <h3>{<strong>View Role</strong>}</h3>
                <CButton
                  className="width-8rem"
               
                  color="primary"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <strong>Back</strong>
                </CButton>
              </CCardHeader>
              <CCardBody>
                <CFormGroup>
                  <CLabel htmlFor="name">Role Name</CLabel>
                  <CInput
                    autoComplete="new-password"
                    type="name"
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    disabled={true}
                    placeholder="Enter Role Name.."
                    className="width-93"
                   
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="email-validate">{formik.errors.name}</div>
                  ) : null}
                </CFormGroup>
                <CFormGroup>
                  <CLabel htmlFor="description">Admins</CLabel>
                  <ul>
                    {fields.map((field, idx) => {
                      return (
                        <li>
                          <div>
                            <div
                              key={`${field}-${idx}`}
                              className="flex-1rem"
                            
                            >
                              <div
                                className="addrole-inline-css-div2"
                               
                              >
                                <CInput
                                  className="margin-right-1rem"
                                
                                  type="text"
                                  value={fields[idx].name}
                                  onBlur={(e) => handleChange(idx, e)}
                                  placeholder="Enter User Name"
                                  onChange={(e) => handleChange(idx, e)}
                                  disabled={true}
                                />
                                {fieldsError &&
                                fieldsError[idx] &&
                                fieldsError[idx].name ? (
                                  <div className="email-validate">
                                    {fieldsError[idx].name}
                                  </div>
                                ) : null}
                              </div>
                              <div
                                className="width-45"
                              
                              >
                                <CInput
                                  className="margin-right-1rem"
                                 
                                  type="text"
                                  value={fields[idx].email}
                                  onBlur={(e) => handleEmailChange(idx, e)}
                                  placeholder="Enter User Email"
                                  onChange={(e) => handleEmailChange(idx, e)}
                                  disabled={true}
                                />
                                {fieldsError &&
                                fieldsError[idx] &&
                                fieldsError[idx].email ? (
                                  <div className="email-validate">
                                    {fieldsError[idx].email}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </CFormGroup>

                <CFormGroup
                  className="addrole-cfrom-group"
                
                >
                  <div className="addrole-inline-addmin-div">
                    <div className="add-role-admin-inline-css">
                      <CSwitch
                        size="sm"
                        variant={"3d"}
                        disabled={true}
                        checked={dashboard?.module === "Dashboard"}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setDashboard({
                              module: "Dashboard",
                              permissions: [],
                            });
                          } else {
                            setDashboard(null);
                          }
                        }}
                        color={"success"}
                      />
                      <div className="addrole-admin-inline-css-div2">
                        Dashboard
                      </div>
                    </div>
                    <div className="addrole-inline-css-checkbox1">
                      <div 
                      className="addrole-inline-css-checkbox2"
                        
                      >
                        <div className="font-size-1rem">
                          <strong>Create</strong>
                        </div>
                        <input 
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          disabled={true}
                          checked={dashboard?.permissions.includes(1)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (dashboard?.permissions) {
                                setDashboard((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 1],
                                }));
                              }
                            } else {
                              if (dashboard?.permissions) {
                                setDashboard((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 1
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>View</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          disabled={true}
                          checked={dashboard?.permissions.includes(2)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (dashboard?.permissions) {
                                setDashboard((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 2],
                                }));
                              }
                            } else {
                              if (dashboard?.permissions) {
                                setDashboard((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 2
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>Edit</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          disabled={true}
                          checked={dashboard?.permissions.includes(3)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (dashboard?.permissions) {
                                setDashboard((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 3],
                                }));
                              }
                            } else {
                              if (dashboard?.permissions) {
                                setDashboard((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 3
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>Delete</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          disabled={true}
                          checked={dashboard?.permissions.includes(4)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (dashboard?.permissions) {
                                setDashboard((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 4],
                                }));
                              }
                            } else {
                              if (dashboard?.permissions) {
                                setDashboard((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 4
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="addrole-inline-addmin-div">
                    <div className="add-role-admin-inline-css">
                      <CSwitch
                        size="sm"
                        variant={"3d"}
                        disabled={true}
                        checked={
                          coachAdministration?.module === "Coach Administration"
                        }
                        onChange={(event) => {
                          if (event.target.checked) {
                            setCoachAdministration({
                              module: "Coach Administration",
                              permissions: [],
                            });
                          } else {
                            setCoachAdministration(null);
                          }
                        }}
                        color={"success"}
                      />
                      <div className="addrole-admin-inline-css-div2">
                        Coach Administration
                      </div>
                    </div>
                    <div className="addrole-inline-css-checkbox1">
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>Create</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          disabled={true}
                          checked={coachAdministration?.permissions.includes(1)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (coachAdministration?.permissions) {
                                setCoachAdministration((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 1],
                                }));
                              }
                            } else {
                              if (coachAdministration?.permissions) {
                                setCoachAdministration((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 1
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>View</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          checked={coachAdministration?.permissions.includes(2)}
                          disabled={true}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (coachAdministration?.permissions) {
                                setCoachAdministration((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 2],
                                }));
                              }
                            } else {
                              if (coachAdministration?.permissions) {
                                setCoachAdministration((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 2
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>Edit</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          disabled={true}
                          checked={coachAdministration?.permissions.includes(3)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (coachAdministration?.permissions) {
                                setCoachAdministration((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 3],
                                }));
                              }
                            } else {
                              if (coachAdministration?.permissions) {
                                setCoachAdministration((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 3
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>Delete</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          disabled={true}
                          checked={coachAdministration?.permissions.includes(4)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (coachAdministration?.permissions) {
                                setCoachAdministration((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 4],
                                }));
                              }
                            } else {
                              if (coachAdministration?.permissions) {
                                setCoachAdministration((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 4
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="addrole-inline-addmin-div">
                    <div className="add-role-admin-inline-css">
                      <CSwitch
                        size="sm"
                        variant={"3d"}
                        checked={
                          administrationManagement?.module ===
                          "Administration Management"
                        }
                        disabled={true}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setAdministrationManagement({
                              module: "Administration Management",
                              permissions: [],
                            });
                          } else {
                            setAdministrationManagement(null);
                          }
                        }}
                        color={"success"}
                      />
                      <div className="addrole-admin-inline-css-div2">
                        Administration Management
                      </div>
                    </div>
                    <div className="addrole-inline-css-checkbox1">
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>Create</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          disabled={true}
                          checked={administrationManagement?.permissions.includes(
                            1
                          )}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (administrationManagement?.permissions) {
                                setAdministrationManagement((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 1],
                                }));
                              }
                            } else {
                              if (administrationManagement?.permissions) {
                                setAdministrationManagement((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 1
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>View</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          checked={administrationManagement?.permissions.includes(
                            2
                          )}
                          disabled={true}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (administrationManagement?.permissions) {
                                setAdministrationManagement((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 2],
                                }));
                              }
                            } else {
                              if (administrationManagement?.permissions) {
                                setAdministrationManagement((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 2
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>Edit</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          checked={administrationManagement?.permissions.includes(
                            3
                          )}
                          disabled={true}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (administrationManagement?.permissions) {
                                setAdministrationManagement((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 3],
                                }));
                              }
                            } else {
                              if (administrationManagement?.permissions) {
                                setAdministrationManagement((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 3
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>Delete</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          checked={administrationManagement?.permissions.includes(
                            4
                          )}
                          disabled={true}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (administrationManagement?.permissions) {
                                setAdministrationManagement((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 4],
                                }));
                              }
                            } else {
                              if (administrationManagement?.permissions) {
                                setAdministrationManagement((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 4
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="addrole-inline-addmin-div">
                    <div className="add-role-admin-inline-css">
                      <CSwitch
                        size="sm"
                        variant={"3d"}
                        checked={staticContent?.module === "Static Content"}
                        disabled={true}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setStaticContent({
                              module: "Static Content",
                              permissions: [],
                            });
                          } else {
                            setStaticContent(null);
                          }
                        }}
                        color={"success"}
                      />
                      <div className="addrole-admin-inline-css-div2">
                        Static Content
                      </div>
                    </div>
                    <div className="addrole-inline-css-checkbox1">
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>Create</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          checked={staticContent?.permissions.includes(1)}
                          disabled={true}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (staticContent?.permissions) {
                                setStaticContent((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 1],
                                }));
                              }
                            } else {
                              if (staticContent?.permissions) {
                                setStaticContent((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 1
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>View</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          checked={staticContent?.permissions.includes(2)}
                          disabled={true}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (staticContent?.permissions) {
                                setStaticContent((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 2],
                                }));
                              }
                            } else {
                              if (staticContent?.permissions) {
                                setStaticContent((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 2
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>Edit</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          checked={staticContent?.permissions.includes(3)}
                          disabled={true}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (staticContent?.permissions) {
                                setStaticContent((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 3],
                                }));
                              }
                            } else {
                              if (staticContent?.permissions) {
                                setStaticContent((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 3
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>Delete</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          checked={staticContent?.permissions.includes(4)}
                          disabled={true}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (staticContent?.permissions) {
                                setStaticContent((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 4],
                                }));
                              }
                            } else {
                              if (staticContent?.permissions) {
                                setStaticContent((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 4
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="addrole-inline-addmin-div">
                    <div className="add-role-admin-inline-css">
                      <CSwitch
                        size="sm"
                        variant={"3d"}
                        checked={sessionContent?.module === "Session Content"}
                        disabled={true}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSessionContent({
                              module: "Session Content",
                              permissions: [],
                            });
                          } else {
                            setSessionContent(null);
                          }
                        }}
                        color={"success"}
                      />
                      <div className="addrole-admin-inline-css-div2">
                        Session Content
                      </div>
                    </div>
                    <div className="addrole-inline-css-checkbox1">
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>Create</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          checked={sessionContent?.permissions.includes(1)}
                          disabled={true}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (sessionContent?.permissions) {
                                setSessionContent((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 1],
                                }));
                              }
                            } else {
                              if (sessionContent?.permissions) {
                                setSessionContent((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 1
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>View</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          checked={sessionContent?.permissions.includes(2)}
                          disabled={true}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (sessionContent?.permissions) {
                                setSessionContent((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 2],
                                }));
                              }
                            } else {
                              if (sessionContent?.permissions) {
                                setSessionContent((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 2
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>Edit</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          checked={sessionContent?.permissions.includes(3)}
                          disabled={true}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (sessionContent?.permissions) {
                                setSessionContent((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 3],
                                }));
                              }
                            } else {
                              if (sessionContent?.permissions) {
                                setSessionContent((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 3
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div

                    
                        className="add-role-admin-inline-css"
                      >
                        <div className="font-size-1rem">
                          <strong>Delete</strong>
                        </div>
                        <input
                              className='addrole-admin-inline-css-div2'
                          type="checkbox"
                          checked={sessionContent?.permissions.includes(4)}
                          disabled={true}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (sessionContent?.permissions) {
                                setSessionContent((state) => ({
                                  ...state,
                                  permissions: [...state.permissions, 4],
                                }));
                              }
                            } else {
                              if (sessionContent?.permissions) {
                                setSessionContent((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 4
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </CFormGroup>
              </CCardBody>
            </CCard>
          </CForm>
        </CCol>
      </CRow>
    </div>
  );
}
