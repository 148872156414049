import React, { useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CNavItem,
  CNavLink,
  CCardHeader,
  CNav,
} from "@coreui/react";
import { useHistory } from "react-router-dom";
import QuillEditor from "./QuillEditor";
import CoachBios from "./CoachBios";
import usePermissions from "src/hooks/usePermissions";
function StaticContent() {
  const history = useHistory();
  let [activeKey, setActiveKey] = useState(1);
  const isPermited = usePermissions("staticcontent");

  if (!isPermited.status) {
    history.push(isPermited.pushto);
  }

  return (
    <div>
      <CCol xxl={12}>
        <CCard>
          <CCardHeader className="admin-management-ccardheader">
            <h2>
              <strong>Static Content Management</strong>
            </h2>
          </CCardHeader>
          <CCardBody>
            <CNav variant="pills" role="tablist" className="flex-spaceBet">
              <CNavItem>
                <CNavLink
                  active={activeKey === 1}
                  onClick={() => setActiveKey(1)}
                >
                  Coaches Bios
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  active={activeKey === 2}
                  onClick={() => setActiveKey(2)}
                >
                  Pricing
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  active={activeKey === 3}
                  onClick={() => setActiveKey(3)}
                >
                  About us
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  active={activeKey === 4}
                  onClick={() => setActiveKey(4)}
                >
                  Privacy Policy
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink
                  active={activeKey === 5}
                  onClick={() => setActiveKey(5)}
                >
                  Terms and Conditions
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink
                  active={activeKey === 6}
                  onClick={() => setActiveKey(6)}
                >
                  Contact Us
                </CNavLink>
              </CNavItem>
            </CNav>

            <div>
              {(() => {
                switch (activeKey) {
                  case 1:
                    return (
                      <div>
                        <CoachBios active={activeKey} isPermited={isPermited} />
                      </div>
                    );
                  case 2:
                    return (
                      <QuillEditor isPermited={isPermited} type={"pricing"} />
                    );
                  case 3:
                    return (
                      <QuillEditor isPermited={isPermited} type={"about_us"} />
                    );
                  case 4:
                    return (
                      <QuillEditor
                        isPermited={isPermited}
                        type={"privacy_policy"}
                      />
                    );
                  case 5:
                    return (
                      <QuillEditor
                        isPermited={isPermited}
                        type={"terms_ondition"}
                      />
                    );
                  case 6:
                    return (
                      <QuillEditor
                        isPermited={isPermited}
                        type={"contact_us"}
                      />
                    );
                  default:
                    return <div>Not Found</div>;
                }
              })()}
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
}

export default StaticContent;
