export const app_language = "en";
export const app_version = "1.0";

export let base_url = "https://api.bluxinga.com/api/v1/bluetango-admin/";
export let coach_url = "https://api.bluxinga.com/api/v1/coach/login";

export const Urls = {
    LOGIN: 'login',
    GET_PROFILE: 'getProfile'

}