import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CImg,
  CCardHeader,
  CButton,
  CModal,
  CModalHeader,
} from "@coreui/react";
import { useLocation, useHistory } from "react-router-dom";
import DefaultUser from "../../assets/svgs/defaultUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFacebookSquare,
  faTwitterSquare,
  faInstagramSquare,
  faLinkedinIn,
  faGooglePlusSquare,
} from "@fortawesome/free-brands-svg-icons";
import { getRequest } from "src/utils/AxiosUtils";
import { isValidHttpUrl } from "../../utils/CommonUtils";

export default function Profile() {
  const history = useHistory();
  const location = useLocation();

  const [userData, setUserData] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getRequest("getProfile");

        setUserData(res.data.body.data);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []);

  return userData ? (
    <CRow alignHorizontal="center" alignVertical="center">
      <CCol xl>
        <CModal
          show={location.state ? true : false}
          size={location.state && location.state.type === "edit" ? "sm" : ""}
          backdrop={false}
          color="warning"
        >
          <CModalHeader closeButton>
            {location.state && location.state.data ? location.state.data : ""}
          </CModalHeader>
        </CModal>
        <CCard>
          <CCardHeader
           className="admin-management-ccardheader"
          >
            <h2>
              <strong>Admin Profile</strong>
            </h2>
            <CButton
            className="prev-submit"
             
              onClick={() =>
                history.push({
                  pathname: "/editprofile",
                  state: { data: userData },
                })
              }
            >
              <strong>Edit Profile</strong>
            </CButton>
          </CCardHeader>
          <CCardBody
           className="layout-div-div"
          >
            {userData.profile_pic_url ? (
              <CImg
                src={userData.profile_pic_url}
                className="c-avatar-img  margin-bottom height-width-7ren"
                alt=""
               
              />
            ) : (
              <DefaultUser className="margin-bottom" />
            )}


            <table 

           className="margin-bottom-4rem"
            >
              <tbody className="fontFamilyPoppins">



              <tr
                className="flex-flexstart-center background-color-none"
              >
                <td
                 className="margin-left-8"
                >
                  {" "}
                  <strong className="font-size-20">Name  :</strong>
                </td>

                <td 
              
               className="margin-left-12-5 font-size-18 border-none">
                <> {userData["name"] ? String(userData["name"]) : ""}</>
                </td>
              </tr>
              <tr
                className="flex-flexstart-center background-color-none"
              >
                <td
                 className="margin-left-8"
                >
                  {" "}
                  <strong className="font-size-20">Role Name  : </strong>
                </td>
 
                <td  
                className="margin-left-9-5 font-size-18 border-none">
                <>{userData?.role_name}</>
                </td>
              </tr>
              <tr
                className="flex-flexstart-center background-color-none"
              >
                <td
                 className="margin-left-8"
                >
                  {" "}
                  <strong className="font-size-20"> Email   :</strong>
                </td>

                <td className="margin-left-12-5 font-size-18 border-none">
                  <>{userData["email"] ? String(userData["email"]) : ""} </>
                </td>
              </tr>
         
              </tbody>
            </table>
            <div
            className="addrole-inline-css-checkbox1 flex-direction-row"
             
            >
              <FontAwesomeIcon
                color="#4267B2"
                size="3x"
               className="cursor-pointer"
                icon={faFacebookSquare}
                onClick={() => {
                  isValidHttpUrl(userData?.social_media_handles?.facebook) &&
                    window.open(userData.social_media_handles.facebook);
                }}
              />
              <FontAwesomeIcon
                color="#00acee"
                size="3x"
               className="cursor-pointer"
                onClick={() => {
                  isValidHttpUrl(userData?.social_media_handles?.twitter) &&
                    window.open(userData.social_media_handles.twitter);
                }}
                icon={faTwitterSquare}
              />
              <FontAwesomeIcon
                color="orange"
                size="3x"
               className="cursor-pointer"
                icon={faInstagramSquare}
                onClick={() => {
                  isValidHttpUrl(userData?.social_media_handles?.instagram) &&
                    window.open(userData.social_media_handles.instagram);
                }}
              />
              <FontAwesomeIcon
                color="#0e76a8"
                size="3x"
               className="cursor-pointer"
                icon={faLinkedinIn}
                onClick={() => {
                  isValidHttpUrl(userData?.social_media_handles?.linkedin) &&
                    window.open(userData.social_media_handles.linkedin);
                }}
              />
              <FontAwesomeIcon
                color="#db4a39"
                size="3x"
               className="cursor-pointer"
                icon={faGooglePlusSquare}
                onClick={() => {
                  isValidHttpUrl(userData?.social_media_handles?.google) &&
                    window.open(userData.social_media_handles.google);
                }}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  ) : null;
}
