import React from "react";

import ChangePassword from "./views/ChangePassword/ChangePassword";
import AddCoach from "./views/CoachAdministration/AddCoach";
import ChatCoach from "./views/CoachAdministration/ChatCoach";
import CoachAdministartion from "./views/CoachAdministration/CoachAdministartion";
import ViewCoach from "./views/CoachAdministration/ViewCoach";
import Dashboard from "./views/dashboard/Dashboard";
import EditProfile from "./views/Profile/EditProfile";
import Profile from "./views/Profile/Profile";
import StaticContent from "./views/StaticContent/StaticContent";
import AdminManagment from "./views/AdminManagement/AdminManagement"
import AddRole from "./views/AdminManagement/AddRole"
import EditRole from "./views/AdminManagement/EditRole"
import ViewRole from "./views/AdminManagement/ViewRole";
import AddCoachBios from "./views/StaticContent/AddCoachBios";
import CompletedSession from "./views/SessionManagement/Session";
import ViewSession from "./views/StaticContent/ViewSession";
import ViewTimeline from "./views/StaticContent/ViewTimeLine";
import CoachAvailability from "./views/StaticContent/CoachAvailability";
import CoachReassign from "./views/StaticContent/CoachReassign";


import Chat from "./coachView/Chat";
const CoachDashboard = React.lazy(() => import("./coachView/CoachDashboard"));
const MySessionRequest = React.lazy(() =>import("./coachView/MySessionRequest"));
const MySessions = React.lazy(() => import("./coachView/MySessions"));
const SessionHistory = React.lazy(() => import("./coachView/SessionHistory"));
const SessionHistoryDetails = React.lazy(() =>import("./coachView/SessionHistoryDetails"));
const DefineAvailability = React.lazy(() =>import("./coachView/DefineAvailability"));
const DefineSlots = React.lazy(() => import("./coachView/DefineSlots"));
const PreviewSlots = React.lazy(() => import("./coachView/PreviewSlots"));
const EditCoachProfile = React.lazy(() =>import("./coachView/EditCoachProfile"));
const CoachChatDetail = React.lazy(() => import("./coachView/CoachChatDetail"));










const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', exact: true, component: Dashboard },
  { path: '/editprofile', name: 'Edit Profile', exact: true, component: EditProfile },
  { path: '/profile', name: 'Profile', exact: true, component: Profile },
  { path: '/changepassword', name: 'Change Password', exact: true, component: ChangePassword },
  { path: '/coachadministration', name: 'Coach Administration', exact: true, component: CoachAdministartion },
  { path: '/coachadministration/viewcoach/:id', name: 'View Coach', exact: true, component: ViewCoach },
  { path: '/coachadministration/addcoach', name: 'Add Coach', exact: true, component: AddCoach },
  { path: '/coachadministration/editcoach/:id', name: 'Edit Coach', exact: true, component: AddCoach },
  { path: '/coachadministration/chatcoach/:id', name: 'Chat Coach', exact: true, component: ChatCoach },
    
  { path: '/administrationmanagement', name: 'Admin Administration', exact: true, component: AdminManagment },
  { path: '/administrationmanagement/addrole', name: 'Add Roles', exact: true, component: AddRole },
  { path: '/administrationmanagement/editrole/:id', name: 'Edit Roles', exact: true, component: EditRole },
  { path: '/administrationmanagement/viewrole/:id', name: 'View Roles', exact: true, component: ViewRole },
  { path: '/staticcontent', name: 'Static content', exact: true, component: StaticContent },
  { path: '/staticcontent/addbio', name: 'Add Bio', exact: true, component: AddCoachBios },
  { path: '/staticcontent/editbio/:id', name: 'Add Bio', exact: true, component: AddCoachBios },
  { path: '/sessioncontent', name: 'Session Management', exact: true, component: CompletedSession },
  { path: '/sessioncontent/viewsession/:id', name: 'View Completed Session', exact: true, component: ViewSession },
  { path: '/sessioncontent/viewtimeline/:id', name: 'View Completed Timeline', exact: true, component: ViewTimeline },
  { path: '/sessioncontent/coachavailability/:id', name: 'View Pending Timeline', exact: true, component: CoachAvailability },
  { path: '/sessioncontent/viewreassigncoach/:id/:sessionid/:slotid', name: 'View Pending Timeline', exact: true, component: CoachReassign },


  // coach routes

  { path: '/coach/dashboard', name: 'CoachDashboard', exact: true, component: CoachDashboard },
  { path: '/coach/editcoachprofile', name: 'EditCoachProfile', exact: true, component: EditCoachProfile },
  { path: '/coach/mysessionrequest', name: 'MySessionRequest', exact: true, component: MySessionRequest },
  { path: '/coach/mysessions', name: 'MySessions', exact: true, component: MySessions },
  { path: '/coach/sessionhistory', name: 'SessionHistory', exact: true, component: SessionHistory },
  { path: '/coach/sessionhistorydetails', name: 'SessionHistoryDetails', exact: true, component: SessionHistoryDetails },
  { path: '/coach/defineavailability', name: 'DefineAvailability', exact: true, component: DefineAvailability },
  { path: '/coach/defineslots', name: 'DefineSlots', exact: true, component: DefineSlots },
  { path: '/coach/previewSlots', name: 'PreviewSlots', exact: true, component: PreviewSlots },
  { path: '/coach/chat', name: 'chat', exact: true, component: Chat },
  { path: '/coachchatdetail', name: 'Coachchatdetail', exact: true, component: CoachChatDetail },





   
];

export default routes;
