import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
} from "@coreui/react";
import { getRequest } from "src/utils/AxiosUtils";
import usePermissions from "src/hooks/usePermissions";
import moment from "moment";
import '../../views/StaticContent/viewTimeLine.css'
const getBadge = (status) => {
  switch (status) {
    case "Active":
      return "success";
    case "Inactive":
      return "secondary";
    case "Pending":
      return "warning";
    case "Banned":
      return "danger";
    default:
      return "primary";
  }
};

const fields = [
  { key: "name", label: "Name", _style: { fontFamily: "Poppins" } },
  {
    key: "request_received",
    label: "Request Received",
    _style: { fontFamily: "Poppins" },
  },
  { key: "status", label: "Status", _style: { fontFamily: "Poppins" } },
  { key: "action", label: "Action", _style: { fontFamily: "Poppins" } },
  { key: "action_by", label: "Action By", _style: { fontFamily: "Poppins" } },
];
const ViewTimeline = () => {
  const history = useHistory();
  const isPermited = usePermissions("coachadministration");
  if (!isPermited.status) {
    history.push(isPermited.pushto);
  }

  const [loading, setLoading] = useState(false);
  const params = useParams();

  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");  
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const page = currentPage;
  const [data, setData] = useState([]);
  const pageChange = (newPage) => {
    
    let newPage1 = newPage;
    if (newPage1 === 0) {
      newPage1 = 1;
    }
    currentPage !== newPage &&
      history.push(`/coachadministration?page=${newPage1}`);
  };

  useEffect(() => {
    const getSessionDetail = async () => {
      setLoading(true)
      const res = await getRequest(`getSessionDetail?id=${params.id}`);
      setData(res?.data?.body?.data?.timeline);
      setLoading(false)
    };

    getSessionDetail();
  }, [params.id]);

  
  return (
    <CRow>
      <CCol xxl={12}>
        <CCard>
          <CCardHeader
           className="admin-management-ccardheader"
          >
            <h2>
              <strong>View Timeline</strong>
            </h2>
            <CButton
              
             className="width-8rem"
              color="primary"
              onClick={() => {
                history.goBack();
              }}

              
            >
              <strong>Back</strong>
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              items={data}
              fields={fields}
              hover
              border
              addTableClasses="table-class"
              striped
              loading={loading}
              noItemsViewSlot={
                !loading ? (
                  <div
                   className="admin-management-no-result"
                  >
                    No Results
                  </div>
                ) : (
                  <div className="height14rem"></div>
                )
              }
              
              clickableRows
              
              scopedSlots={{
                action: (item) => (
                  <td>
                   
                    {item.action === 1 ? (
                      <CBadge

                      className='cbadge-pending'
                        
                        shape="pill"
                        
                      >
                  
                        Pending
                      </CBadge>
                    ) : item.action === 2 ? (
                      <CBadge
                      className='cbadge-expired'
                        
                        shape="pill"
                        
                      >
                      
                        Declined
                      </CBadge>
                    ) : item.action === 3 ? (
                      <CBadge  
                      className='cbadge-expired'
                       
                        shape="pill"
                        
                        

                      >
                      
                        Expired
                      </CBadge>
                    ) : item.action === 4 ? (
                      <CBadge

                      className='cbadge-reassigned'
                        
                        shape="pill"
                        
                      >
                      
                        Reassigned
                      </CBadge>
                    ) : item.action === 5 ? (
                      <CBadge
                       className="admin-management-badget"
                        shape="pill"
                        color={getBadge("blue")}
                      >
                      
                        Accepted
                      </CBadge>
                    ) : item.action === 6 ? (
                      <CBadge

                      className='cbadge-cancelled'
                        
                        shape="pill"
                        
                      >
                      
                        Cancelled
                      </CBadge>
                    ) : null}
                  </td>
                ),
                action_by: (item) => (
                  <td>
                   
                    {item.action_by === 0 ? (
                      <CBadge
                       className="admin-management-badget"
                        shape="pill"
                        color={getBadge("Pending")}
                      >
                  
                        Pending
                      </CBadge>
                    ) : item.action_by === 1 ? (
                      <CBadge
                       className="admin-management-badget"
                        shape="pill"
                        color={getBadge("blue")}
                      >
                      
                        Coach
                      </CBadge>
                    ) : item.action_by === 2 ? (
                      <CBadge

                      className='cbadge-system'
                        
                        shape="pill"
                        
                      >
                      
                        System
                      </CBadge>
                    ) : item.action_by === 3 ? (
                      <CBadge
                      className='cbadge-admin'

                        
                        shape="pill"
                        
                      >
                      
                        Admin
                      </CBadge>
                    ) : item.action_by === 4 ? (
                      <CBadge

                      className='cbadge-employee'
                        
                        shape="pill"
                        
                      >
                      
                        Employee
                      </CBadge>
                    ) : null}
                  </td>
                ),
                request_received: (item) => {
                  return (
                    <td>{
                      moment(item?.request_received).format("lll")
                    }</td>
                  );
                },
              }}
            />
            {data?.count ? (
              <CPagination
                
                activePage={page}
                pages={Math.ceil(data.count / 10)}
                onActivePageChange={pageChange}
                doubleArrows={true}
                align="start"
              />
            ) : null}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default ViewTimeline;
