export default async function permission() {
    let stream = null;

    try {
        stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
       
       
        if (stream.active) {
            return true;
        } 
    } catch (err) {
       
        console.log(err)
        return (false);

    }
}