import React, { useEffect, useState } from "react";
import {
  CFormGroup,
  CLabel,
  CInput,
  CInputFile,
  CCardBody,
  CForm,
  CRow,
  CCol,
  CCard,
  CSelect,
  CButton,
  CCardFooter,
  CCardHeader,
} from "@coreui/react";
import { useFormik } from "formik";
import { addBios } from "../../reusable/validation";
import { useHistory, useParams, useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import {
  getRequest,
  postRequest,
  putRequest,
  uploadFile,
} from "src/utils/AxiosUtils";

export default function AddCoachBios() {
  const location = useLocation();
  const params = useParams();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [emp, setEmp] = useState(null);
  const [showError, setShowError] = useState("");
  const [disable, setDisable] = useState(false);
  const [filename, setFileName] = useState("Choose file...");
  const [coachList, setCoachList] = useState([]);

  const initialValues = {
    coachname: emp ? emp.coach_id : "",
    description: emp ? emp.description : "",
    url: emp ? emp.image : "",
  };

  const onSubmit = async (values, actions) => {
    if (!values.url) {
      actions.setFieldError("url", "image is required");
      return;
    }

    const data = {
      coach_id: values.coachname,
      id: params.id,
      description: values.description,
      image: values.url,
      fileName: filename,
    };

    if (!params.id) {
      delete data.id;
    }
    if (params.id) {
      delete data.coach_id;
    }

    if (!values.url) {
      delete data.image;
      delete data.fileName;
    }

    try {
      setLoading(true);
      let func;
      let text;
      if (params.id) {
        func = putRequest;
        text = "updateBios";
      } else {
        func = postRequest;
        text = "addBios";
      }
      const response = await func(text, data);

      if (response) {
        setLoading(false);
        const msg = params.id
          ? "Bio updated successfully"
          : "Bio added successfully";

        let pathname;
        if (location.state && location.state.prevPath) {
          pathname = location.state.prevPath;
        } else {
          pathname = `/staticcontent`;
        }
        history.push({
          search: location.state ? location.state.prevSearch : "",
          pathname,
          state: { data: msg, type: "edit" },
        });
      }
    } catch (error) {
      actions.setFieldError("description", error.response.data.message);
      setLoading(false);

      console.log(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema: addBios,
  });

  const showFile = async (event) => {
    event.preventDefault();

    if (event.target.files.length < 1) {
      return;
    }

    if (
      event.target.files[0].type !== "image/png" &&
      event.target.files[0].type !== "image/jpeg"
    ) {
      setShowError("Only Image files are allowed");
      return;
    }
    setShowError("");
    setFileName(event.target.files[0].name);

    try {
      const bodyFormData = new FormData();

      bodyFormData.append("file", event.target.files[0]);
      setDisable(true);
      const result = await uploadFile(bodyFormData);
      setDisable(false);

      formik.setFieldValue("url", result.body.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getCoaches = async () => {
      const data = await getRequest(`getCoachList`);
      setCoachList(data?.data?.body?.data?.rows);
    };

    const GetBioDetails = async () => {
      const data = await getRequest(`getBiosDetails?id=${params.id}`);
      setEmp(data.data.body.data);
    };

    if (params.id) {
      GetBioDetails();
    }

    getCoaches();
  }, [params?.id]);

  useEffect(() => {
    if (emp && emp.fileName) {
      setFileName(emp.fileName);
    }
  }, [emp]);

  return (
    <div>
      <CRow>
        <CCol xl="12" lg="12">
          <CForm onSubmit={formik.handleSubmit} autoComplete="off">
            <CCard>
              <CCardHeader className="add-role-ccard-header-inline ">
                <h3>
                  {params.id ? (
                    <strong>Edit Bio</strong>
                  ) : (
                    <strong>Add Bio</strong>
                  )}
                </h3>
                <CButton
                  className="width-8rem"
                  color="primary"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <strong>Back</strong>
                </CButton>
              </CCardHeader>
              <CCardBody>
                <CFormGroup>
                  <CLabel htmlFor="name">Select Coach</CLabel>

                  <CSelect
                    disabled={params?.id}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.coachname}
                    custom
                    name="coachname"
                    id="coachname"
                  >
                    <option value="">Select a Coach</option>

                    {coachList
                      ? coachList.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))
                      : null}
                  </CSelect>
                  {formik.touched.coachname && formik.errors.coachname ? (
                    <div className="email-validate">
                      {formik.errors.coachname}
                    </div>
                  ) : null}
                </CFormGroup>

                <CFormGroup>
                  <CLabel htmlFor="custom-file-input">Image Upload</CLabel>

                  <CCol xs="6" md="12">
                    <CInputFile
                      custom
                      id="custom-file-input"
                      onChange={(e) => showFile(e)}
                    />
                    <CLabel
                      style={{ zIndex: "0" }}
                      htmlFor="custom-file-input"
                      variant="custom-file"
                    >
                      {filename}
                    </CLabel>
                    {formik.touched.url && formik.errors.url ? (
                      <div className="email-validate">{formik.errors.url}</div>
                    ) : null}
                    {showError ? (
                      <div className="email-validate">{showError}</div>
                    ) : null}
                  </CCol>
                </CFormGroup>

                <CFormGroup>
                  <CLabel htmlFor="description">Description</CLabel>
                  <CInput
                    type="text"
                    id="description"
                    name="description"
                    autocomplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    placeholder="Enter Description.."
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <div className="email-validate">
                      {formik.errors.description}
                    </div>
                  ) : null}
                </CFormGroup>
              </CCardBody>

              <CCardFooter className="addcoachbios-footer">
                {loading ? (
                  <div className="spinner-border text-success" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <CButton
                    type="submit"
                    disabled={disable}
                    className="width-5rem"
                    color="success"
                  >
                    {params.id ? <strong>Update</strong> : <strong>Add</strong>}
                  </CButton>
                )}

                <CButton
                  className="width-5rem"
                  color="danger"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <strong>Cancel</strong>
                </CButton>
              </CCardFooter>
            </CCard>
          </CForm>
        </CCol>
      </CRow>
    </div>
  );
}
