import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CTooltip,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CButton,
  CModalTitle,
  CInput,
  CSwitch,
} from "@coreui/react";
import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { deleteRequest, getRequest, putRequest } from "src/utils/AxiosUtils";
import Select from "react-select";
import usePermissions from "src/hooks/usePermissions";
import { useSelector } from "react-redux";
const getBadge = (status) => {
  switch (status) {
    case "Active":
      return "success";
    case "Inactive":
      return "secondary";
    case "Pending":
      return "warning";
    case "Banned":
      return "danger";
    default:
      return "primary";
  }
};

const fields = [
  { key: "role_name", label: "Role Name", _style: { fontFamily: "Poppins" } },
  {
    key: "bluetango_admins",
    label: "User Name",
    _style: { fontFamily: "Poppins" },
  },
  { key: "modules", label: "Modules", _style: { fontFamily: "Poppins" } },
  {
    key: "last_activity",
    label: "Last Updated",
    _style: { fontFamily: "Poppins" },
  },
  { key: "status", label: "Status", _style: { fontFamily: "Poppins" } },
  {
    key: "show_details",
    label: "Action",
    _style: {
      minWidth: "12rem",
      height: "4.2rem",
      justifyContent: "space-evenly",
      alignItems: "center",
      fontFamily: "Poppins",
    },
    sorter: false,
    filter: false,
  },
];
const AdminManagement = () => {
  const history = useHistory();
  const isPermited = usePermissions("administrationmanagement");
  const role_id = useSelector((state) => state?.profileReducer?.data?.role_id);

  if (!isPermited.status) {
    history.push(isPermited.pushto);
  }
  const token = localStorage.getItem("s_token");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const queryPageSEarch = useLocation().search.match(
    /search=([A-Za-z0-9 _@]+)/,
    ""
  );

  const updateQueryStatus = useLocation()
    .search.replace(/%20/g, " ")
    .match(/module=([A-Za-z0-9 _@]+)/, "");

  const currentStatus =
    updateQueryStatus && updateQueryStatus[1] ? updateQueryStatus[1] : null;

  const currentPageSearch =
    queryPageSEarch && queryPageSEarch[1] ? queryPageSEarch[1] : "";

  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);

  const [page, setPage] = useState(currentPage);

  const [data, setData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [active, setActive] = useState(null);
  const [enableModal, setEnableModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalError, setDeleteModalError] = useState(false);
  const [statusInputval, setStatusInputval] = useState("");

  const [onInputChange, setOnInputChange] = useState({
    onsearchCHange: currentPageSearch ? currentPageSearch : "",
    onStatusChange: currentStatus ? currentStatus : "",
  });

  const pageChange = (newPage) => {
    let newPage1 = newPage;
    if (newPage1 === 0) {
      newPage1 = 1;
    }
    currentPage !== newPage &&
      history.push(
        `/administrationmanagement?search=${onInputChange.onsearchCHange}&&page=${newPage1}&&module=${onInputChange.onStatusChange}`
      );
  };

  const toggleEnable = (id, Active) => {
    setUserId(id);
    if (id === role_id) {
      setDeleteModalError(`You can't edit your own role`);
      return;
    }
    setActive(Active);
    setEnableModal(!enableModal);
  };

  const handleEnable = async () => {
    try {
      setEnableModal(!enableModal);
      let pass;
      if (active) {
        pass = 0;
      } else {
        pass = 1;
      }
      await putRequest("updateAdminAndRoleStatus", {
        id: userId,
        status: pass,
      });
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setRefresh(!refresh);
    }
  };

  const toggleDelete = (id) => {
    setUserId(id);

    if (id === role_id) {
      setDeleteModalError(`You can't delete your own role`);
      return;
    }
    setDeleteModal(!deleteModal);
  };

  const handleDelete = async () => {
    try {
      setDeleteModal(!deleteModal);

      const res = await deleteRequest(`deleteRole/${userId}`);
      if (res.status === 200) {
        toast.success("Role Deleted Successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error.response);
      setDeleteModalError(error?.response?.data?.message);
    }
  };

  const handleSearchChange = (e) => {
    setOnInputChange((preState) => {
      return {
        ...preState,
        onsearchCHange: e.target.value,
      };
    });
  };

  const handleSearch = async () => {
    (currentPageSearch !== onInputChange.onsearchCHange ||
      currentStatus !== onInputChange.onStatusChange) &&
      history.push(
        `/administrationmanagement?search=${onInputChange.onsearchCHange}&&page=${page}&&module=${onInputChange.onStatusChange}`
      );
  };

  const handleStatusChange = (e) => {
    setStatusInputval(e);
    let statusId = null;
    if (e?.id === 0) {
      statusId = 0;
    } else {
      if (e.id) {
        statusId = e.id;
      }
    }
    setOnInputChange((preState) => {
      return {
        ...preState,
        onStatusChange: statusId,
      };
    });
  };

  const handleReset = () => {
    setStatusInputval(null);

    setOnInputChange((preState) => {
      return {
        ...preState,
        onsearchCHange: "",
        onStatusChange: null,
      };
    });

    let newPage = page;
    if (newPage === 0) {
      newPage = 1;
    }
    history.push(`/administrationmanagement?search=&&page=${newPage}`);
  };

  useEffect(() => {
    if (currentStatus) {
      if (currentStatus === "Dashboard") {
        setStatusInputval({
          name: "Dashboard",
          id: "Dashboard",
        });
      } else if (currentStatus === "Coach Administration") {
        setStatusInputval({
          name: "Coach Administration",
          id: "Coach Administration",
        });
      } else if (currentStatus === "Administration Management") {
        setStatusInputval({
          name: "Administration Management",
          id: "Administration Management",
        });
      } else if (currentStatus === "Static Content") {
        setStatusInputval({
          name: "Static Content",
          id: "Static Content",
        });
      } else if (currentStatus === "Session Content") {
        setStatusInputval({
          name: "Session Content",
          id: "Session Content",
        });
      }
    }

    const getData = async () => {
      let searchText = "";
      let statusText = "";
      if (currentPageSearch) {
        searchText = `&&searchKey=${currentPageSearch}`;
      }

      if (currentStatus && currentStatus !== "null") {
        statusText = `&&module=${currentStatus}`;
      }

      try {
        setLoading(true);
        const datas = await getRequest(
          `getrolesAndAdmins?offset=${currentPage}&&limit=${10}${searchText}${statusText}`
        );

        datas.data.body.data.rows.map((item) => {
          item._classes = "catTableItem";
          return item;
        });

        setData(datas.data.body.data);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getData();

    currentPage !== page && setPage(currentPage);
  }, [currentPage, currentPageSearch, refresh, token, page, currentStatus]);


  const optionStylesstatus = {
    input: (styles) => ({
      ...styles,
      width: "5rem",
      zIndex: "100",
      marginRight: "4rem",
    }),
    control: (styles) => ({ ...styles, marginLeft: "5px", marginRight: "5px" }),
  };

  return (
    <CRow>
      <CModal
        show={location.state && location.state.data ? true : false}
        size="sm"
        backdrop={false}
        color="warning"
      >
        <CModalHeader closeButton>
          {location.state && location.state.data !== null
            ? location.state.data
            : ""}
        </CModalHeader>
      </CModal>
      <CModal
        show={deleteModalError}
        size="sm"
        backdrop={false}
        color="warning"
        onClose={() => setDeleteModalError(false)}
      >
        <CModalHeader closeButton>{deleteModalError}</CModalHeader>
      </CModal>
      <CModal
        show={enableModal}
        centered={true}
        color="warning"
        onClose={setEnableModal}
        backdrop={true}
        style={{ fontFamily: "Poppins" }}
      >
        <CModalHeader style={{ height: "3rem" }}>
          <CModalTitle>
            {active ? "Deactivate User?" : "Activate User?"}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          {active
            ? "Are you sure you want to Deactivate this Role?"
            : "Are you sure you want to Activate this Role?"}
        </CModalBody>
        <CModalFooter style={{ height: "4rem" }}>
          <CButton color="success" onClick={handleEnable}>
            Yes
          </CButton>
          <CButton color="secondary" onClick={() => setEnableModal(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
      <CModal
        show={deleteModal}
        centered={true}
        backdrop={true}
        color="warning"
        onClose={setDeleteModal}
      >
        <CModalHeader>
          <CModalTitle>Delete Role?</CModalTitle>
        </CModalHeader>
        <CModalBody>Are you sure you want to delete this Role?</CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={handleDelete}>
            Yes
          </CButton>{" "}
          <CButton color="secondary" onClick={() => setDeleteModal(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
      <CCol xxl={12}>
        <CCard>
          <CCardHeader className="admin-management-ccardheader">
            <h2>
              <strong>Administration Management</strong>
            </h2>
            {isPermited.permissionArray.includes(1) && (
              <CButton
                className="width-8rem"
                color="success"
                onClick={() => {
                  history.push({
                    pathname: `/administrationmanagement/addrole`,
                    state: { prevPath: location.pathname },
                  });
                }}
              >
                <strong>Add New Role</strong>
              </CButton>
            )}
          </CCardHeader>

          <CCardBody>
            <CDataTable
              items={data.rows}
              fields={fields}
              hover
              border
              addTableClasses="table-class"
              striped
              loading={loading}
              noItemsViewSlot={
                !loading ? (
                  <div className="admin-management-no-result">No Results</div>
                ) : (
                  <div style={{ height: "14rem" }}></div>
                )
              }
              clickableRows
              overTableSlot={
                <CCol className="admin-management-table" md="14">
                  <div className="display-flex">
                    <CInput
                      className="admin-management-input"
                      value={onInputChange.onsearchCHange}
                      onChange={handleSearchChange}
                      autocomplete="off"
                      id="input1-group1"
                      name="input1-group1"
                      placeholder="Search by Role Name, Username"
                    />

                    <Select
                      styles={optionStylesstatus}
                      onChange={(e) => {
                        handleStatusChange(e);
                      }}
                      value={statusInputval}
                      name="status"
                      isSearchable={true}
                      isClearable={false}
                      placeholder="Select Module"
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => `${option.id}`}
                      options={[
                        {
                          name: "Dashboard",
                          id: "Dashboard",
                        },
                        {
                          name: "Coach Administration",
                          id: "Coach Administration",
                        },
                        {
                          name: "Administration Management",
                          id: "Administration Management",
                        },
                        {
                          name: "Static Content",
                          id: "Static Content",
                        },
                        {
                          name: "Session Content",
                          id: "Session Content",
                        },
                      ]}
                    />
                  </div>

                  <div>
                    <CButton
                      onClick={handleSearch}
                      disabled={
                        !onInputChange.onsearchCHange && !statusInputval
                      }
                      style={{ marginLeft: "1rem" }}
                      color="info"
                    >
                      Search
                    </CButton>
                    <CButton
                      className="margin-left-1rem"
                      onClick={() => {
                        handleReset();
                      }}
                      color="info"
                    >
                      Reset
                    </CButton>
                  </div>
                </CCol>
              }
              scopedSlots={{
                last_activity: (item) => {
                  return <td>{item?.last_activity?.slice(0, 10)}</td>;
                },
                status: (item) => (
                  <td>
                    {item.status === 1 ? (
                      <CBadge
                        className="admin-management-badget"
                        shape="pill"
                        color={getBadge("Active")}
                      >
                        Active
                      </CBadge>
                    ) : item.status === 2 ? (
                      <CBadge
                        className="admin-management-badget"
                        shape="pill"
                        color={getBadge("Banned")}
                      >
                        Deleted
                      </CBadge>
                    ) : item.status === 0 ? (
                      <CBadge
                        className="admin-management-badget"
                        shape="pill"
                        color={getBadge("Banned")}
                      >
                        Inactive
                      </CBadge>
                    ) : null}
                  </td>
                ),
                bluetango_admins: (item) => {
                  let str = "";
                  item.bluetango_admins.forEach((admin, index) => {
                    str =
                      index > 0
                        ? str + `, ${admin.name}`
                        : str + ` ${admin.name}`;
                  });
                  return str ? (
                    <CTooltip content={`${str}`} placement={"top-start"}>
                      <td>
                        {item?.bluetango_admins.length > 0 ? (
                          item.bluetango_admins.length > 1 ? (
                            <div className="color-0d86ff">
                              {" "}
                              {item.bluetango_admins.length}
                            </div>
                          ) : (
                            item.bluetango_admins[0].name
                          )
                        ) : (
                          ""
                        )}
                      </td>
                    </CTooltip>
                  ) : (
                    <div></div>
                  );
                },
                modules: (item) => {
                  let str = "";
                  item.module_wise_permissions.forEach((permissions, index) => {
                    str =
                      index > 0
                        ? str + `, ${permissions.module}`
                        : str + ` ${permissions.module}`;
                  });
                  return str ? (
                    <CTooltip content={`${str}`} placement={"top-start"}>
                      <td>
                        {item?.module_wise_permissions.length > 0 ? (
                          item.module_wise_permissions.length > 1 ? (
                            <div className="color-0d86ff">
                              {item.module_wise_permissions.length}
                            </div>
                          ) : (
                            item.module_wise_permissions[0].module
                          )
                        ) : (
                          ""
                        )}
                      </td>
                    </CTooltip>
                  ) : (
                    <td></td>
                  );
                },
                show_details: (item, index) => {
                  return (
                    <td>
                      <div className="py-2  admin-management-icon-div">
                        {isPermited.permissionArray.includes(2) && (
                          <CTooltip content={`View `} placement={"top-start"}>
                            <FontAwesomeIcon
                              color="green"
                              size="lg"
                              className="cursor-pointer"
                              onClick={() =>
                                history.push({
                                  pathname: `/administrationmanagement/viewrole/${item.id}`,
                                  state: {
                                    item,
                                    prevPath: location.pathname,
                                    prevSearch: location.search,
                                  },
                                })
                              }
                              icon={faEye}
                            />
                          </CTooltip>
                        )}
                        {isPermited.permissionArray.includes(3) && (
                          <CTooltip content={"Edit "} placement={"top-start"}>
                            <CIcon
                              className="admin-management-ctooltip"
                              size="lg"
                              onClick={() => {
                                if (item.id === role_id) {
                                  setDeleteModalError(
                                    `You can't edit your own role`
                                  );
                                  return;
                                }
                                history.push({
                                  pathname: `/administrationmanagement/editrole/${item.id}`,
                                  state: {
                                    item,
                                    prevPath: location.pathname,
                                    prevSearch: location.search,
                                  },
                                });
                              }}
                              content={freeSet.cilPencil}
                            />
                          </CTooltip>
                        )}
                        {isPermited.permissionArray.includes(3) && (
                          <CTooltip
                            content={
                              item.status === 1
                                ? "Deactivate User"
                                : "Activate User"
                            }
                            placement={"top-start"}
                            interactive={true}
                            trigger="mouseenter"
                          >
                            <div>
                              <CSwitch
                                onChange={() =>
                                  toggleEnable(item.id, item.status === 1)
                                }
                                size="sm"
                                variant={"3d"}
                                color={"success"}
                                checked={item.status === 1}
                              />
                            </div>
                          </CTooltip>
                        )}
                        {isPermited.permissionArray.includes(4) && (
                          <CTooltip
                            content={`Delete `}
                            placement={"top-start"}
                            interactive={true}
                            trigger="mouseenter"
                          >
                            <CIcon
                              onClick={() => toggleDelete(item.id)}
                              size="lg"
                              className="admin-management-cion-trash"
                              content={freeSet.cilTrash}
                            />
                          </CTooltip>
                        )}
                      </div>
                    </td>
                  );
                },
              }}
            />
            {data.count ? (
              <CPagination
                activePage={page}
                pages={Math.ceil(data.count / 10)}
                onActivePageChange={pageChange}
                doubleArrows={true}
                align="start"
              />
            ) : null}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AdminManagement;
