import React, { useEffect, useState } from "react";
import {
  CFormGroup,
  CLabel,
  CInput,
  CSwitch,
  CCardBody,
  CForm,
  CRow,
  CCol,
  CCard,
  CButton,
  CCardFooter,
  CCardHeader,
  CModal,
  CModalHeader,
} from "@coreui/react";

import { useFormik } from "formik";
import { roleValidation } from "../../reusable/validation";
import { useHistory, useParams, useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { deleteRequest, getRequest, putRequest } from "src/utils/AxiosUtils";
import { useSelector } from "react-redux";

export default function EditRole() {
  const location = useLocation();
  const params = useParams();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([{ name: "", email: "" }]);
  const [fieldsError, setFieldsError] = useState([{ name: "", email: "" }]);
  const [dashboard, setDashboard] = useState(null);
  const [coachAdministration, setCoachAdministration] = useState(null);
  const [administrationManagement, setAdministrationManagement] =
    useState(null);
  const [staticContent, setStaticContent] = useState(null);
  const [sessionContent, setSessionContent] = useState(null);
  const [errorModal, setErrorModal] = useState(null);
  const [roleName, setRoleName] = useState("");
  const userData = useSelector((state) => state?.profileReducer?.data);

  const initialValues = {
    name: "",
  };

  const onSubmit = async (values, actions) => {
    let error = false;

    let module_wise_permissions = [];

    if (dashboard?.module === "Dashboard") {
      module_wise_permissions.push(dashboard);
    }
    if (coachAdministration) {
      module_wise_permissions.push(coachAdministration);
    }
    if (administrationManagement) {
      module_wise_permissions.push(administrationManagement);
    }
    if (staticContent) {
      module_wise_permissions.push(staticContent);
    }
    if (sessionContent) {
      module_wise_permissions.push(sessionContent);
    }

    let body = {
      id: params.id,
      admins: fields,
      role_name: values.name,
      module_wise_permissions,
    };

    if (body.role_name === roleName) {
      delete body.role_name;
    }

    if (body.module_wise_permissions.length === 0) {
      setErrorModal("Please add atleast one permission module");
      return;
    }

    body.admins.forEach((item) => {
      if (!item?.name || !item?.email) {
        setErrorModal("Please complete all the users details");
        error = true;
      }
    });

    if (error) return;

    body.module_wise_permissions.forEach((item) => {
      if (item?.permissions?.length === 0) {
        setErrorModal("Please add permissions for all enabled modules");
        error = true;
      }
    });
    if (error) return;

    try {
      setLoading(true);
      const result = await putRequest(`updateAdminAndRole`, body);
      if (result.status === 200) {
        setLoading(false);

        if (result.data.body.data.Already_exist_admins.length > 0) {
          let alreadyExistData = result?.data?.body?.data?.Already_exist_admins;
          fields.forEach((field, index) => {
            if (alreadyExistData[0].email === field.email) {
              const errors = [...fieldsError];
              errors[index].email = "This email already exist";
              setFieldsError(errors);
             
            }
          });
          return;
        }

        const msg = "Role updated successfully";

        let pathname;
        if (location.state && location.state.prevPath) {
          pathname = location.state.prevPath;
        } else {
          pathname = `/administrationmanagement`;
        }
        history.push({
          search: location.state ? location.state.prevSearch : "",
          pathname,
          state: { data: msg, type: "edit" },
        });
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema: roleValidation,
  });

  useEffect(() => {
    const getRole = async () => {
      const res = await getRequest(`viewRoleDetails?role_id=${params.id}`);
      if (res.status === 200) {
        formik.setFieldValue("name", res?.data?.body?.data?.role_name);
        setRoleName(res?.data?.body?.data?.role_name);
        setFields(res?.data?.body?.data?.bluetango_admins);
        res?.data?.body?.data?.bluetango_admins.forEach((item, index) => {
          if (index === 0) {
            return;
          }
          setFieldsError((state) => [...state, { name: "", email: "" }]);
        });
        res?.data?.body?.data?.module_wise_permissions.forEach((perm) => {
          if (perm.module === "Dashboard") {
            setDashboard(perm);
          }
          if (perm.module === "Coach Administration") {
            setCoachAdministration(perm);
          }
          if (perm.module === "Administration Management") {
            setAdministrationManagement(perm);
          }
          if (perm.module === "Session Content") {
            setSessionContent(perm);
          }
          if (perm.module === "Static Content") {
            setStaticContent(perm);
          }
        });
      }
    };
    getRole();
  }, [params.id]);

  function handleChange(i, event) {
    const values = [...fields];
    const errors = [...fieldsError];
    errors[i].name = "";

    setFieldsError(errors);

    if (event.target.value === "" || event.target.value === null) {
      errors[i].name = "Please enter this user's name ";
      setFieldsError(errors);
    }

    if (event.target.value.length > 50) {
      errors[i].name = "Description must be less than 50 characters";
      setFieldsError(errors);
    }

    values[i].name = event.target.value;
    setFields(values);
  }

  function handleEmailChange(i, event) {
    const values = [...fields];
    const errors = [...fieldsError];
    errors[i].email = "";

    setFieldsError(errors);

    if (event.target.value === "" || event.target.value === null) {
      errors[i].email = "Please enter this user's email";
      setFieldsError(errors);
    }

    if (event.target.value > 50) {
      errors[i].name = "Email must be less than 50 characters";
      setFieldsError(errors);
    }

    values[i].email = event.target.value;
    setFields(values);
  }

  function handleAdd() {
    const values = [...fields];
    const errors = [...fieldsError];
    values.push({ name: "", email: "" });
    errors.push({ name: "", email: "" });
    setFieldsError(errors);
    setFields(values);
  }

  async function handleRemove(i, field) {
    const values = [...fields];
    const errors = [...fieldsError];

    values.splice(i, 1);
    errors.splice(i, 1);
    setFieldsError(errors);

    setFields(values);
    await deleteRequest(`deleteAdmin/${field.id}`);
  }

  return (
    <div>
      <CRow>
        <CCol xl="12" lg="12">
          <CModal
            show={errorModal}
            backdrop={false}
            color="warning"
            onClose={() => setErrorModal(false)}
          >
            <CModalHeader closeButton>{errorModal}</CModalHeader>
          </CModal>
          <CForm onSubmit={formik.handleSubmit} autoComplete="off">
            <CCard>
              <CCardHeader
                className="admin-management-ccardheader"
               
              >
                <h3>
                  {params.id ? (
                    <strong>Edit Role</strong>
                  ) : (
                    <strong>Add Role</strong>
                  )}
                </h3>
                <CButton
                  className="width-8rem"
                  
                  color="primary"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <strong>Back</strong>
                </CButton>
              </CCardHeader>
              <CCardBody>
                <CFormGroup>
                  <CLabel htmlFor="name">Role Name</CLabel>
                  <CInput
                    autoComplete="new-password"
                    type="name"
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    placeholder="Enter Role Name.."
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="email-validate">{formik.errors.name}</div>
                  ) : null}
                </CFormGroup>
                <CFormGroup>
                  <CLabel htmlFor="description">Add User Details</CLabel>
                  {fields.map((field, idx) => {
                    return (
                      <div>
                        <div
                          className="flex-1rem"
                          key={`${field}-${idx}`}
                         
                        >
                          <div style={{ width: "45%", marginRight: "2rem" }}>
                            <CInput
                              style={{ marginRight: "1rem" }}
                              type="text"
                              value={fields[idx].name}
                              onBlur={(e) => handleChange(idx, e)}
                              placeholder="Enter User Name"
                              onChange={(e) => handleChange(idx, e)}
                            />
                            {fieldsError &&
                            fieldsError[idx] &&
                            fieldsError[idx].name ? (
                              <div className="email-validate">
                                {fieldsError[idx].name}
                              </div>
                            ) : null}
                          </div>
                          <div
                            className="width-45"
                          
                          >
                            <CInput
                              className="margin-right-1rem"
                             
                              type="text"
                              value={fields[idx].email}
                              disabled={fields[idx].id}
                              onBlur={(e) => handleEmailChange(idx, e)}
                              placeholder="Enter User Email"
                              onChange={(e) => handleEmailChange(idx, e)}
                            />
                            {fieldsError &&
                            fieldsError[idx] &&
                            fieldsError[idx].email ? (
                              <div className="email-validate">
                                {fieldsError[idx].email}
                              </div>
                            ) : null}
                          </div>

                          {field.id !== userData?.id && idx !== 0 ? (
                            <CButton
                              className="addrole-inline-css-cbutton"
                             
                              color="danger"
                              type="button"
                              onClick={() => {
                                handleRemove(idx, field);
                              }}
                            >
                              X
                            </CButton>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                  <CButton
                    className="margin-top-1rem"
                   
                    color="primary"
                    type="button"
                    onClick={() => {
                      handleAdd();
                    }}
                  >
                    <strong>Add more user for this role</strong>
                  </CButton>
                </CFormGroup>

                <CFormGroup
                  className="addrole-cfrom-group"
                 
                >
                  <div
                    className="addrole-inline-addmin-div"
                 
                  >
                    <div
                      className="add-role-admin-inline-css"
                     
                    >
                      <CSwitch
                        size="sm"
                        variant={"3d"}
                        checked={dashboard?.module === "Dashboard"}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setDashboard({
                              module: "Dashboard",
                              permissions: [2],
                            });
                          } else {
                            setDashboard(null);
                          }
                        }}
                        color={"success"}
                      />
                      <div
                        className="addrole-admin-inline-css-div2"
                       
                      >
                        Dashboard
                      </div>
                    </div>
                    <div
                      className="addrole-inline-css-checkbox1"
                      
                    >
                      <div
                        className="addrole-inline-css-checkbox2"
                        
                      >
                        <input
                          className="addrole-inline-css-input"
                         
                          type="checkbox"
                          disabled={dashboard?.module !== "Dashboard"}
                          checked={dashboard?.permissions.includes(1)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (dashboard?.permissions) {
                                setDashboard((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 1],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 1, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (dashboard?.permissions) {
                                setDashboard((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 1
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div
                          className="font-size-1rem"
                          
                        >
                          <strong>Create</strong>
                        </div>
                      </div>
                      <div
                      
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                         
                          type="checkbox"
                          checked={dashboard?.module === "Dashboard"}
                          disabled={dashboard?.module !== "Dashboard"}
                        /> 
                        <div 
                        className="font-size-1rem" 
                     
                        >
                          <strong>View</strong>
                        </div>
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                        
                      >
                        <input
                          className="addrole-inline-css-input"
                          
                          type="checkbox"
                          disabled={dashboard?.module !== "Dashboard"}
                          checked={dashboard?.permissions.includes(3)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (dashboard?.permissions) {
                                setDashboard((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 3],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 3, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (dashboard?.permissions) {
                                setDashboard((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 3
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div   
                       
                         className="font-size-1rem">
                          <strong>Edit</strong>
                        </div>
                      </div>
                      <div
                        className="add-role-admin-inline-css"
                        
                      >
                        <input
                          className="addrole-inline-css-input"
                          
                          type="checkbox"
                          disabled={dashboard?.module !== "Dashboard"}
                          checked={dashboard?.permissions.includes(4)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (dashboard?.permissions) {
                                setDashboard((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 4],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 4, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (dashboard?.permissions) {
                                setDashboard((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 4
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div   className="font-size-1rem">
                          <strong>Delete</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="addrole-inline-addmin-div"
                   
                  >
                    <div
                      className="add-role-admin-inline-css"
                     
                    >
                      <CSwitch
                        size="sm"
                        variant={"3d"}
                        checked={
                          coachAdministration?.module === "Coach Administration"
                        }
                        onChange={(event) => {
                          if (event.target.checked) {
                            setCoachAdministration({
                              module: "Coach Administration",
                              permissions: [2],
                            });
                          } else {
                            setCoachAdministration(null);
                          }
                        }}
                        color={"success"}
                      />
                      <div 
                       className="addrole-admin-inline-css-div2"
                   
                      >
                        Coach Administration
                      </div>
                    </div>
                    <div
                      className="addrole-inline-css-checkbox1"
                      
                    >
                      <div
                      
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                         
                          type="checkbox"
                          disabled={
                            coachAdministration?.module !==
                            "Coach Administration"
                          }
                          checked={coachAdministration?.permissions.includes(1)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (coachAdministration?.permissions) {
                                setCoachAdministration((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 1],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 1, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (coachAdministration?.permissions) {
                                setCoachAdministration((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 1
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div
                          className="font-size-1rem"
                          
                        >
                          <strong>Create</strong>
                        </div>
                      </div>
                      <div
                       
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                         
                          type="checkbox"
                          checked={
                            coachAdministration?.module ===
                            "Coach Administration"
                          }
                          disabled={
                            coachAdministration?.module !==
                            "Coach Administration"
                          }
                        />
                        <div   className="font-size-1rem">
                          <strong>View</strong>
                        </div>
                      </div>
                      <div
                       
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                         
                          type="checkbox"
                          disabled={
                            coachAdministration?.module !==
                            "Coach Administration"
                          }
                          checked={coachAdministration?.permissions.includes(3)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (coachAdministration?.permissions) {
                                setCoachAdministration((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 3],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 3, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (coachAdministration?.permissions) {
                                setCoachAdministration((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 3
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div   
                       
                          className="font-size-1rem">
                          <strong>Edit</strong>
                        </div>
                      </div>
                      <div
                        
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                          
                          type="checkbox"
                          disabled={
                            coachAdministration?.module !==
                            "Coach Administration"
                          }
                          checked={coachAdministration?.permissions.includes(4)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (coachAdministration?.permissions) {
                                setCoachAdministration((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 4],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 4, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (coachAdministration?.permissions) {
                                setCoachAdministration((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 4
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div  
                        className="font-size-1rem"
                        
                        >
                          <strong>Delete</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="addrole-inline-addmin-div"
                   
                  >
                    <div
                     
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CSwitch
                        size="sm"
                        variant={"3d"}
                        checked={
                          administrationManagement?.module ===
                          "Administration Management"
                        }
                        onChange={(event) => {
                          if (event.target.checked) {
                            setAdministrationManagement({
                              module: "Administration Management",
                              permissions: [2],
                            });
                          } else {
                            setAdministrationManagement(null);
                          }
                        }}
                        color={"success"}
                      />
                      <div  
                       className="addrole-admin-inline-css-div2"
                  
                      >
                        Administration Management
                      </div>
                    </div>
                    <div
                      className="addrole-inline-css-checkbox1"
                      
                    >
                      <div
                       
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                        
                          type="checkbox"
                          disabled={
                            administrationManagement?.module !==
                            "Administration Management"
                          }
                          checked={administrationManagement?.permissions.includes(
                            1
                          )}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (administrationManagement?.permissions) {
                                setAdministrationManagement((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 1],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 1, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (administrationManagement?.permissions) {
                                setAdministrationManagement((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 1
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div
                          className="font-size-1rem"
                         
                        >
                          <strong>Create</strong>
                        </div>
                      </div>
                      <div
                       
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                        
                          type="checkbox"
                          checked={
                            administrationManagement?.module ===
                            "Administration Management"
                          }
                          disabled={
                            administrationManagement?.module !==
                            "Administration Management"
                          }
                        />
                        <div  
                        className="font-size-1rem"
                      
                        >
                          <strong>View</strong>
                        </div>
                      </div>
                      <div
                      
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                          
                          type="checkbox"
                          checked={administrationManagement?.permissions.includes(
                            3
                          )}
                          disabled={
                            administrationManagement?.module !==
                            "Administration Management"
                          }
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (administrationManagement?.permissions) {
                                setAdministrationManagement((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 3],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 3, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (administrationManagement?.permissions) {
                                setAdministrationManagement((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 3
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div style={{ fontSize: "1.2rem" }}>
                          <strong>Edit</strong>
                        </div>
                      </div>
                      <div
                        
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                        
                          type="checkbox"
                          checked={administrationManagement?.permissions.includes(
                            4
                          )}
                          disabled={
                            administrationManagement?.module !==
                            "Administration Management"
                          }
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (administrationManagement?.permissions) {
                                setAdministrationManagement((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 4],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 4, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (administrationManagement?.permissions) {
                                setAdministrationManagement((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 4
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div 
                        className="font-size-1rem" 
                      
                        >
                          <strong>Delete</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="addrole-inline-addmin-div"
                    
                  >
                    <div
                     
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CSwitch
                        size="sm"
                        variant={"3d"}
                        checked={staticContent?.module === "Static Content"}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setStaticContent({
                              module: "Static Content",
                              permissions: [2],
                            });
                          } else {
                            setStaticContent(null);
                          }
                        }}
                        color={"success"}
                      />
                      <div  
                       className="addrole-admin-inline-css-div2"
                    
                      >
                        Static Content
                      </div>
                    </div>
                    <div
                      className="addrole-inline-css-checkbox1"
                     
                    >
                      <div
                       
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                          
                          type="checkbox"
                          checked={staticContent?.permissions.includes(1)}
                          disabled={staticContent?.module !== "Static Content"}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (staticContent?.permissions) {
                                setStaticContent((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 1],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 1, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (staticContent?.permissions) {
                                setStaticContent((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 1
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div
                          className="font-size-1rem"
                         
                        >
                          <strong>Create</strong>
                        </div>
                      </div>
                      <div
                       
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"

                          type="checkbox"
                          checked={staticContent?.module === "Static Content"}
                          disabled={staticContent?.module !== "Static Content"}
                        />
                        <div  className="font-size-1rem"
                       
                        >
                          <strong>View</strong>
                        </div>
                      </div>
                      <div
                      
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                         
                          type="checkbox"
                          checked={staticContent?.permissions.includes(3)}
                          disabled={staticContent?.module !== "Static Content"}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (staticContent?.permissions) {
                                setStaticContent((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 3],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 3, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (staticContent?.permissions) {
                                setStaticContent((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 3
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div 
                        className="font-size-1rem"
                        
                     
                        >
                          <strong>Edit</strong>
                        </div>
                      </div>
                      <div
                       
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                          
                          type="checkbox"
                          checked={staticContent?.permissions.includes(4)}
                          disabled={staticContent?.module !== "Static Content"}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (staticContent?.permissions) {
                                setStaticContent((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 4],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 4, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (staticContent?.permissions) {
                                setStaticContent((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 4
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div style={{ fontSize: "1.2rem" }}>
                          <strong>Delete</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="addrole-inline-addmin-div"
                    
                  >
                    <div
                    
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CSwitch
                        size="sm"
                        variant={"3d"}
                        checked={sessionContent?.module === "Session Content"}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSessionContent({
                              module: "Session Content",
                              permissions: [2],
                            });
                          } else {
                            setSessionContent(null);
                          }
                        }}
                        color={"success"}
                      />
                      <div 
                       className="addrole-admin-inline-css-div2"
                    
                      >
                        Session Content
                      </div>
                    </div>
                    <div
                      className="addrole-inline-css-checkbox1"
                     
                    >
                      <div
                       
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                        
                          type="checkbox"
                          checked={sessionContent?.permissions.includes(1)}
                          disabled={
                            sessionContent?.module !== "Session Content"
                          }
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (sessionContent?.permissions) {
                                setSessionContent((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 1],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 1, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (sessionContent?.permissions) {
                                setSessionContent((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 1
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div
                          className="font-size-1rem"
                          
                        >
                          <strong>Create</strong>
                        </div>
                      </div>
                      <div
                        
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                          
                          type="checkbox"
                          checked={sessionContent?.module === "Session Content"}
                          disabled={
                            sessionContent?.module !== "Session Content"
                          }
                        />
                        <div  
                        className="font-size-1rem"
                       
                        >
                          <strong>View</strong>
                        </div>
                      </div>
                      <div
                        
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                          
                          type="checkbox"
                          checked={sessionContent?.permissions.includes(3)}
                          disabled={
                            sessionContent?.module !== "Session Content"
                          }
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (sessionContent?.permissions) {
                                setSessionContent((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 3],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 3, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (sessionContent?.permissions) {
                                setSessionContent((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 3
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div 
                        className="font-size-1rem"
                       
                        >
                          <strong>Edit</strong>
                        </div>
                      </div>
                      <div
                      
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="addrole-inline-css-input"
                          
                          type="checkbox"
                          checked={sessionContent?.permissions.includes(4)}
                          disabled={
                            sessionContent?.module !== "Session Content"
                          }
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (sessionContent?.permissions) {
                                setSessionContent((state) => {
                                  if (state?.permissions?.includes(2)) {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 4],
                                    };
                                  } else {
                                    return {
                                      ...state,
                                      permissions: [...state.permissions, 4, 2],
                                    };
                                  }
                                });
                              }
                            } else {
                              if (sessionContent?.permissions) {
                                setSessionContent((state) => {
                                  let newState = { ...state };
                                  let newPermission =
                                    newState.permissions.filter(
                                      (item) => item !== 4
                                    );
                                  newState.permissions = newPermission;

                                  return { ...newState };
                                });
                              }
                            }
                          }}
                        />
                        <div  
                        className="font-size-1rem"
                     
                        >
                          <strong>Delete</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </CFormGroup>
              </CCardBody>

              <CCardFooter
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <div className="spinner-border text-success" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <CButton
                    type="submit"
                    style={{ width: "5rem" }}
                    color="success"
                  >
                    <strong>Update</strong>
                  </CButton>
                )}

                <CButton
                  style={{ width: "5rem" }}
                  color="danger"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <strong>Cancel</strong>
                </CButton>
              </CCardFooter>
            </CCard>
          </CForm>
        </CCol>
      </CRow>
    </div>
  );
}
