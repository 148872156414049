import React, { useEffect, useState } from "react";
import {
  CFormGroup,
  CLabel,
  CInput,
  CCardBody,
  CForm,
  CRow,
  CCol,
  CCard,
  CButton,
  CCardHeader,
  CModalHeader,
  CModal,
} from "@coreui/react";
import { useHistory, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { getRequest, putRequest } from "src/utils/AxiosUtils";

export default function CoachReassign() {
  const params = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [successModal, setSuccessModal] = useState(null);
  const optionStyles = {
    input: (styles) => ({
      ...styles,
      width: "8rem",
      zIndex: "100",
      backgroundColor: "red",
    }),
  };

  useEffect(() => {
    const getCoachDetails = async () => {
      const res = await getRequest(`getCoachDetails?coach_id=${params.id}`);
      setData(res?.data?.body?.data);
    };

    getCoachDetails();

    const getSessionDetail = async () => {
      const res = await getRequest(`getSessionDetail?id=${params.sessionid}`);
      setSessionData(res?.data?.body?.data);
    };

    getSessionDetail();
  }, [params.id, params.sessionid]);

  const handleReassign = async () => {
    let Data = {
      id: Number(params.sessionid),
      coach_id: Number(params.id),
      slot_id: Number(params.slotid),
      date: sessionData.date,
      action: 4,
      start_time: sessionData.start_time,
      end_time: sessionData.end_time,
    };

    try {
      let res = await putRequest(`performAction`, Data);
      if (res.status === 200) {
        setSuccessModal(true);
        history.push("sessioncontent");
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <div>
      <CRow>
        <CModal
          show={successModal}
          backdrop={false}
          color="warning"
          onClose={() => setSuccessModal(false)}
        >
          <CModalHeader closeButton>
            Session reassigned successfully
          </CModalHeader>
        </CModal>
        <CCol xl="12" lg="12">
          <CForm autoComplete="off">
            <CCard>
              <CCardHeader className="admin-management-ccardheader">
                <h3>Reassign Coach</h3>
                <CButton
                  className="width-8rem"
                  color="primary"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <strong>Back</strong>
                </CButton>
              </CCardHeader>
              <CCardBody>
                <CFormGroup className="flex-flexStart">
                  <div className="width30-marginRight">
                    <CLabel htmlFor="name">Coach Name</CLabel>
                    <CInput
                      autoComplete="new-password"
                      type="name"
                      id="name"
                      name="name"
                      disabled={true}
                      value={data?.name}
                      placeholder=""
                    />
                  </div>
                  <div className="width-30rem">
                    <CLabel htmlFor="name">Mobile</CLabel>
                    <CInput
                      autoComplete="new-password"
                      type="name"
                      id="name"
                      name="name"
                      disabled={true}
                      value={data?.phone_number}
                      placeholder=""
                    />
                  </div>
                </CFormGroup>
                <CFormGroup className="flex-flexStart">
                  <div className="width-60rem">
                    <CLabel htmlFor="name">Coach Specialization</CLabel>
                    <Select
                      isDisabled={true}
                      value={data?.coach_specialization_categories}
                      name="coach_specialization_category_ids"
                      isSearchable={false}
                      isClearable={false}
                      autocomplete="off"
                      isMulti="true"
                      disabled="true"
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => `${option.id}`}
                    />
                  </div>
                </CFormGroup>
                <CFormGroup className="flex-flexStart">
                  <div className="width30-marginRight">
                    <CLabel htmlFor="name">Coach Email</CLabel>
                    <CInput
                      autoComplete="new-password"
                      type="name"
                      id="name"
                      name="name"
                      disabled={true}
                      value={data?.email}
                      placeholder=""
                    />
                  </div>
                </CFormGroup>
                <CFormGroup className="flex-flexStart">
                  <div className="width-60rem">
                    <CLabel htmlFor="name">Coach Team Level</CLabel>
                    <Select
                      styles={optionStyles}
                      isDisabled={true}
                      value={data?.employee_ranks}
                      name="employee_ranks"
                      isSearchable={false}
                      isClearable={false}
                      autocomplete="off"
                      isMulti="true"
                      disabled="true"
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => `${option.id}`}
                    />
                  </div>
                </CFormGroup>

                <div className="marginTop-flex-justifyContent">
                  <CButton
                    className="margin-right-1rem  width-8rem "
                    color="primary"
                    onClick={handleReassign}
                  >
                    Reassign
                  </CButton>
                </div>
              </CCardBody>
            </CCard>
          </CForm>
        </CCol>
      </CRow>
    </div>
  );
}
