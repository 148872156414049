import * as actions from '../type.action'
const initialState = {
  errorMsg: '',
  sucessMsg: '',
  ratingData: {},
  rateDetailData: {},
  chatPopupData: [],
  chatTitle: '',
  chatRoomdata: {},
  chatUser: [],
  chatList: []
}

const chatReducer = (state, action) => {
  let newState = {};
  let obj = [];
  let id = 0;
  if (state === undefined) {
    return (state = initialState)
  }
  switch (action.type) {
    case actions.CHATPOPUP_DATASUCCESS:
      return {
        ...state,
        chatPopupData: action.data
      }
    case 'SETCHATTITLE':
      obj = Object.assign([], state.chatPopupData);
      
      id = action.data.id
      obj.forEach((e, index) => {
        if (index === id) {
          e['select'] = true

        }
        else {
          e['select'] = false
        }
      });
      newState = Object.assign({}, state, {
        chatPopupData: obj,
        chatTitle: action.data.data.label
      });
      
      return newState;
    case actions.CHATROOM_DATASUCCESS:
      return {
        ...state,
        chatRoomdata: action.data
      }
    
    

    
    
    
    
    
    
    
    
    
    
    

    
    

    
    
    
    

    
    case 'setChatUser':
      return {
        ...state,
        chatUser: action.value,
      }
    
    
    
    
    

    default:
      return state
  }
}
export default chatReducer
