import React, { useEffect, useRef, useState } from "react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react";
import firebase from "firebase";
import { getRequest, postRequest } from "src/utils/AxiosUtils";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import profile1 from "../../assets/images/profile1.png"

export default function ChatCoach() {
  const { id } = useParams();
  const [roomId, setRoomId] = useState(null);
  const limit = 1000
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [chat_room_id, setChatRoomId] = useState("");

  const emilyIco =
    "https://chatscope.io/storybook/react/static/media/emily.d34aecd9.svg";

  const inputRef = useRef();
  useEffect(() => inputRef.current.focus(), []);

  const [messages, setMessages] = useState([]);
  useEffect(() => {
    let unsub;
    if (roomId?.room_id) {
      const chatCollection = firebase.firestore().collection("chats_dev");
      const chatRef = chatCollection.doc(roomId?.room_id);
      const messageRef = chatRef.collection("messages");
      const query = messageRef.orderBy("timestamp", "desc").limit(limit);
      const snapshot = query.get().then((res) => res);
      if (snapshot.empty) {
        return;
      }

      unsub = query.onSnapshot((snapshots) => {
        try {
          if (!snapshots.empty)  {
            snapshots.docChanges().forEach((change) => {
              let updatedData = snapshots.docs.map((doc) => doc.data());
              setMessages(updatedData.reverse());
            });
          }
        } catch (err) {
          console.log(err)
        }
      });
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [roomId?.room_id, limit]);

  useEffect(() => {
    const getChatRoomId = async () => {
      setLoading(true);
      const result = await getRequest(`getChatRoomId?other_user_id=${id}`);

      setRoomId(result.data.body.data);
      setChatRoomId(result.data.body.data.room_id);
      setLoading(false);
    };

    getChatRoomId();
  }, [id]);

  function addMessage(msgObj) {
    const executor = async (resolve, reject) => {
      try {
        const collection = firebase.firestore().collection("chats_dev");
        const chatRef = collection.doc(roomId?.room_id);
        const messagesCollection = chatRef.collection("messages");
        const addMsg = await messagesCollection.add(msgObj);

        resolve(addMsg);
      } catch (error) {
        reject(error);
      }
    };
    return new Promise(executor);
  }

  const handleSubmit = (msg) => {
    const order = messages.length + 1;

    const message = {
      _id: Math.random().toString(),
      type: "text",
      text: msg.replace(/&nbsp;|,/g, " "),
      timestamp: new Date().getTime().toString(),
      createdAt: new Date().getTime(),
      user: {
        avatar: roomId?.user.profile_pic_url,
        name: roomId?.user?.name,
        _id: roomId?.user?.id,
      },
      receiver_id: {
        avatar: roomId?.other_user.profile_pic_url,
        name: roomId?.other_user?.name,
        _id: roomId?.other_user?.id,
      },
      order: order,
      isRead: false,

      reactionId: 0,
    };

    addMessage(message);

    sendNotifi(msg.replace(/&nbsp;|,/g, " "));
  };


  const renderHeading = (index, msg) => {
    if (index === 0) {
      return (
        <MessageSeparator>
          {moment(msg.createdAt).format("MMMM Do YYYY")}
        </MessageSeparator>
      );
    }

    if (
      moment(msg.createdAt).format("MMMM Do YYYY") !==
      moment(messages[index - 1].createdAt).format("MMMM Do YYYY")
    ) {
      return (
        <MessageSeparator>
          {moment(msg.createdAt).format("MMMM Do YYYY")}
        </MessageSeparator>
      );
    }
  };

  const sendNotifi = (message) => {
    const sendnotifichat = async () => {
      const body = { chat_room_id, message };

      await postRequest(`sendChatNotification`, body, 3);
    };
    sendnotifichat();
  };

  return (
    <div
      className="App"
      style={{ ...styles, position: "relative", height: "85vh" }}
    >
      <MainContainer>
        <ChatContainer>
          <ConversationHeader VideoCallButton={false}>
            <ConversationHeader.Back onClick={() => history.goBack()} />
            <Avatar
              src={roomId?.other_user?.profile_pic_url || profile1}
              name="Emily"
            />
            <ConversationHeader.Content
              userName={roomId?.other_user?.name || ""}
            />
            <ConversationHeader.Actions></ConversationHeader.Actions>
          </ConversationHeader>
          <MessageList loading={loading} className="message-customstyle">
            {messages?.map((msg, index) => {
              if (msg?.user?._id === roomId?.user?.id) {
                return (
                  <>
                    {renderHeading(index, msg)}
                    <Message
                      style={{ paddingRight: "2rem" }}
                      key={msg.id}
                      model={{
                        message: msg?.text,
                        sentTime: "15 mins ago",
                        sender: "Emily",
                        direction: "outgoing",
                        position: "first",
                      }}
                      avatarPosition="tr"
                    >
                      <Message.Footer
                        sentTime={moment(msg.createdAt).format("h:mm a")}
                      />

                      <Avatar
                        src={roomId?.user?.profile_pic_url || profile1}
                        name={"Emily"}
                      />
                    </Message>
                  </>
                );
              }
              return (
                <>
                  {renderHeading(index, msg)}
                  <Message
                    key={msg.id}
                    model={{
                      message: msg?.text,

                      sentTime: "15 mins ago",
                      sender: "Emily",
                      direction: "incoming",
                      position: "first",
                    }}
                    avatarPosition="tl"
                  >
                    <Message.Footer
                      sentTime={moment(msg.createdAt).format("h:mm a")}
                    />
                    <Avatar
                      src={roomId?.other_user?.profile_pic_url || profile1}
                      name={"Emily"}
                    />
                  </Message>
                </>
              );
            })}
          </MessageList>
          <MessageInput
            ref={inputRef}
            onSend={handleSubmit}
            placeholder="Type message here"
            attachButton={false}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
}
