const initialState = {
    show: null,
    showFromChat: null,
    subs: []
}

const unmountReducer = (state, action) => {
    if (state === undefined) {
        return (state = initialState)
    }

    switch (action.type) {
        case 'UNMOUNT':
            return {
                ...state,
                show: true
            }
        case 'MOUNT':
            return {
                ...state,
                show: false
            }
        case 'MOUNTFROMCHAT':
            return {
                ...state,
                showFromChat: false
            }
        case 'UNMOUNTFROMCHAT':
            return {
                ...state,
                showFromChat: true
            }
        case 'ADDSUBS':
            return {
                ...state,
                subs: action.payload
            }
        default:
            return state
    }

}

export default unmountReducer;