import globalReducer from './global.reducer';
import profileReducer from './profile.reducer';
import loginReducer from './login.reducer'
import permissionReducer from './permission.reducer';
import chatReducer from "./chat.reducer";
import unmountReducer from "./unmount.reducer";
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  globalReducer: globalReducer,
  profileReducer: profileReducer,
  permissionReducer: permissionReducer,
  chatReducer: chatReducer,
  unmountReducer: unmountReducer,
  loginReducer: loginReducer,
})

export default rootReducer