import firebase from "firebase";
const firebaseApp = firebase.initializeApp({

    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId:process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket:process.env.REACT_APP_FIREBSE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
})

const db = firebaseApp.firestore();
const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

export const requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
        if(!messaging) {
            reject("Firebase messaging not supported")
        }
        messaging?.getToken()
            .then((firebaseToken) => {
                resolve(firebaseToken);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const onMessageListener = () =>
    new Promise((resolve, reject) => {
        if(!messaging) {
            reject("Firebase messaging not supported")
        }
        messaging?.onMessage((payload) => {
            resolve(payload);
        });
    });


export { db }