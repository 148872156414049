import axios from "axios";

var baseUrl = "https://api.bluxinga.com/api/v1/admin/";
const employerbaseUrl = "https://api.bluxinga.com/api/v1/employer/";
const url = "https://api.bluxinga.com/api/v1/employee/";
const webbaseUrl = "https://api.bluxinga.com/api/v1/web/";
const coachbaseUrl = "https://api.bluxinga.com/api/v1/coach/";

let coach_token= localStorage.getItem("token");
let token;


export const isLoggedIn = () => {
  if (
    localStorage.getItem("s_token") === undefined ||
    localStorage.getItem("s_token") === null
  ) {
    return false;
  }
  return true;
};

export const coach_isLoggedIn = () => {
  if (
    localStorage.getItem("token") === undefined ||
    localStorage.getItem("token") === null
  ) {
    return false;
  }
  return true;
};


export const GET = async (getUrl, usertype) => {

  if (usertype === "coachweb") {
    baseUrl = webbaseUrl;
    token=localStorage.getItem("s_token");


  } else if (usertype === "coach") {
    
    baseUrl = coachbaseUrl;
    token=localStorage.getItem("token");
  }

  const Url = `${baseUrl.concat(getUrl)}`;
 

  const data = await fetch(Url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    })
    if (data?.status === 401 || data?.status === 402) {
      window.location = "/";
      localStorage.clear();
      alert("session expired!!Please Login");
    } 
  return data;
};

export const WebGetAPI = (path) => {
  return axios({
    method: "GET",
    url: webbaseUrl + path,
    headers: { "content-type": "application/json" },
  });
};

export const putAPI = (path, data, loginstatus = 0) => {

  const state = 1;
  if (state || state == 0) {
    loginstatus = state;
  }
 

  let Finalurl =
    loginstatus === 2 ? webbaseUrl : loginstatus === 3 ? coachbaseUrl : baseUrl;

  return axios({
    method: "put",
    url: Finalurl + path,
    headers: { "content-type": "application/json", Authorization: token },
    data: data,
  });
};

export const putAPICoach = (path, data, loginstatus = 0) => {
 
  const state = 1;
  if (state || state == 0) {
    loginstatus = state;
  }
 

  let Finalurl = coachbaseUrl;

  return axios({
    method: "put",
    url: Finalurl + path,
    headers: { "content-type": "application/json", Authorization: token },
    data: data,
  });
};

export const RESETPOST = async (posturl, data, finaltoken) => {
  const finalurl = url + posturl;

  const Data = JSON.stringify(data);
  const ResponseData = await fetch(finalurl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: finaltoken,
    },
    body: Data,
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
  return ResponseData;
};

export const RESETPOSTCUSTOM = async (
  posturl,
  data,
  finaltoken,
  loginstatus = 0
) => {

  const state = localStorage.getItem("loginRole");

  if (state || state == 0) {
    loginstatus = state;
  }
  let FinalBaseurl =
    loginstatus === 0
      ? baseUrl
      : loginstatus === 1
      ? employerbaseUrl
      : coachbaseUrl;
  const finalurl = FinalBaseurl + posturl;
  const Data = JSON.stringify(data);
  const ResponseData = await fetch(finalurl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    body: Data,
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
  if (ResponseData?.status === 401 || ResponseData?.status === 402) {
    window.location = "/home";
    localStorage.clear();
    alert("session expired!!Please Login Again");
  } else {
    return ResponseData;
  }
};

export const RESETPOSTCALL = async (posturl, data, loginstatus = 0) => {

 
  const state = localStorage.getItem("loginRole");
  if (state || state == 0) {
    loginstatus = state;
  }
  let FinalBaseurl =
    loginstatus === 0
      ? baseUrl
      : loginstatus === 1
      ? employerbaseUrl
      : coachbaseUrl;
  const finalurl = FinalBaseurl + posturl;
  const Data = JSON.stringify(data);
  
  const ResponseData = await fetch(finalurl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    body: Data,
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
  if (ResponseData?.status === 401 || ResponseData?.status === 402) {
    window.location = "/home";
    localStorage.clear();
    alert("session expired!!Please Login Again");
  } else {
    return ResponseData;
  }
};

export const POST = async (posturl, data, loginstatus) => {
  let base =
    loginstatus === 2 ? webbaseUrl : loginstatus === 3 ? coachbaseUrl : baseUrl;
  const finalurl = base + posturl;
 
  const Data = JSON.stringify(data);
  const ResponseData = await fetch(finalurl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    body: Data,
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
  return ResponseData;
};

export const POSTIMAGE = async (posturl, data) => {
  let urls;
  if (posturl === "Coach") {
    urls = `${coachbaseUrl.concat("uploadFile")}`;
    token=coach_token
  } else {
    urls = `${baseUrl.concat(posturl)}`;
    token=localStorage.getItem("s_token");

  }

 
  const ResponseData = await fetch(urls, {
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: data,
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
  return ResponseData;
};

export const deleteChatThread = async (posturl, data, loginstatus = 0) => {
  const state = localStorage.getItem("loginRole");
  if (state || state == 0) {
    loginstatus = state;
  }
  let FinalbaseUrl =
    loginstatus === 0
      ? baseUrl
      : loginstatus === 1
      ? employerbaseUrl
      : coachbaseUrl;

  const finalurl = FinalbaseUrl + posturl;

  const ResponseData = await axios(finalurl, {
    data,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    method: "DELETE",
  }).catch((error) => console.log(error));

  if (ResponseData?.status === 401 || ResponseData?.status === 402) {
    window.location = "/home";
    localStorage.clear();
    alert("session expired!!Please Login Again");
  } else {
    return ResponseData;
  }
};

export const deleteChatSession = async (data) => {
 
  let loginstatus;
  const state = localStorage.getItem("loginRole");
  if (state || state == 0) {
    loginstatus = state;
  }

  let param;
  if (loginstatus == 0) {
    param = "employee";
  } else if (loginstatus == 2) {
    param = "coach";
  }
  const res = await fetch(
    `https://api.bluxinga.com/api/v1/${param}/dropChatSession`,
    {
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    }
  );

  const result = await res.json();
  return result;
};

export const RESET_PASSWORD = (path, data, finalToken) => {
  const Data = JSON.stringify(data);
  return axios({
    method: "post",
    url: coachbaseUrl + path,
    headers: {
      "content-type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: Data,
  });
};


export const Coach_RESET_PASSWORD = (path, data, finalToken) => {
  const Data = JSON.stringify(data);
  return axios({
    method: "post",
    url: url + path,
    headers: {
      "content-type": "application/json",
      Authorization:finalToken
    },
    data: Data,
  });
};