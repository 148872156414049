import 'react-app-polyfill/ie11'; 
import 'react-app-polyfill/stable';
import 'core-js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { icons } from './assets/icons'
import { Provider } from 'react-redux'
import configurestore from './store'


React.icons = icons
if (process.env.NODE_ENV !== "development")
  console.log = console.warn = console.error = () => { };

ReactDOM.render(
  <Provider store={configurestore}>
    <App />
  </Provider>,
  document.getElementById('root')
);








