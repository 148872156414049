import React from "react";
import {
  CModal,
  CModalBody,
  CModalFooter,
  CButton,
  CModalTitle,
  CModalHeader,
} from "@coreui/react";

export default function Modal(props) {
  return (
    <div>
      <CModal
        show={props.showmodal}
        centered={true}
        backdrop={true}
        color="warning"
        style={{
          background: !props.title ? "transparent" : "",
          border: !props.title ? "none" : "",
        }}
        onClose={() => {
          props.onCLose();
        }}
      >
        <CModalHeader
          style={{
            border: !props.title ? "none" : "",
            backgroundColor: "white",
            color: "black",
          }}
        >
          {props.title && <CModalTitle>{props.title}</CModalTitle>}
        </CModalHeader>
        <CModalBody>{props.body}</CModalBody>
        {props.handleClick && (
          <CModalFooter style={{ display: "flex", flexDirection: "row" }}>
            <CButton
              style={{
                width: props.yesButtonText ? "12rem" : "6rem",
                height: "3rem",
                backgroundColor: "#F56A14",
                color: "white",
              }}
              onClick={() => props.handleClick()}
            >
              {props.yesButtonText ? props.yesButtonText : "Yes"}
            </CButton>

            <CButton
              style={{
                width: "6rem",
                height: "3rem",
                backgroundColor: "#4B9CEB",
                color: "white",
              }}
              onClick={() => {
                props.onCLose();
              }}
            >
              Cancel
            </CButton>
          </CModalFooter>
        )}
      </CModal>
    </div>
  );
}
