
import * as actions from '../type.action'
const initialState = {
    permissions: null
}

const permissionReducer = (state, action) => {
    if (state === undefined) {
        return (state = initialState)
    }
    switch (action.type) {
        case actions.SET_PERMISSION:
            return {
                ...state,
                permissions: action.payload
            }
        default:
            return state
    }
}
export default permissionReducer
