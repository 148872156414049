import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CTooltip,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CButton,
  CModalTitle,
  CInput,
} from "@coreui/react";
import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { deleteRequest, getRequest, putRequest } from "src/utils/AxiosUtils";
import usePermissions from "src/hooks/usePermissions";
import DefaultUser from "src/assets/svgs/defaultUser";
import { isValidHttpUrl } from "src/utils/CommonUtils";


const fields = [
  { key: "coach_name", label: "Coach Name", _style: { fontFamily: "Poppins" } },
  { key: "image", label: "Image", _style: { fontFamily: "Poppins" } },
  {
    key: "description",
    label: "Description",
    _style: { fontFamily: "Poppins" },
  },

  {
    key: "show_details",
    label: "Action",
    _style: {
      minWidth: "4rem",
      height: "4.2rem",
      justifyContent: "space-evenly",
      alignItems: "center",
      fontFamily: "Poppins",
    },
    sorter: false,
    filter: false,
  },
];
const CoachBios = (props) => {
  const history = useHistory();
  const isPermited = usePermissions("staticcontent");

  if (!isPermited.status) {
    history.push(isPermited.pushto);
  }
  const token = localStorage.getItem("s_token");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const queryPageSEarch = useLocation().search.match(
    /search=([A-Za-z0-9 _@]+)/,
    ""
  );
  const queryStatus = useLocation().search.match(
    /module=([A-Za-z0-9 _@]+)/,
    ""
  );

  const currentStatus = queryStatus && queryStatus[1] ? queryStatus[1] : "";

  const currentPageSearch =
    queryPageSEarch && queryPageSEarch[1] ? queryPageSEarch[1] : "";

  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);

  const [page, setPage] = useState(currentPage);

  const [data, setData] = useState([]);
  const [userId, setUserId] = useState(null);
  const active=null;
  const [enableModal, setEnableModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalError, setDeleteModalError] = useState(false);
  const [statusInputval, setStatusInputval] = useState("");

  const [onInputChange, setOnInputChange] = useState({
    onsearchCHange: currentPageSearch ? currentPageSearch : "",
    onStatusChange: currentStatus ? currentStatus : "",
  });

  const pageChange = (newPage) => {
    let newPage1 = newPage;
    if (newPage1 === 0) {
      newPage1 = 1;
    }
    currentPage !== newPage &&
      history.push(
        `/staticcontent?search=${onInputChange.onsearchCHange}&&page=${newPage1}&&module=${onInputChange.onStatusChange}`
      );
  };


  const handleEnable = async () => {
    try {
      setEnableModal(!enableModal);
      let pass;
      if (active) {
        pass = 0;
      } else {
        pass = 1;
      }
      await putRequest("updateAdminAndRoleStatus", {
        id: userId,
        status: pass,
      });
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setRefresh(!refresh);
    }
  };

  const toggleDelete = (id) => {
    setUserId(id);
    setDeleteModal(!deleteModal);
  };

  const handleDelete = async () => {
    try {
      setDeleteModal(!deleteModal);

      const res = await deleteRequest(`deleteBios/${userId}`);
      if (res.status === 200) {
        setRefresh(!refresh);
      }
    } catch (error) {
     
      setDeleteModalError(error?.response?.data?.message);
    }
  };

  const handleReset = () => {
    setStatusInputval(null);
  
    setOnInputChange((preState) => {
      return {
        ...preState,
        onsearchCHange: "",
        onRankChange: "",
        onCoachSpecializationChagne: "",
        onRatingChange: "",
        onStatusChange: null,
      };
    });

    let newPage = page;
    if (newPage === 0) {
      newPage = 1;
    }
    history.push(`staticcontent?search=&&page=${newPage}`);
  };

  const handleSearch = async () => {
    (currentPageSearch !== onInputChange.onsearchCHange ||
      currentStatus !== onInputChange.onStatusChange) &&
      history.push(
        `/staticcontent?search=${onInputChange.onsearchCHange}&&page=${page}&&module=${onInputChange.onStatusChange}`
      );
  };

  const handleSearchChange = (e) => {
    setOnInputChange((preState) => {
      return {
        ...preState,
        onsearchCHange: e.target.value,
      };
    });
  };

  useEffect(() => {
    //statusInputval
    if (currentStatus) {
      if (currentStatus === "0") {
        setStatusInputval({
          name: "Inactive",
          id: 0,
        });
      } else if (currentStatus === "1") {
        setStatusInputval({
          name: "Active",
          id: 1,
        });
      }
    }

    const getData = async () => {
      let searchText="";
      if (currentPageSearch) {
        searchText = `searchKey=${currentPageSearch}`;
      } 


      try {
        setLoading(true);
        const Data = await getRequest(
          `getBios?offset=${currentPage}&&limit=${10}&&${searchText}`
        );

        Data.data.body.data.rows.map((item) => {
          item._classes = "catTableItem";
          return item;
        });

        setData(Data.data.body.data);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getData();

    currentPage !== page && setPage(currentPage);
  }, [currentPage, currentPageSearch, refresh, token, page, currentStatus]);

  return (
    <CRow>
      <CModal
        show={location.state && location.state.data ? true : false}
        size="sm"
        backdrop={false}
        color="warning"
      >
        <CModalHeader closeButton>
          {location.state && location.state.data !== null
            ? location.state.data
            : ""}
        </CModalHeader>
      </CModal>
      <CModal
        show={deleteModalError}
        size="sm"
        backdrop={false}
        color="warning"
        onClose={() => setDeleteModalError(false)}
      >
        <CModalHeader closeButton>{deleteModalError}</CModalHeader>
      </CModal>
      <CModal
        show={enableModal}
        centered={true}
        color="warning"
        onClose={setEnableModal}
        backdrop={true}
      className="fontFamilyPoppins"
      >
        <CModalHeader className="height3rem">
          <CModalTitle>
            {active ? "Deactivate User?" : "Activate User?"}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          {active
            ? "Are you sure you want to Deactivate this Role?"
            : "Are you sure you want to Activate this Role?"}
        </CModalBody>
        <CModalFooter className="height4rem">
          <CButton color="success" onClick={handleEnable}>
            Yes
          </CButton>
          <CButton color="secondary" onClick={() => setEnableModal(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
      <CModal
        show={deleteModal}
        centered={true}
        backdrop={true}
        color="warning"
        onClose={setDeleteModal}
      >
        <CModalHeader>
          <CModalTitle>Delete Bio?</CModalTitle>
        </CModalHeader>
        <CModalBody>Are you sure you want to Delete this Bio?</CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={handleDelete}>
            Yes
          </CButton>{" "}
          <CButton color="secondary" onClick={() => setDeleteModal(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
      <CCol xxl={12} className="share-myprofile">
        <CCard>
          <CCardBody>
            <CDataTable
              items={data.rows}
              fields={fields}
              hover
              border
              addTableClasses="table-class"
              striped
              loading={loading}
              noItemsViewSlot={
                !loading ? (
                  <div
                  className="coachavilabilityDataBody"
                  >
                    No Results
                  </div>
                ) : (
                  <div className="height14rem"></div>
                )
              }
              clickableRows
              overTableSlot={
                <CCol
              className="admin-management-table"
                  
                >
                  <div className="education-box-myprofilelist">
                    <CInput
                     className="minHeightMarginright"
                      value={onInputChange.onsearchCHange}
                      onChange={handleSearchChange}
                      autocomplete="off"
                      id="input1-group1"
                      name="input1-group1"
                      placeholder="Search by Coach Name"
                    />
                  </div>

                  <div className=".Cmodal-footer" style={{display:'flex', flexDirection:'row'}}>
                    <CButton
                      onClick={handleSearch}
                      disabled={!onInputChange.onsearchCHange}
                     className="margin-left-1rem"
                      color="info"
                    >
                      Search
                    </CButton>
                    <CButton
                      onClick={() => {
                        handleReset();
                      }}
                     className="margin-left-1rem"
                      color="info"
                    >
                      Reset
                    </CButton>
                  </div>
                  {props.active === 1 &&
                  isPermited.permissionArray.includes(1) ? (
                    <CButton
                    className="coach-no-coach-found add-button"
                 
                      color="success"
                      onClick={() => {
                        history.push("/staticcontent/addbio");
                      }}
                    >
                      <strong>Add Bio</strong>
                    </CButton>
                  ) : null}
                </CCol>
              }
              scopedSlots={{
                coach_name: (item) => {
                  return <td>{item?.coach_management?.name}</td>;
                },
                image: (item) => {
                  return (
                    <td>
                      {item?.image && isValidHttpUrl(item.image) ? (
                        <img
                          src={item?.image}
                          className="img-pro-myprofile"
                          width={65}
                          height={65}
                          alt=""
                        />
                      ) : (
                        <DefaultUser />
                      )}
                    </td>
                  );
                },

                show_details: (item, index) => {
                  return (
                    <td>
                      <div
                       
                        className="py-2 coach-bios"
                      >
                        {isPermited.permissionArray.includes(3) && (
                          <CTooltip content={"Edit "} placement={"top-start"}>
                            <CIcon
                            className="admin-management-ctooltip"
                              size="lg"
                              onClick={() =>
                                history.push({
                                  pathname: `/staticcontent/editbio/${item.id}`,
                                  state: {
                                    item,
                                    prevPath: location.pathname,
                                    prevSearch: location.search,
                                  },
                                })
                              }
                              content={freeSet.cilPencil}
                            />
                          </CTooltip>
                        )}
                        {isPermited.permissionArray.includes(4) && (
                          <CTooltip
                            content={`Delete `}
                            placement={"top-start"}
                            interactive={true}
                            trigger="mouseenter"
                          >
                            <CIcon
                              onClick={() => toggleDelete(item.id)}
                              size="lg"
                             className="admin-management-cion-trash"
                              content={freeSet.cilTrash}
                            />
                          </CTooltip>
                        )}
                      </div>
                    </td>
                  );
                },
              }}
            />
            {data.count ? (
              <CPagination
                activePage={page}
                pages={Math.ceil(data.count / 10)}
                onActivePageChange={pageChange}
                doubleArrows={true}
                align="start"
              />
            ) : null}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default CoachBios;
