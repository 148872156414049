import React,{useEffect} from 'react'
import { useHistory } from "react-router";
const TheLayout = React.lazy(() => import("../containers/TheLayout"));
const CoachLayout = React.lazy(() => import("./CoachLayout"));


const MainLayout = ({ children }) => {
   const isLoggedInRole = localStorage.getItem('loginRole');
    const coachLoginToken = localStorage.getItem('token');
    const adminLoginToken = localStorage.getItem('s_token');

    const isAdminLogin =  adminLoginToken && isLoggedInRole == "1" ? true : false;
    const isCoachLogin =  coachLoginToken && isLoggedInRole == "2" ? true : false;

    const history = useHistory();

    console.log(isAdminLogin,!(isAdminLogin || isCoachLogin),14)
  // const adminLoginToken = localStorage.getItem('s_token');

  useEffect(() => {
    console.log('page laoded')
    if(!(isAdminLogin || isCoachLogin)) {
      localStorage.clear();
      
      setTimeout(() => {
        history.push('/homepage');
      }, 500);
    }
    
  }, [])
  


  return (
      <div className='main-layout-container'>
        {
          isAdminLogin ? 
              <TheLayout adminRoutes={children} /> 
              : <CoachLayout coachRoutes={children} />
            
        }
  </div>
  )
}

export default MainLayout