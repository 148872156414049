import axios from "axios";
import { base_url } from "../constants/ApiConstants";
import { getToken } from "./CommonUtils";
import { openNotificationWithIcon } from "./Notifications";
import history from "src/history";

const axiosInstance = axios.create({
    baseURL: base_url
});

axiosInstance.interceptors.response.use(
    response => {

        return response
    },
    error => {


        if (error.response) {


            if (error.response.status === 401) {

                history.push('/homepage')

            }

            if (error.response.status === 409) {
                console.log(error.response.data.error.message);
                openNotificationWithIcon("error", "Error", error.response.data.error ? error.response.data.error.message : 'Please try after sometime!');

            }

            if (error.response.status === 500) {
                console.log(error.response.data.error.err.errors[0].message);
                openNotificationWithIcon("error", "Error", error.response.data.error ? error?.response?.data?.error?.err?.errors[0].message : 'Please try after sometime!');

            }

        }


        return Promise.reject(error);
    }
)








export const uploadFile = (body) => {
    return fetch(`${base_url}uploadFile`, {
        body: body,
        headers: {
            Accept: "application/json",
            Authorization: `${getToken()}`,
            
        },
        method: "POST"
    }).then(res => res.json())
}

export const postRequest = (url, data) => {
    return axiosInstance({

        method: "post",
        url,
        data,
        headers: {
            "authorization": `${getToken()}`,
        },
        onUploadProgress: (prog) => {
            console.log(prog)
        }
    });
}


export const getRequest = (url) => {
    return axiosInstance({
        method: "get",
        url,
        headers: { "authorization": `${getToken()}` }
    });
}


export const deleteRequest = (url) => {
    return axiosInstance({
        method: "delete",
        url,
        headers: { "authorization": `${getToken()}` }
    });
}


export const putRequest = (url, data) => {
    return axiosInstance({
        method: "put",
        url,
        data,
        headers: { "authorization": `${getToken()}` }
    });
}