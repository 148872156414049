import React from "react";
import { Link } from "react-router-dom";
import "../styles/_blue.scss";

const Footer = () => {

  return (
    <React.Fragment>
      <div className="home-container">
        <section className="footer-wrapper">
          <nav className="navbar navbar-expand-md navbar-new-bottom">
            <div className="navbar-collapse" id="navbar2">
              <ul className="navbar-nav w-100 justify-content-center">
                <li className="nav-item active">
                  <Link
                   
                    className="nav-link  color-white"
                    to="/homepage"
                  >
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                  
                    className="nav-link  color-white"
                    to="/privacypolicy"
                  >
                    Privacy Policy
                  </Link>
                </li>
               
                <li className="nav-item">
                  <Link
                  
                    className="nav-link  color-white"
                    to="/pricing"
                  >
                    Pricing
                  </Link>
                </li>
              

                <li className="nav-item">
                  <Link
                    
                    className="nav-link  color-white"
                    to="/coaches"
                  >
                    Coaches
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                   
                    className="nav-link  color-white"
                    to="/termcondition"
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                  
                    className="nav-link color-white"
                    to="/contactushome"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <hr className="footer-hr" />
          <p className="All-Rights">© 2020 Bluxinga. All Rights Reserved</p>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Footer;
