import React, { useState, useEffect } from "react";
import logo from "../assets/images/blutango-logo.png";
import toggle from "../assets/images/toggle.png";
import notification from "../assets/images/notification.png";
import { useLocation, Link, useHistory } from "react-router-dom";
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { ToastContainer } from "react-toastify";
import { putAPI, GET } from "../utils/helper";
import cross from "../assets/images/cross.png";
import { default as MyModal } from "./Modal";
import { default as Notify } from "./Notification";

import "../styles/_blue.scss";

const Header = (props) => {
 
  const token = localStorage.getItem("token");
  const unauthRoutes = [
    "/coach/login",
    "/resetpassword",
    "/coachForgetPassword",
    "/coachResetPassword",
    "/coachResetpswrd",
  ];
  let location = useLocation();
  let history = useHistory();
  const showTitle = !unauthRoutes.includes(location.pathname);
  const [logoutModal, setLogoutModal] = useState(false);

  const [visible, setVisible] = useState(false);
  const [notifi, setnotifi] = useState({ chat: 0, all: 0, chat_only: 0 });

  const handleLogout = async () => {
    try {
      await putAPI("clearEmployerDeviceToken", {});
      localStorage.clear();
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister();
        });
      }
      setLogoutModal(false);
      history.push("/homepage");
    } catch (error) {
      localStorage.clear();
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister();
        });
      }
      setLogoutModal(false);
      history.push("/homepage");
    }
  };

  const handleclick = (e) => {
    e.stopPropagation();
    setVisible(!visible);

    setnotifi({
      chat_only: 0,
    });
  };

  const getUnseenNotificationCount = async () => {
    const result = await GET(`getUnseenNotificationCount`, "coach");

    setnotifi({
      chat: result?.body?.data?.chat,
      all: result?.body?.data?.all,
      chat_only: result?.body?.data?.chat_message_only,
    });
  };
  useEffect(() => {
    localStorage.getItem("token") && getUnseenNotificationCount();
  }, [setnotifi]);

  return (
    <React.Fragment>
      <div className="home-container">
        <ToastContainer limit={1} hideProgressBar={true} />

        <section className="header-wrapper">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-md-4 col-4">
                <div className="logo">
                  <img src={logo} alt="" />
                </div>
              </div>
              {(location.pathname === "/homepage" ||
                location.pathname === "/" ||
                location.pathname === "/privacypolicy" ||
                location.pathname === "/pricing" ||
                location.pathname === "/coaches" ||
                location.pathname === "/termcondition" ||
                location.pathname === "/contactushome") &&
              !token ? (
                <>
                  <div className="col-md-4 col-4 Employee-Team"></div>
                  <div className="col-md-4 col-4 Logout">
                    <div className="dropdown">
                      <h3 data-toggle="dropdown" id="dropdownMenuButton">
                        Login
                      </h3>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <Link
                          to={"/login"}
                          className="dropdown-item"
                          style={{ display: "block" }}
                        >
                          Admin
                        </Link>
                        <Link
                          to={"/coach/login"}
                          className="dropdown-item"
                          style={{ display: "block" }}
                        >
                          Coach
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {showTitle && token ? (
                <>
                  <div className="col-md-4 Employee-Team"></div>

                  <div className="col-md-4 Logout">
                    <h3 onClick={() => setLogoutModal(true)}>Logout</h3>

                    {token ? (
                      <div className="cursor-pointer" onClick={(e) => handleclick(e)}>
                        <img
                          src={notification}
                          alt={notification}
                          style={{ marginLeft: "-31px" }}
                        />

                        {notifi.all && notifi.all > 0 ? (
                          <div
                            style={{
                              right: "25px",
                              position: "relative",
                              display: "inline",
                              marginLeft: "16px",
                            }}
                          >
                            <span
                              style={{
                                color: "white",
                                marginLeft: "-12px",
                                top: "-10px",
                                background: "#ff3e3e",
                                borderRadius: "50%",
                                height: "28px",
                                width: "28px",
                                fontSize: "14px",
                                padding: "4px",
                                position: "relative",
                                right: "-7px",
                                display: "inline-block",
                                textAlign: "center",
                              }}
                            >
                           
                              {notifi.all}

                             
                            </span>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      ""
                    )}
                    {token ? (
                      <>
                        <div className="dropdown  Logout menu noticount-wrap">
                          <button
                            data-toggle="dropdown"
                            id="dropdownMenuButton"
                          >
                            <img src={toggle} alt={toggle} />
                            {notifi.chat_only && notifi.chat_only > 0 ? (
                              <div
                                style={{
                                  right: "25px",
                                  position: "relative",
                                  display: "inline",
                                  marginLeft: "20px",
                                  top: "-10px",
                                }}
                              >
                                <span
                                  style={{
                                   

                                    color: "white",
                                marginLeft: "-12px",
                                top: "-1px",
                                background: "#ff3e3e",
                                borderRadius: "50%",
                                height: "28px",
                                width: "28px",
                                fontSize: "14px",
                                padding: "4px",
                                position: "relative",
                                right: "-7px",
                                display: "inline-block",
                                textAlign: "center",
                                  }}
                                >
                                  {notifi.chat_only}
                                </span>
                              </div>
                            ) : null}
                          </button>
                          <div
                            className="dropdown-menu   left-0"
                           // style={{left:0}}
                            aria-labelledby="dropdownMenuButton"
                          >
                            <Link
                              to={"/coach/dashboard"}
                              className="dropdown-item"
                              style={{ display: "block" }}
                            >
                              Dashboard
                            </Link>
                            <Link
                              to={"/coach/chat"}
                              className="dropdown-item"
                              style={{ display: "block" }}
                            >
                              Chat
                              {notifi.chat && notifi.chat > 0 ? (
                                <div
                                  style={{
                                    right: "25px",

                                    display: "inline",
                                    marginLeft: "16px",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "white",
                                      display: "inline-table",
                                      marginLeft: "-12px",
                                      background: "#ff3e3e",
                                      borderRadius: "50%",
                                      width: "27px",
                                      fontSize: "14px",
                                      
                                    }}
                                  >
                                    {notifi.chat_only}
                                  </span>
                                </div>
                              ) : null}
                            </Link>
                            <Link
                              to={"/coach/defineavailability"}
                              className="dropdown-item"
                              style={{ display: "block" }}
                            >
                              Define Availablity
                            </Link>
                            <Link
                              to={"/coach/mysessions"}
                              className="dropdown-item"
                              style={{ display: "block" }}
                            >
                              My Sessions
                            </Link>
                            <Link
                              to={"/coach/mysessionrequest"}
                              className="dropdown-item"
                              style={{ display: "block" }}
                            >
                              My Session Requests
                            </Link>
                            <Link
                              to={"/coach/sessionhistory"}
                              className="dropdown-item"
                              style={{ display: "block" }}
                            >
                              Session History
                            </Link>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <CModal
            style={{
              width: "638px",
              background: "#fff",
              boxShadow: "0 0 15px rgb(83 83 83 / 10%)",
              borderRadius: "14px",
              margin: "0 auto",
              padding: "43px 70px",
              marginLeft: "-6rem",
            }}
            show={visible}
            centered={true}
            color="warning"
            onClose={() => {
              setVisible(!visible);
            }}
          >
            <CModalHeader
              style={{
                fontWeight: "600",
                background: "white",
                fontSize: "4rem",
                lineHeight: "30px",
                textAlign: "center",

                color: "#6a6a6a",

                borderColor: "white",
              }}
            >
              <CModalTitle
                style={{
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "36px",
                  textAlign: "center",
                  line: "underline",
                  textDecorationLine: "underline",
                  color: "#6a6a6a",
                  paddingBottom: "0px",
                  margin: "0",
                }}
              >
                Notification
              </CModalTitle>
            </CModalHeader>
            <CModalBody>
              <div
                className="notif-wrapper"
                style={
                  {
                   
                  }
                }
              >
                <img
                  style={{
                    position: "relative",
                    top: "-140px",
                    left: "532px",

                    
                  }}
                  src={cross}
                  className="cross"
                  onClick={() => setVisible(!visible)}
                  alt=""
                />
              </div>
              {visible && <Notify />}
            </CModalBody>
          </CModal>
        </section>
      </div>
      <MyModal
        style={{ marginLeft: "-9rem", background: "white" }}
        showmodal={logoutModal}
        onCLose={() => {
          setLogoutModal(false);
        }}
        title={<div>{`Logout?`}</div>}
        body={<div>Are you sure you want to Logout</div>}
        handleClick={handleLogout}
      />
    </React.Fragment>
  );
};

export default Header;
