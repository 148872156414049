import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CTooltip,
  CModal,
  CModalHeader,
  CButton,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { getRequest, putRequest } from "src/utils/AxiosUtils";
import usePermissions from "src/hooks/usePermissions";

const fields = [
  { key: "name", label: "Coach Name", _style: { fontFamily: "Poppins" } },
  { key: "email", label: "Email", _style: { fontFamily: "Poppins" } },
  { key: "action", label: "Action", _style: { fontFamily: "Poppins" } },
];
const CoachAvailability = () => {
  const history = useHistory();
  const isPermited = usePermissions("coachadministration");
  if (!isPermited.status) {
    history.push(isPermited.pushto);
  }

  const [loading, setLoading] = useState(false);
  const params = useParams();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");

  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);

  const page= currentPage
  const [data, setData] = useState([]);
  const [sessionData, setSessionData] = useState(null);
  const [successModal, setSuccessModal] = useState(null);

  const pageChange = (newPage) => {
    let newPage1 = newPage;
    if (newPage1 === 0) {
      newPage1 = 1;
    }
  };

  const finalData = [];
  useEffect(() => {
    setLoading(true)
    const getAvailCoaches = async () => {

      const res = await getRequest(`getAvailabileCoaches?id=${params.id}`);
      setLoading(false)
      res.data.body.data.rows.forEach((element) => {
        if (element.status === 1) {
          finalData.push(element);
        }
      });
      setData(finalData);
    };

    const getSessionDetail = async () => {
      const res = await getRequest(`getSessionDetail?id=${params.id}`);
      setSessionData(res?.data?.body?.data);
    };

    getAvailCoaches();
    getSessionDetail();
  }, [params.id]);

  const handleReassign = async (e, coach_id, sessionId, id) => {
    let datas = {
      id: Number(params.id),
      coach_id: Number(coach_id),
      slot_id: Number(sessionId),
      date: sessionData.date,
      action: 4,
      start_time: sessionData.start_time,
      end_time: sessionData.end_time,
    };

    try {
      let res = await putRequest(`performAction`, datas);
      if (res.status === 200) {
        setSuccessModal(true);
        history.push(`/sessioncontent/viewsession/${params.id}`);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <CRow>
      <CModal
        show={successModal}
        backdrop={false}
        color="warning"
        onClose={() => setSuccessModal(false)}
      >
        <CModalHeader closeButton>Session reassigned successfully</CModalHeader>
      </CModal>

      <CCol xxl={12}>
        <CCard>
          <CCardHeader className="admin-management-ccardheader">
            <h2>
              <strong>View Available Coaches</strong>
            </h2>
            <CButton
              className="width-8rem"
              color="primary"
              onClick={() => {
                history.goBack();
              }}
            >
              <strong>Back</strong>
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              items={data}
              fields={fields}
              hover
              border
              addTableClasses="table-class"
              striped
              loading={loading}
              noItemsViewSlot={
                !loading ? (
                  <div
                    className="coachavilabilityDataBody"
                  >
                    No Results
                  </div>
                ) : (
                  <div className="height14rem"></div>
                )
              }
              clickableRows
              scopedSlots={{
                email: (Datas) => <td>{Datas?.email}</td>,
                action: (item) => {
                  return (
                    <td className="width20rem">
                      {isPermited.permissionArray.includes(2) && (
                        <CTooltip content={`View `} placement={"top-start"}>
                          <FontAwesomeIcon
                            color="green"
                            size="lg"
                            className="cursor-pointer"
                            onClick={() =>
                              history.push({
                                pathname: `/sessioncontent/viewreassigncoach/${item.coach_id}/${params.id}/${item.id}`,
                              })
                            }
                            icon={faEye}
                          />
                        </CTooltip>
                      )}
                      <CButton
                        className="margin8left-8width"
                        color="primary"
                        onClick={(e) => {
                          handleReassign(e, item.coach_id, item.id, params.id);
                        }}
                      >
                        Reassign
                      </CButton>
                    </td>
                  );
                },
              }}
            />
            {data.length ? (
              <CPagination
                activePage={page}
                pages={Math.ceil(data.length / 10)}
                onActivePageChange={pageChange}
                doubleArrows={true}
                align="start"
              />
            ) : null}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default CoachAvailability;
