import React, { Component } from "react";
import { Route, Switch, Router } from "react-router-dom";
import { PrivateRoute } from "./utils/PrivateRoute";
import history from "./history";
import routes from './routes';
import MainLayout from "./components/MainLayout";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"> </div>
   
  </div>
);

const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Coachlogin = React.lazy(() => import("./views/pages/login/Coachlogin"));
const Forgot = React.lazy(() => import("./views/pages/forget/forgetpassword"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Pricing = React.lazy(() => import("./views/pages/Pricing"));
const PrivacyPolicy = React.lazy(() => import("./views/pages/PrivacyPolicy"));
const Contactushome = React.lazy(() => import("./views/pages/Contactushome"));
const Coaches = React.lazy(() => import("./views/pages/Coaches"));
const TermCondition = React.lazy(() => import("./views/pages/TermCondition"));
const Homepage = React.lazy(() => import("./views/pages/Home"));
const ChangePassword = React.lazy(() =>import("./views/pages/ResetPassword/ResetPasword"));
const CoachForgot = React.lazy(() =>import("./views/pages/forget/CoachForgetpassword"));
const GroupChatDetail = React.lazy(() => import("./coachView/NewChatDetail"));
const CoachResetPassword = React.lazy(() =>import("./views/pages/ResetPassword/CoachResetPassword"));
const CoachResetpswrd = React.lazy(() =>import("./views/pages/ResetPassword/Resetpswrd"));

class Main extends Component {
  render() {
    return (
      <Router history={history}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/coach/login"
              name="Coach Login"
              render={(props) => <Coachlogin {...props} />}
            />
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/resetpassword"
              name="Reset Password"
              render={(props) => <ChangePassword {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              name="Homepage"
              path={["/homepage","/"]}
              render={(props) => <Homepage {...props} />}
            /> 
            <Route
              exact
              path="/coachResetpswrd"
              name="Coach Reset Password"
              render={(props) => <CoachResetPassword {...props} />}
            />
             <Route
            exact
            path="/coachResetPassword"
            name="Coach Reset Password"
            render={(props) => <CoachResetpswrd {...props} />}
          />
            <Route
              exact
              path="/coachForgetPassword"
              name="Coach Forgot Password"
              render={(props) => <CoachForgot {...props} />}
            />
            <Route
              exact
              path="/forgetpassword"
              name="Forgot Password"
              render={(props) => <Forgot {...props} />}
            />
            <Route
              exact
              name="Pricing"
              path="/pricing"
              render={(props) => <Pricing {...props} />}
            />
            <Route
              exact
              name="TermCondition"
              path="/termcondition"
              render={(props) => <TermCondition {...props} />}
            />
            <Route
              exact
              name="PrivacyPolicy"
              path="/privacypolicy"
              render={(props) => <PrivacyPolicy {...props} />}
            />
            <Route
              exact
              name="Coaches"
              path="/coaches"
              render={(props) => <Coaches {...props} />}
            />
            <Route
              exact
              name="ContactusHome"
              path="/contactushome"
              render={(props) => <Contactushome {...props} />}
            />
             <Route
              path="/groupChatDetail"
              name="GroupChatDetail"
              render={(props) => <GroupChatDetail {...props} />}
            />


            <MainLayout>
               

                {
                 
                  routes.map((route, idx) => {
                    return route.component && (
                      <PrivateRoute
                       
                        key={idx}
                        path={route.path || '/'}
                        exact={route.exact}
                        name={route.name}
                        component= {route.component}
                       
                        />
                    )
                  })               
                  
                }

           </MainLayout>

      
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default Main;
