import React, { useEffect, useState } from "react";
import {
  CFormGroup,
  CLabel,
  CInput,
  CCardBody,
  CForm,
  CRow,
  CCol,
  CCard,
  CButton,
  CCardHeader,
} from "@coreui/react";
import { useHistory, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { getRequest } from "src/utils/AxiosUtils";
import ReactStars from "react-rating-stars-component";

export default function ViewSession() {
  const params = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getSessionDetail = async () => {
      const res = await getRequest(`getSessionDetail?id=${params.id}`);
      setData(res?.data?.body?.data);
    };

    getSessionDetail();
  }, [params.id]);

  return (
    <div>
      <CRow>
        <CCol xl="12" lg="12">
          <CForm autoComplete="off">
            <CCard>
              <CCardHeader className="admin-management-ccardheader">
                <h3>View Session</h3>
                <CButton
                  className="width-8rem"
                  color="primary"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <strong>Back</strong>
                </CButton>
              </CCardHeader>
              <CCardBody>
                <CFormGroup className="flex-flexStart">
                  <div className="width30-marginRight">
                    <CLabel htmlFor="name">Coach Name</CLabel>
                    <CInput
                      autoComplete="new-password"
                      type="name"
                      id="name"
                      name="name"
                      disabled={true}
                      value={data?.name}
                      placeholder=""
                    />
                  </div>
                  <div className="width-30rem">
                    <CLabel htmlFor="name">Employee Team Level</CLabel>
                    <CInput
                      autoComplete="new-password"
                      type="name"
                      id="name"
                      name="name"
                      disabled={true}
                      value={data?.team_level}
                      placeholder=""
                    />
                  </div>
                </CFormGroup>

                <CFormGroup className="flex-flexStart">
                  <div className="width30-marginRight">
                    <CLabel htmlFor="name">Session Date</CLabel>
                    <CInput
                      autoComplete="new-password"
                      type="name"
                      id="name"
                      name="name"
                      disabled={true}
                      value={data?.date}
                      placeholder=""
                    />
                  </div>
                  <div className="width-30rem">
                    <CLabel htmlFor="name">Session Type</CLabel>
                    <CInput
                      autoComplete="new-password"
                      type="name"
                      id="name"
                      name="name"
                      disabled={true}
                      value={data?.type === 1 ? "Free" : "Paid"}
                      placeholder=""
                    />
                  </div>
                </CFormGroup>
                <CFormGroup className="flex-flexStart">
                  <div className="width30-marginRight">
                    <CLabel htmlFor="name">Session Duration</CLabel>
                    <CInput
                      autoComplete="new-password"
                      type="name"
                      id="name"
                      name="name"
                      disabled={true}
                      value={
                        data?.call_duration > 0
                          ? data?.call_duration + " mins"
                          : data?.call_duration + " min"
                      }
                      placeholder=""
                    />
                  </div>
                  <div className="width-30rem">
                    <CLabel htmlFor="name">Session Status</CLabel>
                    <CInput
                      autoComplete="new-password"
                      type="name"
                      id="name"
                      name="name"
                      disabled={true}
                      value={
                        data?.status === 1
                          ? "Pending"
                          : data?.status === 2
                          ? "Accepted"
                          : data?.status === 3
                          ? "Rejected"
                          : data?.status === 4
                          ? "Cancelled"
                          : data?.status === 5
                          ? "Completed"
                          : null
                      }
                      placeholder=""
                    />
                  </div>
                </CFormGroup>
                <CFormGroup className="flex-flexStart">
                  <div className="width30-marginRight">
                    <CLabel htmlFor="name">Performance Challenge</CLabel>
                    <CInput
                      autoComplete="new-password"
                      type="name"
                      id="name"
                      name="name"
                      disabled={true}
                      value={data?.coach_specialization_category}
                      placeholder=""
                    />
                  </div>
                  <div className="width-30rem">
                    <CLabel htmlFor="name">Challenge Description</CLabel>
                    <CInput
                      autoComplete="new-password"
                      type="name"
                      id="name"
                      name="name"
                      disabled={true}
                      value={data?.query}
                      placeholder=""
                    />
                  </div>
                </CFormGroup>
               { data?.cancelled_by === 0 ? 
                <CFormGroup className="flex-flexStart">
                  <div className="width30-marginRight">
                    <CLabel htmlFor="name">Comment</CLabel>
                    <CInput
                      autoComplete="new-password"
                      type="comment"
                      id="comment"
                      name="comment"
                      disabled={true}
                      value={data?.comment}
                      placeholder=""
                    />
                  </div>
                 
                  <div className="flex-dir">
                    <CLabel htmlFor="name">Rating given by client</CLabel>
                    <ReactStars
                      {...{
                        color: "#C9C9C9",
                        size: 25,
                        value: data?.coach_rating,
                        edit: false,
                        activeColor: "#FF7500",
                      }}
                    />
                  </div>
                </CFormGroup> :null}
                <CFormGroup className="flex-flexStart">
                  {data?.status == 4 ? (
                    <>
                      <div className="width30-marginRight">
                        {}
                        <CLabel htmlFor="name">Cancelled By</CLabel>
                        <CInput
                          autoComplete="new-password"
                          type="name"
                          id="name"
                          name="name"
                          disabled={true}
                          value={
                            data?.cancelled_by === 0
                              ? "Not Cancelled"
                              : data?.cancelled_by === 1
                              ? "Coach"
                              : data?.cancelled_by === 2
                              ? "Employee"
                              : ""
                          }
                          placeholder=""
                        />
                      </div>
                    </>
                  ) : null}

                </CFormGroup>
                {data?.status === 1 || data?.status === 2 ? (
                  <>
                    Would you like to reassign the session request to other
                    coach?
                    <div className="margin-top-1rem">
                      <CButton
                        className="width-8rem margin-right-1rem"
                        color="primary"
                        onClick={() => {
                          history.push(
                            `/sessioncontent/coachavailability/${params.id}`
                          );
                        }}
                      >
                        Reassign
                      </CButton>
                    </div>
                  </>
                ) : null}
              </CCardBody>
            </CCard>
          </CForm>
        </CCol>
      </CRow>
    </div>
  );
}
