import React, { useState, useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CLabel,
  CImg,
} from "@coreui/react";
import { useHistory, useParams } from "react-router-dom";
import userprofile from "../../assets/images/default_imageprofile.jpg";

import StarRatingComponent from "react-star-rating-component";
import { getRequest } from "src/utils/AxiosUtils";
import { isValidHttpUrl, convertMinsToHrsMins } from "../../utils/CommonUtils";

const ViewCoach = () => {
  const history = useHistory();
  const params = useParams();
  const [Data, setData] = useState(null);

  useEffect(() => {
    const getEmployer = async () => {
      if (params.id) {
        const datas = await getRequest(`getCoachDetails?coach_id=${params.id}`);

        setData(datas.data.body.data);
      }
    };

    getEmployer();
  }, [params.id]);

  return params.id ? (
    <>
      <CCard>
        <CCardBody>
          <CCol>
            <CCard>
              <CCardHeader 
              className='admin-management-ccardheader'
              
              >
                <CFormGroup row className="my-0">
                  <CCol xs="12">
                    <h3>View Coach Details</h3>
                  </CCol>
                </CFormGroup>
                <CButton 
                className='width-8rem'
              
                  color="primary"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <strong>Back</strong>
                </CButton>
              </CCardHeader>
              <CCardBody>
                <CImg
                  src={isValidHttpUrl(Data?.image) ? Data.image : userprofile}
                  className="c-avatar-img  viewcoach-inline-css"
                  alt="admin@bootstrapmaster.com"

                />
                <div>
                  <CFormGroup row className="my-0">
                    <CCol xs="2">
                      <CLabel htmlFor="Name">
                        <strong>Coach Name</strong>
                      </CLabel>
                    </CCol>
                    <CCol xs="4">
                      {Data && (
                        <CLabel 
                        className='view-coach-clabel'
                        
                          htmlFor="name"
                        >
                          {Data.name}
                        </CLabel>
                      )}
                    </CCol>
                    <CCol xs="2">
                      <CLabel htmlFor="select">
                        <strong>Email Address</strong>
                      </CLabel>
                    </CCol>
                    <CCol xs="4">
                      {Data && (
                        <CLabel 
                        className='view-coach-clabel1'
                      
                          htmlFor="select"
                        >
                          {Data.email}
                        </CLabel>
                      )}
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row className="my-0">
                    <CCol xs="2">
                      <CLabel htmlFor="Number">
                        <strong>Country Code</strong>
                      </CLabel>
                    </CCol>
                    <CCol xs="4">
                      {Data && (
                        <CLabel
                         className='view-coach-clabel'
                       
                          htmlFor="Number"
                        >
                          {Data.country_code}
                        </CLabel>
                      )}
                    </CCol>
                    <CCol xs="2">
                      <CLabel htmlFor="Number">
                        <strong>Phone Number</strong>
                      </CLabel>
                    </CCol>
                    <CCol xs="4">
                      {Data && (
                        <CLabel 
                         className='view-coach-clabel'
                      
                          htmlFor="Number"
                        >
                          {Data.phone_number}
                        </CLabel>
                      )}
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row className="my-0">
                    <CCol xs="2">
                      <CLabel htmlFor="Number">
                        <strong>Description</strong>
                      </CLabel>
                    </CCol>
                    <CCol xs="4">
                      {Data && (
                        <CLabel
                        className='view-coach-clabel2'
                         
                          htmlFor="Number"
                        >
                          {Data.description}
                        </CLabel>
                      )}
                    </CCol>
                    <CCol xs="2">
                      <CLabel htmlFor="Number">
                        <strong>Coach Specialization Category</strong>
                      </CLabel>
                    </CCol>
                    <CCol xs="4">
                      {Data && (
                        <CLabel 
                        className='view-coach-clabel1'
                        
                          htmlFor="Number"
                        >
                          {Data.coach_specialization_categories.map(
                            (data, index) => (
                              <span>
                                {index > 0 && ","} {data.name}
                              </span>
                            )
                          )}
                        </CLabel>
                      )}
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row className="my-0">
                    <CCol xs="2">
                      <CLabel htmlFor="Number">
                        <strong>Team Level</strong>
                      </CLabel>
                    </CCol>
                    <CCol xs="4">
                      {Data && (
                        <CLabel 
                        className='view-coach-clabel1'
                       
                          htmlFor="Number"
                        >
                          {Data.employee_ranks.map((data, index) => (
                            <span>
                              {index > 0 && ","} {data.name}
                            </span>
                          ))}
                        </CLabel>
                      )}
                    </CCol>
                    <CCol xs="2">
                      <CLabel htmlFor="Number">
                        <strong>Average Rating</strong>
                      </CLabel>
                    </CCol>
                    <CCol xs="4">
                      {Data && (
                        <CLabel
                        className='view-coach-clabel1'
                         
                          htmlFor="Number"
                        >
                          {Data?.average_rating ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                aligndatas: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <StarRatingComponent
                                starCount={Data.average_rating}
                                starColor={`#FF7500`}
                                className="star"
                                emptyStarColor={`#FF7500`}
                              />
                              <span  
                             
                              className="view-coach-span"
                               
                              >
                                {" "}
                                {`(${Data?.rating_count})`}{" "}
                              </span>
                            </div>
                          ) : (
                            "Not rated yet"
                          )}
                        </CLabel>
                      )}
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row className="my-0">
                    <CCol xs="2">
                      <CLabel htmlFor="Number">
                        <strong>No of free Sessions</strong>
                      </CLabel>
                    </CCol>
                    <CCol xs="4">
                      {Data && (
                        <CLabel 
                        className='view-coach-clabel1'
                       
                          htmlFor="Number"
                        >
                          {Data.freeSessionsCount}
                        </CLabel>
                      )}
                    </CCol>

                    <CCol xs="2">
                      <CLabel htmlFor="Number">
                        <strong>No of Paid Sessions</strong>
                      </CLabel>
                    </CCol>
                    <CCol xs="4">
                      {Data && (
                        <CLabel 
                        className='view-coach-clabel1'
                         
                          htmlFor="Number"
                        >
                          {Data.paidSessionsCount}
                        </CLabel>
                      )}
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row className="my-0">
                    <CCol xs="2">
                      <CLabel htmlFor="Number">
                        <strong>Free Session hours</strong>
                      </CLabel>
                    </CCol>
                    <CCol xs="4">
                      {Data && (
                        <CLabel 
                        className='view-coach-clabel1'
                         
                          htmlFor="Number"
                        >
                          {convertMinsToHrsMins(Data.freeSessionsMinutes)}
                        </CLabel>
                      )}
                    </CCol>

                    <CCol xs="2">
                      <CLabel htmlFor="Number">
                        <strong>Paid Session hours</strong>
                      </CLabel>
                    </CCol>
                    <CCol xs="4">
                      {Data && (
                        <CLabel 
                        className='view-coach-clabel1'
                         
                          htmlFor="Number"
                        >
                          {convertMinsToHrsMins(Data.paidSessionsMinutes)}
                        </CLabel>
                      )}
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row className="my-0">
                    <CCol xs="2">
                      <CLabel htmlFor="Number">
                        <strong>Conversion Rate</strong>
                      </CLabel>
                    </CCol>
                    <CCol xs="4">
  {Data && (
                        <CLabel 
                        className='view-coach-clabel1'
                        
                          htmlFor="Number"
                        >
                          {Data.conversionRate === null
                            ? 0
                            : Data.conversionRate.toFixed(2)}
                        </CLabel>
                      )}
                    </CCol>
                  </CFormGroup>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CCardBody>
      </CCard>
    </>
  ) : (
    history.goBack()
  );
};

export default ViewCoach;
