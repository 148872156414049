import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CButton, CModal, CModalHeader } from '@coreui/react';
import { getRequest, putRequest } from 'src/utils/AxiosUtils';

const quillEditorContainer = {
    marginTop: '2rem',
    height: "500px"
};

const quillEditor = {
    height: "80%"
};

const buttonContainer = {
    textAlign: "center"
};

function QuillEditor(props) {
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);

    useEffect(() => {
        getQuillValue(props.type);
    }, [props.type])

    const getQuillValue = async (type) => {

        try {
            setLoading(true);
            const data = await getRequest(`getStaticContent?contentType=${type}`);

            if (data.status === 200) {
                setValue(data.data.body.data[type]);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {

        }
    }

    const updateContent = async () => {
        

        try {
            setLoading(true);
            const data = await putRequest(`addStaticContent`, { [props.type]: value });

            if (data.status === 200) {
                setUpdateModal('Content updated successfully')
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {

        }
    };

    return (
        <div>
            <CModal
                show={updateModal}
                size='sm'
                backdrop={false}
                color="warning"
                onClose={() => {
                    setUpdateModal(false)
                }}
            >
                <CModalHeader closeButton>
                    {updateModal}
                </CModalHeader>

            </CModal>
            <div style={quillEditorContainer}>
                <ReactQuill style={quillEditor} theme="snow" value={value} onChange={setValue} />
            </div>
            {props.isPermited.permissionArray.includes(3) && <div style={buttonContainer}>
                {loading ? 'Loading...' : <CButton color="primary" onClick={() => updateContent()}>Update</CButton>}
            </div>}

        </div>
    )
}

export default QuillEditor

