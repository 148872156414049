import moment from 'moment';
import * as yup from 'yup';


export const validateEmail = function (data = '') {
    
    
    const email =/^(?:^[0-9]{4,15}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i
    let status = false
    if(data && data!==""){
    if (data.match(email)) {
      status = true
    } else {
      status = false
    }
  }
    return status
  }
  export const validMobile = function (text = '') {
    let status = false
    if (!isNaN(text) && text.length === 10) {
      status = true
    } else {
      status = false
    }
  
    return status
  }
  export const validatePassword = function (data = '') {
    
    const hasUpperAndLowerCase = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    let status = false
    if(data && data!==""){
    if (data.match(hasUpperAndLowerCase) && data.length>7) {
      status = true
    } else {
      status = false
    }
  }
    return status
  }

  export const errorMessage = {
    required: 'Please enter this information',
    invalid: 'Please enter valid input',
    invalidFile: 'Wrong file type is selected',
    invalidEmail:' Email should be a valid email address',
    paswrdError:'Password must have 8 character, one uppercase , one lowercase, one numeric digit,one special character',
    cofirmpaswrdError:'ReEnter password to continue',
    paswrdnotmatchError:'The password confirmation does not match.'
  }




  export const coachProfileValidation = (id) => {
    const phoneRegExp = /^\d+$/
  
    return yup.object({
      name: yup.string()
        .max(50, 'Name cannot exceed 50 characters')
        .required('Name is Required'),
      email: yup.string().email('Invalid Email address format').required('Email address is required').trim(),
      country_code: yup.string().required('Country code is required'),
      phone_number: yup.string().matches(phoneRegExp, 'Phone number is not valid').max(10, 'Phone number cannot exceed 10 digits').min(10, 'Phone number must be exactly 10 digits').required('Phone number is required'),
      description: yup.string().max(150, 'Description can not exceed 150 characters').required('Description is required')
      
    })
  
  }





  export const slotvalidation = () => {
    return yup.lazy(values => {

        return yup.object({

            session_duration: yup.number().required("Select a session duration").test(
                "Select a session duration",
                "Select a session duration",
              
                (value) => {
              
                    if (value === 0) {
                       
                        return false;
                    }else{
                      return true
                    }
                   
                    
                }
            ),
            day: yup.array().required("Select at least one session frequency").test(
                "Select at least one session frequency",
                "Select at least one session frequency",
                (value) => {
                    
                    if (value.length>=1) {
                       
                        return true
                    }
                  
                }
            ),
            time_capture_type: yup.number().required("Select a definition type").test(
                "Select a definition type",
                "Select a definition type",
                (value) => {
                    if (value===1 || value===2) {
                        return true
                    }
                  

                }
            ),
            custom_date: yup.date().test(
                "End date should be equal or greater than start date",
                "End date should be equal or greater than start date",
                (value) => {
                    if (values.date <= values.custom_date) {
                        return true
                    }
                  

                }
            ),
            timings: yup.array().of(
                yup.object().shape({
                    start_time: yup.string().required('Start time is required')
                        .test(
                            "Start time is required",
                            "Times overlap with another sets of time",
                            (value, ctx) => {
                                let index = ctx.options.index;
                                if (index === 0) {
                                    return true;
                                }
                                else {
                                    let prevIndex = index - 1;
                                    return (moment(value, 'h:mm a').isSameOrAfter(moment(values.timings[prevIndex].end_time, 'h:mm a')))

                                }

                            }
                        ),
                    
                    end_time: yup.string().required('Start time is required')
                        .test(
                            "Start time is required",
                            "Select valid time according to choosen session duration",
                            (value, ctx) => {
                                var ms = moment(ctx.parent.end_time, 'h:mm a').diff(moment(ctx.parent.start_time, 'h:mm a'), "minutes");


                                if (ms % values.session_duration == 0) {

                                    return moment(ctx.parent.start_time, 'h:mm a').isBefore(moment(value, 'h:mm a'));
                                }
                                else {

                                    return false;
                                }
                                
                            }
                        ),
                })
            )


        })
    })
}

export const getTime = (lastMessageTime) => {
	const now = new Date().getTime();
	const sec = parseInt((now - lastMessageTime) / 1000);
	if (sec < 1 * 60) {
		return 'just now';
	}
	if (sec < 1 * 60 * 60) {
		return `${parseInt(sec / 60)}min`;
	}
	if (sec < 1 * 24 * 60 * 60) {
		return `${parseInt(sec / (60 * 60))}h`;
	}
	if (sec < 1 * 30 * 24 * 60 * 60) {
		return `${parseInt(sec / (24 * 60 * 60))}d`;
	}
	return `${parseInt(sec / (30 * 24 * 60 * 60))}mth`;
};

export function timeAgo(timestamp) {
	if (!timestamp) {
		return ""
	}
	var timeStampFromServer = timestamp;
	var date = new Date(Number(timestamp))
	let hour = date.getHours();
	let minutes = date.getMinutes();
	let finalDate = "";


	var currentTime = new Date().getTime()
	let sec = parseInt((currentTime - timeStampFromServer) / 1000)
	let min = parseInt(sec / 60);
	let hours = parseInt(min / 60);
	let days = parseInt(hours / 24);
	let weeks = parseInt(days / 7)
	let months = parseInt(weeks / 4)
	let years = parseInt(months / 12)

	if (days < 1) {
		if (hour <= 12) {
			finalDate = hour + ":";
		} else if (hour > 12) {
			finalDate = (hour % 12) + ":";
		}
		if (minutes < 10) {
			finalDate = finalDate + "0" + minutes;
		}
		if (minutes >= 10) {
			finalDate = finalDate + minutes;
		}
		if (hour < 12) {
			finalDate = finalDate + " AM";
		}
		if (hour >= 12) {
			finalDate = finalDate + " PM";
		}
		return finalDate;

	}

	else if (days <= 7) {
		if (days === 1) {
			return "Yesterday"
		} else {
			return days + " days ago"
		}
	} else if (weeks <= 4.3) {
		if (weeks === 1) {
			return "a week ago"
		} else {
			return weeks + " weeks ago"
		}
	} else if (months <= 12) {
		if (months === 1) {
			return "a month ago"
		} else {
			return months + " months ago"
		}
	} else {
		if (years === 1) {
			return "one year ago"
		} else {
			return years + " years ago"
		}
	}

}

export function getDate(dateObj) {
	if (dateObj) {
		return moment(dateObj).format('YYYY-MM-DD');
	}
	return "";
}
export function getTextDate(dateObj) {
	if (dateObj) {
		return moment(dateObj).format('MM/DD/yyy');
	}
	return "";
}
