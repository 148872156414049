import React,{useState,useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => {

    const coachLoginToken = localStorage.getItem('token');
    const adminLoginToken = localStorage.getItem('s_token');
    const isLoggedIn = coachLoginToken || adminLoginToken ? true : false;
    
    return (
        <Route
            {...rest }
            render={props =>
                isLoggedIn ? (
                    <Component  {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/homepage",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />

    );
};
