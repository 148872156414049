import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CTooltip,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CButton,
  CModalTitle,
  CInput,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { deleteRequest, getRequest, putRequest } from "src/utils/AxiosUtils";
import Select from "react-select";
import usePermissions from "src/hooks/usePermissions";
const getBadge = (status) => {
  switch (status) {
    case "Active":
      return "success";
    case "Inactive":
      return "secondary";
    case "Accepted":
      return "info";
    case "Pending":
      return "warning";
    case "Banned":
      return "danger";
    default:
      return "primary";
  }
};

const fields = [
  { key: "name", label: "Coach Name", _style: { fontFamily: "Poppins" } },

  { key: "team_level", label: "Team Level", _style: { fontFamily: "Poppins" } },
  { key: "status", label: "Status", _style: { fontFamily: "Poppins" } },
  { key: "date", label: "Session Date", _style: { fontFamily: "Poppins" } },
  { key: "type", label: "Session Type", _style: { fontFamily: "Poppins" } },
  {
    key: "call_duration",
    label: "Call Duration",
    _style: { fontFamily: "Poppins" },
  },
  {
    key: "show_details",
    label: "Action",
    _style: {
      minWidth: "12rem",
      height: "4.2rem",
      justifyContent: "space-evenly",
      alignItems: "center",
      fontFamily: "Poppins",
    },
    sorter: false,
    filter: false,
  },
];
const Session = () => {
  const history = useHistory();
  const isPermited = usePermissions("sessioncontent");
  if (!isPermited.status) {
    history.push(isPermited.pushto);
  }

  const token = localStorage.getItem("s_token");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const queryPageSEarch = useLocation().search.match(
    /search=([A-Za-z0-9 _@]+)/,
    ""
  );
  const queryRankName = useLocation().search.match(
    /employeeRankName=([0-9]+)/,
    ""
  );
  const queryCoachSpecializtion = useLocation().search.match(
    /coachspecialization=([0-9]+)/,
    ""
  );
  const queryCoachRating = useLocation().search.match(/rating=([0-9]+)/, "");
  const queryStatus = useLocation().search.match(/status=([0-9-]+)/, "");
  const queryType = useLocation().search.match(/type=([0-9-]+)/, "");
  const currentRankName =
    queryRankName && queryRankName[1] ? queryRankName[1] : "";
  const currentCoachSpecialization =
    queryCoachSpecializtion && queryCoachSpecializtion[1]
      ? queryCoachSpecializtion[1]
      : "";
  const currentCoachRating =
    queryCoachRating && queryCoachRating[1] ? queryCoachRating[1] : "";
  const currentStatus = queryStatus && queryStatus[1] ? queryStatus[1] : "";
  const currentType = queryType && queryType[1] ? queryType[1] : "";
  const currentPageSearch =
    queryPageSEarch && queryPageSEarch[1] ? queryPageSEarch[1] : "";
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const [data, setData] = useState([]);
  const userId=null;
  const active=null;
  const [enableModal, setEnableModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ranks, setRankList] = useState([]);
  const [rankinputval, setrankinputval] = useState("");
  const [typeInputVal, setTypeInputVal] = useState("");
  const [onInputChange, setOnInputChange] = useState({
    onsearchCHange: currentPageSearch ? currentPageSearch : "",
    onRankChange: currentRankName ? currentRankName : "",
    onCoachSpecializationChagne: currentCoachSpecialization
      ? currentCoachSpecialization
      : "",
    onRatingChange: currentCoachRating ? currentCoachRating : "",
    onStatusChange: currentStatus ? currentStatus : "",
    onTypeChange: currentType ? currentType : "",
  });

  const pageChange = (newPage) => {
    let newPage1 = newPage;
    if (newPage1 === 0) {
      newPage1 = 1;
    }
    currentPage !== newPage &&
      history.push(
        `/sessioncontent?search=${onInputChange.onsearchCHange}&&employeeRankName=${onInputChange.onRankChange}&&page=${newPage1}&&coachspecialization=${onInputChange.onCoachSpecializationChagne}&&status=${onInputChange.onStatusChange}&&type=${onInputChange.onTypeChange}`
      );
  };

  const handleEnable = async () => {
    try {
      setEnableModal(!enableModal);
      let pass;
      if (active) {
        pass = 0;
      } else {
        pass = 1;
      }
      await putRequest("blockUnblockCoach", {
        coach_id: userId,
        status: pass,
      });
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setRefresh(!refresh);
    }
  };

  const handleDelete = async () => {
    try {
      setDeleteModal(!deleteModal);

      await deleteRequest(`deleteCoach/${userId}`);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setRefresh(!refresh);
    }
  };

  const handleSearchChange = (e) => {
    setOnInputChange((preState) => {
      return {
        ...preState,
        onsearchCHange: e.target.value,
      };
    });
  };

  const handleRankChange = (e) => {
    setrankinputval(e);
    setOnInputChange((preState) => {
      return {
        ...preState,
        onRankChange: e?.id || "",
      };
    });
  };

  const handleSearch = async () => {
    (currentPageSearch !== onInputChange.onsearchCHange ||
      currentRankName !== onInputChange.onRankChange ||
      currentCoachSpecialization !==
        onInputChange.onCoachSpecializationChagne ||
      currentStatus !== onInputChange.onStatusChange ||
      currentType !== onInputChange.onTypeChange ||
      currentCoachRating !== onInputChange.onRatingChange) &&
      history.push(
        `/sessioncontent?search=${onInputChange.onsearchCHange}&&employeeRankName=${onInputChange.onRankChange}&&page=${page}&&coachspecialization=${onInputChange.onCoachSpecializationChagne}&&status=${onInputChange.onStatusChange}&&type=${onInputChange.onTypeChange}`
      );
  };

  const handleStatusChange = (val) => {
    (currentPageSearch !== onInputChange.onsearchCHange ||
      currentRankName !== onInputChange.onRankChange ||
      currentCoachSpecialization !==
        onInputChange.onCoachSpecializationChagne ||
      currentStatus !== val ||
      currentType !== onInputChange.onTypeChange ||
      currentCoachRating !== onInputChange.onRatingChange) &&
      history.push(
        `/sessioncontent?search=${onInputChange.onsearchCHange}&&employeeRankName=${onInputChange.onRankChange}&&page=${page}&&coachspecialization=${onInputChange.onCoachSpecializationChagne}&&status=${val}&&type=${onInputChange.onTypeChange}`
      );

    setOnInputChange((preState) => {
      return {
        ...preState,
        onStatusChange: val,
      };
    });
  };

  const handleTypeChange = (e) => {
    setTypeInputVal(e);
    let statusId = null;
    if (e?.id === 0) {
      statusId = 0;
    } else {
      if (e.id) {
        statusId = e.id;
      }
    }
    setOnInputChange((preState) => {
      return {
        ...preState,
        onTypeChange: statusId,
      };
    });
  };

  const handleReset = () => {
    setrankinputval(null);
    setTypeInputVal(null);
    setOnInputChange((preState) => {
      return {
        ...preState,
        onsearchCHange: "",
        onRankChange: "",
        onCoachSpecializationChagne: "",
        onRatingChange: "",
        onStatusChange: null,
        onTypeChange: null,
      };
    });

    let newPage = page;
    if (newPage === 0) {
      newPage = 1;
    }
    history.push(`/sessioncontent?search=&&page=${newPage}`);
  };

  useEffect(() => {
    if (currentType) {
      if (currentType === "1") {
        setTypeInputVal({
          name: "Free",
          id: 1,
        });
      } else if (currentType === "2") {
        setTypeInputVal({
          name: "Paid",
          id: 2,
        });
      }
    }

    if (currentStatus) {
      if (currentStatus === "5") {
        setOnInputChange((preState) => {
          return {
            ...preState,
            onStatusChange: 5,
          };
        });
      } else if (currentStatus === "4") {
        setOnInputChange((preState) => {
          return {
            ...preState,
            onStatusChange: 4,
          };
        });
      } else if (currentStatus === "3") {
        setOnInputChange((preState) => {
          return {
            ...preState,
            onStatusChange: 3,
          };
        });
      } else if (currentStatus === "2") {
        setOnInputChange((preState) => {
          return {
            ...preState,
            onStatusChange: 2,
          };
        });
      } else if (currentStatus === "1") {
        setOnInputChange((preState) => {
          return {
            ...preState,
            onStatusChange: 1,
          };
        });
      }
    }

    const getEmployeeRankList = async () => {
      try {
        const datas = await getRequest(`listEmployeeRanks`);
        if (datas.data.status === 200) {
          datas.data.body.data.rows.map((item) => {
            if (currentRankName) {
              if (Number(item.id) === Number(currentRankName)) {
                setrankinputval(item);
              }
            }
            item._classes = "catTableItem";
            return item;
          });
          setRankList(datas.data.body.data.rows);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {}
    };

    const getData = async () => {
      let rankText;
      let searchText;
      let statusText;
      let typetext;
      if (currentPageSearch) {
        searchText = `&searchKey=${currentPageSearch}`;
      } else {
        searchText = "";
      }
      if (currentRankName) {
        rankText = `&team_level_Id=${currentRankName}`;
      } else {
        rankText = "";
      }
      if (currentStatus) {
        statusText = `&status=${currentStatus}`;
      } else {
        statusText = "";
      }

      if (currentType) {
        typetext = `&type=${currentType}`;
      } else {
        typetext = "";
      }

      try {
        setLoading(true);
        const Data = await getRequest(
          `getSessionList?offset=${currentPage}&limit=${10}${searchText}${rankText}${typetext}${statusText}`
        );

        Data.data.body.data.rows.map((item) => {
          item._classes = "catTableItem";
          return item;
        });

        setData(Data.data.body.data);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getData();
    getEmployeeRankList();

    currentPage !== page && setPage(currentPage);
  }, [
    currentPage,
    currentPageSearch,
    refresh,
    token,
    page,
    currentRankName,
    currentCoachSpecialization,
    currentCoachRating,
    currentStatus,
    currentType,
  ]);

  const optionStyles = {
    input: (styles) => ({
      ...styles,
      width: "6rem",
      zIndex: "100",
      marginRight: "4rem",
    }),
  };
  const optionStylesstatus = {
    input: (styles) => ({
      ...styles,
      width: "5rem",
      zIndex: "100",
      marginRight: "4rem",
    }),
    control: (styles) => ({ ...styles, marginLeft: "5px", marginRight: "5px" }),
  };

  return (
    <CRow>
      <CModal
        show={location.state && location.state.data ? true : false}
        size="sm"
        backdrop={false}
        color="warning"
      >
        <CModalHeader closeButton>
          {location.state && location.state.data !== null
            ? location.state.data
            : ""}
        </CModalHeader>
      </CModal>
      <CModal
        show={enableModal}
        centered={true}
        color="warning"
        onClose={setEnableModal}
        backdrop={true}
        className="fontFamilyPoppins"
      >
        <CModalHeader className="height3rem">
          <CModalTitle>
            {active ? "Deactivate User?" : "Activate User?"}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          {active
            ? "Are you sure you want to Deactivate the Coach?"
            : "Are you sure you want to Activate the Coach?"}
        </CModalBody>
        <CModalFooter className="height4rem">
          <CButton color="success" onClick={handleEnable}>
            Yes
          </CButton>
          <CButton color="secondary" onClick={() => setEnableModal(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
      <CModal
        show={deleteModal}
        centered={true}
        backdrop={true}
        color="warning"
        onClose={setDeleteModal}
      >
        <CModalHeader>
          <CModalTitle>Delete Coach?</CModalTitle>
        </CModalHeader>
        <CModalBody>
          Are you sure you want to Delete the Coach? This coach bios may get
          deleted along with it.
        </CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={handleDelete}>
            Yes
          </CButton>{" "}
          <CButton color="secondary" onClick={() => setDeleteModal(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
      <CCol xxl={12}>
        <CCard>
          <CCardHeader className="admin-management-ccardheader">
            <h2>
              <strong>Session Management</strong>
            </h2>
          </CCardHeader>

          <CCardBody>
            <CDataTable
              items={data.rows}
              fields={fields}
              hover
              border
              addTableClasses="table-class"
              striped
              loading={loading}
              noItemsViewSlot={
                !loading ? (
                  <div className="coachavilabilityDataBody">No Results</div>
                ) : (
                  <div className="height14rem"></div>
                )
              }
              clickableRows
              overTableSlot={
                <div>
                  <CCol className="admin-management-table" md="14">
                    <div className="education-box-myprofilelist">
                      <CInput
                        className="minHeightMarginright"
                        value={onInputChange.onsearchCHange}
                        onChange={handleSearchChange}
                        autocomplete="off"
                        id="input1-group1"
                        name="input1-group1"
                        placeholder="Search by Coach Name"
                      />
                      <Select
                        styles={optionStyles}
                        onChange={(e) => {
                          handleRankChange(e);
                        }}
                        value={rankinputval}
                        name="employee_rank_ids"
                        isSearchable={true}
                        isClearable={false}
                        placeholder="Select Team Level"
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => `${option.id}`}
                        options={ranks}
                      />
                      <Select
                        styles={optionStylesstatus}
                        onChange={(e) => {
                          handleTypeChange(e);
                        }}
                        value={typeInputVal}
                        name="status"
                        isSearchable={true}
                        isClearable={false}
                        placeholder="Select Type"
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => `${option.id}`}
                        options={[
                          {
                            name: "Free",
                            id: 1,
                          },
                          {
                            name: "Paid",
                            id: 2,
                          },
                        ]}
                      />
                    </div>
                    <div  style={{display:'flex', flexDirection:'row'}}>
                      <CButton
                        onClick={handleSearch}
                        disabled={
                          !onInputChange.onsearchCHange &&
                          !rankinputval &&
                          !typeInputVal
                        }
                        className="margin-left1_1"
                        color="info"
                      >
                        Search
                      </CButton>
                      <CButton
                        onClick={() => {
                          handleReset();
                        }}
                        className="margin-left1_1"
                        color="info"
                      >
                        Reset
                      </CButton>
                    </div>
                  </CCol>
                  <div className="session-div-button">
                    <CButton
                      color="primary"
                      variant={
                        onInputChange.onStatusChange !== 1 ? "outline" : ""
                      }
                      onClick={() => {
                        handleStatusChange(1);
                      }}
                    >
                      Pending
                    </CButton>
                    <CButton
                      color="primary"
                      onClick={() => {
                        handleStatusChange(2);
                      }}
                      variant={
                        onInputChange.onStatusChange !== 2 ? "outline" : ""
                      }
                    >
                      Accepted
                    </CButton>
                    <CButton
                      color="primary"
                      onClick={() => {
                        handleStatusChange(3);
                      }}
                      variant={
                        onInputChange.onStatusChange !== 3 ? "outline" : ""
                      }
                    >
                      Rejected
                    </CButton>
                    <CButton
                      color="primary"
                      onClick={() => {
                        handleStatusChange(4);
                      }}
                      variant={
                        onInputChange.onStatusChange !== 4 ? "outline" : ""
                      }
                    >
                      Cancelled
                    </CButton>
                    <CButton
                      color="primary"
                      onClick={() => {
                        handleStatusChange(5);
                      }}
                      variant={
                        onInputChange.onStatusChange !== 5 ? "outline" : ""
                      }
                    >
                      Completed
                    </CButton>
                  </div>
                </div>
              }
              scopedSlots={{
                call_duration: (item) => {
                  return (
                    <td>
                      {item.call_duration > 0
                        ? item.call_duration + " mins"
                        : item.call_duration + " min"}
                    </td>
                  );
                },
                status: (item) => (
                  <td>
                    {item.status === 1 ? (
                      <CBadge
                        className="wid5-height1  color-white "
                        shape="pill"
                        color={getBadge("Pending")}
                      >
                        Pending
                      </CBadge>
                    ) : item.status === 2 ? (
                      <CBadge
                        className="wid5-height1"
                        shape="pill"
                        color={getBadge("Accepted")}
                      >
                        Accepted
                      </CBadge>
                    ) : item.status === 3 ? (
                      <CBadge
                        className="wid5-height1"
                        shape="pill"
                        color={getBadge("Banned")}
                      >
                        Rejected
                      </CBadge>
                    ) : item.status === 4 ? (
                      <CBadge
                        className="wid5-height1"
                        shape="pill"
                        color="dark"
                      >
                        Cancelled
                      </CBadge>
                    ) : item.status === 5 ? (
                      <CBadge
                        className="wid5-height1"
                        shape="pill"
                        color={getBadge("Active")}
                      >
                        Completed
                      </CBadge>
                    ) : null}
                  </td>
                ),

                coach_specialization_categories: (item) => {
                  let str = "";

                  return str ? (
                    <CTooltip content={`${str}`} placement={"top-start"}>
                      <td>
                        {item?.coach_specialization_categories.length > 0 ? (
                          item.coach_specialization_categories.length > 1 ? (
                            <div className="color-0d86ff">
                              {item.coach_specialization_categories.length}
                            </div>
                          ) : (
                            item.coach_specialization_categories[0].name
                          )
                        ) : (
                          ""
                        )}
                      </td>
                    </CTooltip>
                  ) : (
                    <td></td>
                  );
                },
                type: (item, index) => {
                  return <td>{item.type === 1 ? "Free" : "Paid"}</td>;
                },

                show_details: (item, index) => {
                  return (
                    <td>
                      <div className="py-2 coach-bios">
                        {isPermited.permissionArray.includes(2) && (
                          <CTooltip content={`View `} placement={"top-start"}>
                            <CDropdown>
                              <CDropdownToggle color="secondary">
                                {" "}
                                <FontAwesomeIcon
                                  color="green"
                                  size="lg"
                                  className="cursor-pointer"
                                  icon={faEye}
                                />
                              </CDropdownToggle>
                              <CDropdownMenu>
                                <CDropdownItem
                                  onClick={() => {
                                    history.push(
                                      `sessioncontent/viewsession/${item.id}`
                                    );
                                  }}
                                >
                                  Session View
                                </CDropdownItem>
                                <CDropdownItem
                                  onClick={() => {
                                    history.push(
                                      `sessioncontent/viewtimeline/${item.id}`
                                    );
                                  }}
                                >
                                  Timeline
                                </CDropdownItem>
                              </CDropdownMenu>
                            </CDropdown>
                          </CTooltip>
                        )}
                      </div>
                    </td>
                  );
                },
              }}
            />
            {data.count ? (
              <CPagination
                activePage={page}
                pages={Math.ceil(data.count / 10)}
                onActivePageChange={pageChange}
                doubleArrows={true}
                align="start"
              />
            ) : null}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Session;
