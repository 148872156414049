
import * as yup from 'yup';

export const editAdmin = () => {
   

    return yup.object({
        name: yup.string()
            .test('alphabetsonly', 'Admin name should not contain Numbers and Special characters', function (val) {
                return /^[a-zA-Z\s]*$/.test(val);
            }).required('Name is required'),
        
        
    })

}

export const addBios = () => {

    return yup.object({
        coachname: yup.string().required('Coach is required'),
        description: yup.string().max(250, 'Description cannot exceed 250 characters').required('Description is required'),
    })

}

export const roleValidation = (id) => {

    return yup.object({
        name: yup.string()
            .max(50, 'Role Name cannot exceed 50 characters')
            .required('Role Name is required'),
    })
}



export const rankValidation = (id) => {

    return yup.object({
        name: yup.string()
            .max(50, 'Team Level cannot exceed 50 characters')
            .required('Team Level is required'),
        description: yup.string().max(250, 'Description cannot exceed 250 characters').required('Description is required'),
    })


}

export const AddLibraryValidation = () => {

    return yup.object({
        name: yup.string()
            .max(50, 'Name cannot exceed 50 characters')
            .test('alphabetsonly', 'Name should not contain Numbers and Special characters', function (val) {
                return /^[a-zA-Z\s]*$/.test(val);
            }).required('Name is required'),
        description: yup.string().max(250, 'Description cannot exceed 250 characters').required('Description is required')
    })

}

export const notificationValidation = () => {

    return yup.object({
        type: yup.string().required('Type is required'),
        message: yup.string()
            .max(250, 'message cannot exceed 250 characters')
            .required('Message is required')
    })

}

export const addEditSubadminValidation = (id) => {
    let email;
    let password;
    let confirmPassword;
    let status;

    if (!id) {
        email = yup.string().email('Invalid Email address format').required('Email address is required').trim();
        password = yup
            .string()
            .required("Password is required")
            .test(
                "regex",
                "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase",
                val => {
                    let regExp = new RegExp(
                        "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                    );
                    
                    return regExp.test(val);
                }
            );
        confirmPassword = yup.string().required('Confirm Password is required').oneOf([yup.ref('password'), null], 'Passwords must match').trim();
        status = ""

    } else {
        email = "";
        password = "";
        confirmPassword = "";
        status = yup.string().required('Status is required')
    }
   
    return yup.object({
        name: yup.string()
            .max(50, 'Name cannot exceed 50 characters')
            .test('alphabetsonly', 'Name should not contain Numbers and Special characters', function (val) {
                return /^[a-zA-Z\s]*$/.test(val);
            }).required('Name is required'),
        email,
        password,
        confirmPassword,

        phone: yup.string().required('Phone Number is required').max(15, 'Phone number cannot exceed 15 digits'),

        status
    })

}

export const addCoachValidation = (id) => {
    let passwordData;
    if (id) {

        passwordData = yup
            .string()
            .required("Password is required")
            .test(
                "regex",
                "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase",
                vals => {
                    let regExp = new RegExp(
                        "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                    );
                    
                    return regExp.test(vals);
                }
            )
    } else {
       
        passwordData = ""
    }
   

    return yup.object({
        name: yup.string()
            .max(50, 'Name cannot exceed 50 characters')
            .test('alphabetsonly', 'Name should not contain Numbers and Special characters', function (val) {
                return /^[a-zA-Z\s]*$/.test(val);
            })
            .required('Name is required'),
        email: yup.string().email('Invalid Email address format').required('Email address is required').trim(),
        password: passwordData,

        phone: yup.string().required('Phone Number is required').max(15, 'Phone number cannot exceed 15 digits'),
        description: yup.string().max(250, 'Description cannot exceed 250 characters').required('Description is required'),
        coach_specialization_category_ids: yup.array().test({
            message: 'Specialization category is required',
            test: arr => arr.length !== 0
        }),
        employee_rank_ids: yup.array().test({
            message: 'Team level is required',
            test: arr => arr.length !== 0
        })
    })


}


export const loginValidation = () => {

    return yup.object({
        email: yup.string().email('Invalid Email address format').required('Email address is required').trim(),
        password: yup.string().required('Password is required').trim(),
    })

}

export const forgetpasswordValidation = () => {

    return yup.object({
        email: yup.string().email('Invalid Email address format').required('Email address is required').trim(),
        
    })

}

export const ChangePasswordValidation = () => {

    return yup.object({
        oldpassword: yup
            .string()
            .required("Please enter old password")
        ,
        newpassword: yup
            .string()
            .required("Please enter new password")
            .test(
                "regex",
                "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase",
                Val => {
                    let regExp = new RegExp(
                        "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                    );

                    return regExp.test(Val);
                }
            )
        ,
        confirmpassword: yup.string().required('Confirm Password is required').oneOf([yup.ref('newpassword'), null], 'Passwords must match').trim(),
    })

}

export const ResetPasswordValidation = () => {

    return yup.object({
        newpassword: yup
            .string()
            .required("Please Enter your password")
            .test(
                "regex",
                "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase",
                valss => {
                    let regExp = new RegExp(
                        "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                    );

                    return regExp.test(valss);
                }
            )
        ,
        confirmpassword: yup.string().required('Confirm Password is required').oneOf([yup.ref('newpassword'), null], 'Passwords must match').trim(),
    })

}






export const addEditCoachSpecializationValidation = (id) => {

    return yup.object({
        name: yup.string()
            .max(50, 'Coach Specialization cannot exceed 50 characters')
            
            
            
            .required('Coach Specialization is required'),

        description: yup.string().max(250, 'Description cannot exceed 250 characters').required('Description is required'),
    })


}


export const editEmployeeValidations = () => {
   
    return yup.object({
        name: yup.string()
            .max(50, 'Name cannot exceed 50 characters')
            .test('alphabetsonly', 'Name should not contain Numbers and Special characters', function (val) {
                return /^[a-zA-Z\s]*$/.test(val);
            }).required('Name is required'),
        email: yup.string().email('Invalid Email address format').required('Email address is required').trim(),
        
        empname: yup.string().required('Employer Name is required'),
        deptname: yup.string().required('Department Name is required'),
        status: yup.string().required('Status is required'),
        rank: yup.string().required('Team Level is required'),
    })

}

export const addEditEmployersValidation = () => {
  
    return yup.object({
        name: yup.string()
            .max(50, 'Name cannot exceed 50 characters')
            .test('alphabetsonly', 'Name should not contain Numbers and Special characters', function (val) {
                return /^[a-zA-Z\s]*$/.test(val);
            }).required('Name is required'),
        email: yup.string().email('Invalid Email address format').required('Email address is required').trim(),
        password: yup
            .string()
            .required("Password is required")
            .test(
                "regex",
                "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase",
                vaL => {
                    let regExp = new RegExp(
                        "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                    );
                    
                    return regExp.test(vaL);
                }
            ),
        phone: yup.string().required('Phone Number is required').max(15, 'Phone number cannot exceed 15 digits'),
        industry: yup.string().required('Industry is required'),
        address: yup.string()
            .max(250, 'Address cannot exceed 250 characters')
            .required('Address is required'),
        thought: yup.string()
            .max(150, 'Thought cannot exceed 250 characters')
        
    })

}

export function timeAgo(timestamp) {
	if (!timestamp) {
		return ""
	}
	var timeStampFromServer = timestamp;
	var date = new Date(Number(timestamp))
	let hour = date.getHours();
	let minutes = date.getMinutes();
	let finalDate = "";


	var currentTime = new Date().getTime()
	let sec = parseInt((currentTime - timeStampFromServer) / 1000)
	let min = parseInt(sec / 60);
	let hours = parseInt(min / 60);
	let days = parseInt(hours / 24);
	let weeks = parseInt(days / 7)
	let months = parseInt(weeks / 4)
	let years = parseInt(months / 12)

	if (days < 1) {
		if (hour <= 12) {
			finalDate = hour + ":";
		} else if (hour > 12) {
			finalDate = (hour % 12) + ":";
		}
		if (minutes < 10) {
			finalDate = finalDate + "0" + minutes;
		}
		if (minutes >= 10) {
			finalDate = finalDate + minutes;
		}
		if (hour < 12) {
			finalDate = finalDate + " AM";
		}
		if (hour >= 12) {
			finalDate = finalDate + " PM";
		}
		return finalDate;

	}

	else if (days <= 7) {
		if (days === 1) {
			return "Yesterday"
		} else {
			return days + " days ago"
		}
	} else if (weeks <= 4.3) {
		if (weeks === 1) {
			return "a week ago"
		} else {
			return weeks + " weeks ago"
		}
	} else if (months <= 12) {
		if (months === 1) {
			return "a month ago"
		} else {
			return months + " months ago"
		}
	} else {
		if (years === 1) {
			return "one year ago"
		} else {
			return years + " years ago"
		}
	}

}
