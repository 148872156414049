import React, { Component } from "react";
import { GET } from "../utils/helper";
import { timeAgo } from "../utils/validation";
import newgoalImage from "../assets/images/notification/newgoal.png";
import audiocallImage from "../assets/images/notification/audiocall.png";
import audiomissImage from "../assets/images/notification/audiomiss.png";
import goalacceptedImage from "../assets/images/notification/goalaccepted.png";
import goalcompleteimage from "../assets/images/notification/goalcomplete.png";
import goalrejectedImage from "../assets/images/notification/goalrejected.png";
import highfiveImage from "../assets/images/notification/highfive.png";
import likeImage from "../assets/images/notification/like.png";
import message from "../assets/images/notification/message.png";
import rating from "../assets/images/notification/rating.png";
import videomiss from "../assets/images/notification/videomiss.png";
import videocall from "../assets/images/notification/videocall.png";
import freetrial from "../assets/images/notification/freetrial.png";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      notifications: [],
    };
  }
  componentDidMount() {

    this.loadData(true);
  }
  loadData = (showLoader) => {
    showLoader && this.setState({ isLoading: true });
     GET(`getNotifications`, "coach")
      .then((res) => {
        if (res.status === 200) {
          this.setState({ isLoading: false });
          
          if (
            res &&
            res.body &&
            res.body.data &&
            res.body.data.rows &&
            res.body.data.rows.length > 0
          ) {
            
            this.setState({ notifications: res.body.data.rows });
          }
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log("getNotifications api error---      ---->", error);
      });
  };

  renderItem = (item, index) => {
    const { data, createdAt, status } = item;
    const title = data.title ? data.title : "";
    const body = data.message ? data.message : "";
    const timestamp = new Date(createdAt).getTime();
    let icon;

    switch (item.type) {
      case 1:
        icon = newgoalImage;
        break;
      case 2:
        icon = goalcompleteimage;
        break;
      case 3:
        icon = rating;
        break;
      case 5:
        icon = audiocallImage;
        break;
      case 6:
        icon = videocall;
        break;
      case 7:
        icon = goalacceptedImage;
        break;
      case 8:
        icon = goalrejectedImage;
        break;
      case 10:
        icon = audiomissImage;
        break;
      case 11:
        icon = videomiss;
        break;
      
      case 14:
        icon = highfiveImage;
        break;
      case 13:
        icon = likeImage;
        break;
      case 15:
        icon = message;
        break;
      case 16:
        icon = freetrial;
        break;
      default:
        break;
    }
    return (
      <div
        onClick={() => {
        
          if (item.type === 2) {
            this.props.setModalOpen(false);

            this.props.history.push(
              `/goalaproval/${Number(item.type_id)}/${Number(
                item.team_goal_assign_id
              )}`
            );
          }
          if (item.type === 13 || item.type === 14 || item.type === 15) {
            this.props.setModalOpen(false);
            this.props.history.push(`/achievement/${Number(item.data.id)}`);
          }

          if (item.type === 7 || item.type === 8) {
            this.props.setModalOpen(false);
            this.props.history.push(
              `/goalssubmissionhistory/${Number(item.data.goal_id)}`
            );
          }

          if (item.type === 1) {
            this.props.setModalOpen(false);
            this.props.history.push(`/employeegoal`);
          }
        }}
        className="Notification-chat"
        style={{
          
          border: status === 1 ? "1px solid #ED8423 " : '1px solid #ed8423',
          boxSizing:  'border-box',
           borderRadius:'8px',
           padding: '18px 24px 18px 12px',
           marginBottom:  '20px'
        }}
      >
        <div  className="display-flex"
          
          
          >
          {icon && (
            <img  
            className="notification-img-css"
              src={icon}
              alt=""
             
            />
          )}
          <h5>{title}</h5>
        </div>
        <p  
        className="padding-botttom-5px"
       
        >
          {body}
          <span className="float-right">{timeAgo(timestamp)}</span>
        </p>
      </div>
    );
  };

  render() {
    const { notifications } = this.state;
   
    return (
      <>
        <div
          
          style={{
            width: "638px",
            backGround: "#fff",
            borderRadius: "14px",
            margin: "0 auto",
            padding: "0px 70px",
          }}
        >
          <div
            className="Notification-head  Notification-head-inline-css"
            style={{
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "36px",
              textAlign: "center",
              swebkitTextDecorationLine: "underline",
              textDecorationLine: "underline",
              color: "#6a6a6a",
            
              margin: "0",
            }}
          >
       
          </div>

          <div
            className="Notification-body   notification-body-inline-css"
            
            
            
            
             
             
            
          >
            {this.state.isLoading && (
              <div
               
                className="spinner-border text-primary  spinner-inline-css"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            )}
         
            
            {notifications &&
              notifications.length > 0 &&
              notifications.map((item, id) => this.renderItem(item, id))}
            {!this.state.isLoading && notifications.length === 0 && (
              <div className="Notification-body  notifications-bodys-inline-css"
              
               >
                No Notifications Found
              </div>
            )}
          
            </div>
            
        </div>
      </>
    );
  }
}

export default Notification;
