import React, { useEffect, useState } from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CContainer,
    CForm,
    CFormGroup,
    CInput,
    CLabel,
    CInputFile,
    CModal,
    CModalHeader,
    CModalFooter,
    CModalBody,
    CModalTitle,

} from '@coreui/react';

import { useFormik } from "formik"
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux"
import "react-datepicker/dist/react-datepicker.css";
import { postRequest, putRequest } from 'src/utils/AxiosUtils';
import { editAdmin } from 'src/reusable/validation';
import { PROFILE_DATA } from 'src/redux/type.action';


export default function EditProfile() {
    const location = useLocation();
    const history = useHistory();
    const [disable, setDisable] = useState(false);
    
    
    const [emailModal, setEmailModal] = useState(false);
    const [phoneModal, setPhoneModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState("")
    const [filename, setFileName] = useState("Choose file...");
    const dispatch = useDispatch();


    const initialValues = {
        name: location.state ? location.state.data.name : "",
        email: location.state ? location.state.data.email : "",
        adminImage: "",
        newemail: location.state ? location.state.data.email : "",
        phone: location.state && location.state.data.phone_number ? location.state.data.phone_number : "",
        facebook: location.state && location.state.data.social_media_handles?.facebook ? location.state.data.social_media_handles.facebook : "",
        twitter: location.state && location.state.data.social_media_handles?.twitter ? location.state.data.social_media_handles.twitter : "",
        instagram: location.state && location.state.data.social_media_handles?.instagram ? location.state.data.social_media_handles.instagram : "",
        linkedin: location.state && location.state.data.social_media_handles?.linkedin ? location.state.data.social_media_handles.linkedin : "",
        google: location.state && location.state.data?.social_media_handles?.google ? location.state.data.social_media_handles.google : "",


    }




    const onSubmit = async (values) => {

     

        let social_media_handles = {
            facebook: values.facebook,
            twitter: values.twitter,
            linkedin: values.linkedin,
            instagram: values.instagram,
            google: values.google
        }

        
        
        
        

        let data = {
            name: values.name,
            email: values.email,
            profile_pic_url: values.adminImage,
            social_media_handles,
            
        }

        !values.adminImage && delete data.profile_pic_url;

        try {
            setLoading(true);
            const response = await putRequest('updateProfile', data);
            
            if (response.status === 200) {
                dispatch({
                    type: PROFILE_DATA,
                    payload: response?.data?.body?.data[1][0]
                })
                history.push({
                    pathname: `/profile`,
                    state: { data: response.data.message, type: 'edit' }
                })
            }
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema: editAdmin

    })

    

    

    useEffect(() => {
        let adminImageNameWithPng = location?.state?.data?.profile_pic_url?.substring(location?.state?.data?.profile_pic_url.lastIndexOf('%') + 1);
        let adminImageName = adminImageNameWithPng ? adminImageNameWithPng?.substring(2, adminImageNameWithPng?.length) : ""
        setFileName(adminImageName)
    }, [location?.state?.data?.profile_pic_url])

    const handleUpadteEmail = async (type) => {
        try {
            let params;
            if (type === "email") {
                params = formik.values.newemail;
            }

            if (type === "phone") {
                params = formik.values.newPhone
            }

            
            



            
            
            
            
            

        } catch (error) {
            console.log(error);


        }

    }

    const showFile = async (event) => {
        event.preventDefault()
        
        if (event.target.files.length < 1) {
            return;
        }
        
        
        if (event.target.files[0].type !== "image/png" && event.target.files[0].type !== "image/jpeg") {
            
            setShowError('Only Image files are allowed');
            return;
        }
        setShowError("");
        setFileName(event.target.files[0].name);

        

        try {
            const bodyFormData = new FormData();

            bodyFormData.append('file', event.target.files[0]);
            
            setDisable(true);
            const result = await postRequest('uploadFile', bodyFormData);
            setDisable(false);
            
            
            formik.setFieldValue('adminImage', result.data.body.data);
        } catch (error) {
            console.log(error);
            setShowError(error);
        }
    }



    return (
        <CContainer className="editprofile">
            <CCol lg="8" md="12">
                <CModal
                    show={emailModal}
                    centered={true}
                    backdrop={false}
                    color="warning"
                    onClose={setEmailModal}
                  className="fontFamilyPoppins"
                >
                    <CModalHeader closeButton>
                        <CModalTitle>Enter New Email Address</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel htmlFor="hf-categorytype"><h6>New Email:</h6></CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput
                                    type="text"
                                    id="newemail"
                                    name="newemail"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.newemail}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.newemail && formik.errors.newemail ? <div className="email-validate">{formik.errors.newemail}</div> : null}

                            </CCol>
                        </CFormGroup>
                    </CModalBody>
                    <CModalFooter>
                        <CButton
                            color="success"
                            onClick={() => handleUpadteEmail('email')}
                        >Yes</CButton>
                        <CButton
                            color="secondary"
                            onClick={() => setEmailModal(false)}
                        >Cancel</CButton>
                    </CModalFooter>
                </CModal>
                <CModal
                    show={phoneModal}
                    centered={true}
                    backdrop={false}
                    color="warning"
                    onClose={setPhoneModal}
                       className="fontFamilyPoppins"
                >
                    <CModalHeader closeButton>
                        <CModalTitle>Enter New Phone Number</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel htmlFor="hf-categorytype"><h6>New Phone:</h6></CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput
                                    type="text"
                                    id="newPhone"
                                    name="newPhone"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.newPhone}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.newPhone && formik.errors.newPhone ? <div className="email-validate">{formik.errors.newPhone}</div> : null}

                            </CCol>
                        </CFormGroup>
                    </CModalBody>
                    <CModalFooter>
                        <CButton
                            color="success"
                            onClick={() => handleUpadteEmail('phone')}
                        >Yes</CButton>
                        <CButton
                            color="secondary"
                            onClick={() => setPhoneModal(false)}
                        >Cancel</CButton>
                    </CModalFooter>
                </CModal>
                <CCard >
                    <CCardHeader className="lato">
                        <h3><strong>Edit Admin</strong></h3>
                    </CCardHeader>
                    <CCardBody    className="fontFamilyPoppins" >
                        <CForm onSubmit={formik.handleSubmit}
                            className="form-horizontal">
                       
                            <CFormGroup className="admin-management-ccardheader">

                                <CLabel htmlFor="custom-file-input">
                                    Image Upload
                                </CLabel>

                                <CCol xs="12" md="9">
                                    <CInputFile
                                        custom
                                        id="custom-file-input"
                                        onChange={e => showFile(e)}
                                    />
                                    <CLabel className="zindex"
                                        htmlFor="custom-file-input" variant="custom-file">
                                        {filename}
                                    </CLabel>
                                    {formik.touched.url && formik.errors.url ? <div className="email-validate">{formik.errors.url}</div> : null}
                                    {showError? <div className="email-validate">{showError}</div> : null}
                                </CCol>

                            </CFormGroup>
                            <CFormGroup row>
                                <CCol md="3">
                                    <CLabel htmlFor="hf-category">Admin Name</CLabel>
                                </CCol>
                                <CCol >
                                    <CInput

                                        type="text"
                                        id="name"
                                        name="name"
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.touched.name && formik.errors.name ? <div className="email-validate">{formik.errors.name}</div> : null}

                                </CCol>
                            </CFormGroup>
                            <CFormGroup row>
                                <CCol md="3">
                                    <CLabel htmlFor="hf-categorytype">Email Address</CLabel>
                                </CCol>
                                <CCol xs="12" md="9">
                                    <CInput
                                        type="text"
                                        id="email"
                                        disabled="true"
                                        name="email"
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                    />
                                   
                                </CCol>
                            </CFormGroup>
                   
                            <CFormGroup row>
                                <CCol md="3">
                                    <CLabel htmlFor="hf-categorytype">facebook</CLabel>
                                </CCol>
                                <CCol xs="12" md="9">
                                    <CInput

                                        id="facebook"
                                        
                                        name="facebook"
                                        onBlur={formik.handleBlur}
                                        value={formik.values.facebook}
                                        onChange={formik.handleChange}
                                    />
                                   
                                </CCol>
                            </CFormGroup>
                            <CFormGroup row>
                                <CCol md="3">
                                    <CLabel htmlFor="hf-categorytype">twitter</CLabel>
                                </CCol>
                                <CCol xs="12" md="9">
                                    <CInput

                                        id="twitter"
                                        
                                        name="twitter"
                                        onBlur={formik.handleBlur}
                                        value={formik.values.twitter}
                                        onChange={formik.handleChange}
                                    />
                                   
                                </CCol>
                            </CFormGroup>
                            <CFormGroup row>
                                <CCol md="3">
                                    <CLabel htmlFor="hf-categorytype">instagram</CLabel>
                                </CCol>
                                <CCol xs="12" md="9">
                                    <CInput

                                        id="instagram"
                                        
                                        name="instagram"
                                        onBlur={formik.handleBlur}
                                        value={formik.values.instagram}
                                        onChange={formik.handleChange}
                                    />
                                 
                                </CCol>
                            </CFormGroup>
                            <CFormGroup row>
                                <CCol md="3">
                                    <CLabel htmlFor="hf-categorytype">linkedin</CLabel>
                                </CCol>
                                <CCol xs="12" md="9">
                                    <CInput

                                        id="linkedin"
                                        
                                        name="linkedin"
                                        onBlur={formik.handleBlur}
                                        value={formik.values.linkedin}
                                        onChange={formik.handleChange}
                                    />
                                   
                                </CCol>
                            </CFormGroup>
                            <CFormGroup row>
                                <CCol md="3">
                                    <CLabel htmlFor="hf-categorytype">google</CLabel>
                                </CCol>
                                <CCol xs="12" md="9">
                                    <CInput

                                        id="google"
                                        
                                        name="google"
                                        onBlur={formik.handleBlur}
                                        value={formik.values.google}
                                        onChange={formik.handleChange}
                                    />
                                 
                                </CCol>
                            </CFormGroup>


                            <CCardFooter className="addrole-inline-css-checkbox2">
                                {loading ?
                                    <div className="spinner-border text-success" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : <CButton
                                        type="submit"
                                        disabled={disable}
                                        color="success"
                                        className="width-5rem"
                                    >
                                        <strong>Update</strong>
                                    </CButton>}

                                <CButton
                                    onClick={() => history.push('/profile')}
                                    className="width-5rem"
                                    type="reset"

                                    color="danger">
                                    <strong>Cancel</strong>
                                </CButton>
                            </CCardFooter>
                        </CForm>
                    </CCardBody>

                </CCard>
            </CCol>
        </CContainer>
    )
}
