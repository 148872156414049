import React, { useState } from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow,
    CModal,
    CInputGroupAppend,

    CModalHeader,

} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useFormik } from "formik"
import { ChangePasswordValidation } from "../../reusable/validation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { putRequest } from 'src/utils/AxiosUtils';

const initialValues = {
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
    error: ""
}

export default function ChangePassword() {


    const [showpass, setShowPass] = useState('password');
    const [showpass2, setShowPass2] = useState('password');
    const [showpass3, setShowPass3] = useState('password');

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);


    const onSubmit = async (values, actions) => {

        setLoading(true);
        try {

            const result = await putRequest('changePassword', {
                old_password: values.oldpassword,
                new_password: values.newpassword
            })
            if (result) {
                setModal(!modal);
                setLoading(false);
                
            }

        } catch (error) {
            setLoading(false);
            console.log(error);
            actions.setFieldError('error', error.response.data.message);

        }

    }


    const formik = useFormik({
        initialValues,
        validationSchema: ChangePasswordValidation,
        onSubmit

    })



    return (
        <div className="c-app min-height-100 c-default-layout flex-row align-items-center">
            <CContainer>
                <CModal
                    show={modal}
                    backdrop={false}
                    color="warning"
                    onClose={setModal}
                >
                    <CModalHeader closeButton>
                        Password changed Successfully
                    </CModalHeader>

                </CModal>
                <CRow className="justify-content-center">
                    <CCol md="8">
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <h1>Change Password</h1>
                                        <p className="text-muted">Enter old password</p>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-lock-locked" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput
                                                type={showpass}
                                                placeholder="Old password"
                                                autoComplete="current-password"
                                                name="oldpassword"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.oldpassword}
                                                onChange={formik.handleChange}
                                            />
                                            <CInputGroupAppend >
                                                <CInputGroupText onClick={() => {
                                                    if (showpass === "password") {
                                                        setShowPass('text')
                                                    } else {
                                                        setShowPass('password')
                                                    }
                                                }}>
                                                    <FontAwesomeIcon
                                                        color="blue"
                                                        size="sm"
                                                        icon={showpass !== 'password' ? faEye : faEyeSlash} />                                                   
                                                </CInputGroupText>
                                            </CInputGroupAppend>
                                        </CInputGroup>
                                        {formik.touched.oldpassword && formik.errors.oldpassword ? <div className="email-validate">{formik.errors.oldpassword}</div> : null}
                                        <p className="text-muted">Enter new password</p>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-lock-locked" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput
                                                type={showpass2}
                                                placeholder="New password"
                                                autoComplete="current-password"
                                                name="newpassword"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.newpassword}
                                                onChange={formik.handleChange}
                                            />
                                            <CInputGroupAppend >
                                                <CInputGroupText onClick={() => {
                                                    if (showpass2 === "password") {
                                                        setShowPass2('text')
                                                    } else {
                                                        setShowPass2('password')
                                                    }
                                                }}>
                                                    <FontAwesomeIcon
                                                        color="blue"
                                                        size="sm"
                                                        icon={showpass2 !== 'password' ? faEye : faEyeSlash} />                                                  
                                                </CInputGroupText>
                                            </CInputGroupAppend>
                                        </CInputGroup>
                                        {formik.touched.newpassword && formik.errors.newpassword ? <div className="email-validate">{formik.errors.newpassword}</div> : null}
                                        <p className="text-muted">Confirm new password</p>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-lock-locked" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput
                                                type={showpass3}
                                                placeholder="Confirm new password"
                                                autoComplete="current-password"
                                                name="confirmpassword"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.confirmpassword}
                                                onChange={formik.handleChange}
                                            />
                                            <CInputGroupAppend >
                                                <CInputGroupText onClick={() => {
                                                    if (showpass3 === "password") {
                                                        setShowPass3('text')
                                                    } else {
                                                        setShowPass3('password')
                                                    }
                                                }}>
                                                    <FontAwesomeIcon
                                                        color="blue"
                                                        size="sm"
                                                        icon={showpass3 !== 'password' ? faEye : faEyeSlash} />                                                  
                                                </CInputGroupText>
                                            </CInputGroupAppend>
                                        </CInputGroup>
                                        {formik.touched.confirmpassword && formik.errors.confirmpassword ? <div className="email-validate">{formik.errors.confirmpassword}</div> : null}
                                        {formik.errors.error ? <div className="email-validate">{formik.errors.error}</div> : null}
                                        <CRow>
                                            <CCol xs="6">
                                                {!loading ? <CButton
                                                    color="primary"
                                                    className="px-4"
                                                    type="submit"
                                                >
                                                    Reset
                                                </CButton> :
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}
