import React, { useEffect, useState } from "react";
import {
  CButton,
  CInput,
  CCard,
  CCardBody,
  CRow,
  CCol,
  CFormGroup,
  CLabel,
  CInputRadio,
  CInputGroup,
  CDataTable,
  CCardHeader,
} from "@coreui/react";

import WidgetsDropdown from "../widgets/WidgetsDropdown";
import { getRequest } from "src/utils/AxiosUtils";

import usePermissions from "src/hooks/usePermissions";
import { useHistory } from "react-router-dom";
const getBadge = (status) => {
  switch (status) {
    case "Active":
      return "success";
    case "Inactive":
      return "secondary";
    case "Pending":
      return "warning";
    case "Banned":
      return "danger";
    default:
      return "primary";
  }
};

const fields = [
  { key: "coachName", label: "Coach Name", _style: { fontFamily: "Poppins" } },
  { key: "sessionCount", label: "Sessions", _style: { fontFamily: "Poppins" } },
  {
    key: "conversionRate",
    label: "Conversion Rate",
    _style: { fontFamily: "Poppins" },
  },
  {
    key: "totalDuration",
    label: "Coached Hours",
    _style: { fontFamily: "Poppins" },
  },
];

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result.toISOString();
}

const tomorrow = () => {
  let fromDate = new Date();
  fromDate.setDate(fromDate.getDate());
  return fromDate.toISOString();
};

const Dashboard = () => {
  const history = useHistory();
  const isPermited = usePermissions("dashboard");

  if (!isPermited.status) {
    history.push(isPermited.pushto);
  }
  const [loading, setLoading] = useState(false);
  const [loadingTopCoaches, setLoadingTopCoaches] = useState(false);

  const [time, setTime] = useState(1);

  const [fromDateSelect, setFromDateSelect] = useState(null);
  const [toDateSelect, setToDateSelect] = useState(null);
  const [data, setData] = useState(null);
  const [showError, setShowError] = useState(null);
  const [searchVal, setSearchVal] = useState("");

  const handleTimeChange = async (Time) => {
    setShowError(null);
    setTime(Time);
    setFromDateSelect(null);
    setToDateSelect(null);
    let fromDate = new Date();
    let currentDate = new Date();
    fromDate.setDate(fromDate.getDate() - Time + 1);

    try {
      setLoading(true);
      const response = await getRequest(
        `dashboard?from=${fromDate.toISOString()}&to=${addDays(currentDate, 1)}`
      );

      setData(response.data.body.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDateSubmit = async () => {
    if (fromDateSelect === null || toDateSelect === null) {
      setShowError("Please enter both Dates");
      return;
    }

    setTime(null);

    try {
      setLoading(true);
      const response = await getRequest(
        `dashboard?from=${fromDateSelect}&to=${addDays(toDateSelect, 1)}`
      );

      setData(response.data.body.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await getRequest(
          `dashboard?from=${new Date().toISOString()}&to=${new Date().toISOString()}`
        );

        setData(response.data.body.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getData();
  }, []);

  const handleSearch = async () => {
    if (time) {
      let fromDate = new Date();
      let currentDate = new Date();
      fromDate.setDate(fromDate.getDate() - time + 1);
      setLoadingTopCoaches(true);
      try {
        const response = await getRequest(
          `dashboard?from=${fromDate.toISOString()}&to=${addDays(
            currentDate,
            1
          )}&searchKey=${searchVal}`
        );
        setData(response.data.body.data);
        setLoadingTopCoaches(false);
      } catch (error) {
        setLoadingTopCoaches(false);
      }
    } else if (fromDateSelect && toDateSelect) {
      try {
        setLoadingTopCoaches(true);
        const response = await getRequest(
          `dashboard?from=${fromDateSelect}&to=${addDays(
            toDateSelect,
            1
          )}&searchKey=${searchVal}`
        );

        setData(response.data.body.data);
        setLoadingTopCoaches(false);
      } catch (error) {
        console.log(error);
        setLoadingTopCoaches(false);
      }
    } else {
      try {
        setLoadingTopCoaches(true);
        const response = await getRequest(
          `dashboard?from=${new Date().toISOString()}&to=${new Date().toISOString()}&searchKey=${searchVal}`
        );

        setData(response.data.body.data);
        setLoadingTopCoaches(false);
      } catch (error) {
        console.log(error);
        setLoadingTopCoaches(false);
      }
    }
  };

  useEffect(() => {
    if (!searchVal) {
      handleSearch();
    }
  }, [searchVal]);

  const handleReset = () => {
    setSearchVal("");
  };

  return (
    <>
      <CCard>
        <CCardBody>
          <CFormGroup row>
            <CCol md="2">
              <CLabel>
                {" "}
                <strong>
                  <h4>Time Period :</h4>
                </strong>
              </CLabel>
            </CCol>
            <CCol style={{ display: "flex" }} md="10">
              <CFormGroup variant="custom-radio" inline>
                <CInputRadio
                  checked={time === 1}
                  onChange={() => handleTimeChange(1)}
                  custom
                  id="inline-radio1"
                  name="inline-radios"
                  value="option1"
                />
                <CLabel variant="custom-checkbox" htmlFor="inline-radio1">
                  Today
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="custom-radio" inline>
                <CInputRadio
                  checked={time === 7}
                  onChange={() => handleTimeChange(7)}
                  custom
                  id="inline-radio2"
                  name="inline-radios"
                  value="option2"
                />
                <CLabel variant="custom-checkbox" htmlFor="inline-radio2">
                  Weekly
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="custom-radio" inline>
                <CInputRadio
                  checked={time === 30}
                  onChange={() => handleTimeChange(30)}
                  custom
                  id="inline-radio3"
                  name="inline-radios"
                  value="option3"
                />
                <CLabel variant="custom-checkbox" htmlFor="inline-radio3">
                  Monthly
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="custom-radio" inline>
                <CInputRadio
                  checked={time === 365}
                  onChange={() => handleTimeChange(365)}
                  custom
                  id="inline-radio4"
                  name="inline-radios"
                  value="option4"
                />
                <CLabel variant="custom-checkbox" htmlFor="inline-radio4">
                  Yearly
                </CLabel>
              </CFormGroup>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="2">
              <CLabel htmlFor="date-input">
                {" "}
                <strong>
                  <h4>Date</h4>
                </strong>
              </CLabel>
            </CCol>
            <CCol md="4" xs="12">
              <CLabel htmlFor="date-input">
                {" "}
                <strong>From</strong>
              </CLabel>
              <CInput
                max={new Date().toISOString().slice(0, 10)}
                value={
                  fromDateSelect ? fromDateSelect.slice(0, 10) : "dd-mm-yyyy"
                }
                onChange={(e) => {
                  setShowError(null);
                  setFromDateSelect(new Date(e.target.value).toISOString());
                }}
                type="date"
                id="date-input"
                name="date-input"
                placeholder="date"
              />
              <CLabel htmlFor="date-input">
                {" "}
                <strong>To</strong>
              </CLabel>
              <CInput
                max={new Date().toISOString().slice(0, 10)}
                min={fromDateSelect ? fromDateSelect.slice(0, 10) : null}
                value={toDateSelect ? toDateSelect.slice(0, 10) : "dd-mm-yyyy"}
                onChange={(e) => {
                  setShowError(null);
                  setToDateSelect(new Date(e.target.value).toISOString());
                }}
                type="date"
                id="date-input"
                name="date-input"
                placeholder="date"
              />
              <div className="email-validate">{showError}</div>

              <CButton
                style={{ marginTop: "1rem" }}
                size="md"
                color="primary"
                onClick={handleDateSubmit}
              >
                Filter by date
              </CButton>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="2"></CCol>
          </CFormGroup>
        </CCardBody>
      </CCard>
      <WidgetsDropdown loading={loading} data={data} />
      <CRow>        
        <CCol xxl={12}>
          <CCard>
            <CCardHeader
            className='admin-management-ccardheader'
            
            >
              <h2>
                <strong>Top Coaches</strong>
              </h2>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={data?.topCoaches || []}
                fields={fields}
                hover
                border
                addTableClasses="table-class"
                striped
                loading={loadingTopCoaches}
                noItemsViewSlot={
                  !loading ? (
                    ""
                  ) : (
                    <div
                      className="height14rem"
                    
                    ></div>
                  )
                }
                clickableRows
                overTableSlot={
                  <CCol
                    className="flex-1rem"
                  
                  >
                    <CInputGroup>
                      <CInput
                      
                        style={{ maxWidth: "15rem", borderRadius: "5px" }}
                        value={searchVal}
                        autocomplete="off"
                        onChange={(e) => {
                          setSearchVal(e.target.value);
                        }}
                        id="input1-group1"
                        name="input1-group1"
                        placeholder="Search by Coach Name"
                      />
                      <CButton
                      
                        onClick={handleSearch}
                        disabled={!searchVal}
                        style={{ marginLeft: "1rem" }}
                        color="info"
                      >
                        Search
                      </CButton>
                      <CButton
                        onClick={() => {
                          handleReset();
                        }}
                    
                        style={{ marginLeft: "1rem" }}
                        color="info"
                      >
                        Reset
                      </CButton>
                    </CInputGroup>
                  </CCol>
                }
                scopedSlots={{

                  conversionRate: (item)=><td>{item?.conversionRate!==null? item.conversionRate.toFixed(2):"0.00"} </td>,
                  coachName: (item) => <td>{item?.coach?.name}</td>,
        
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Dashboard;
