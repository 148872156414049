import React, { useEffect, useState } from "react";
import {
  CFormGroup,
  CLabel,
  CInput,
  CInputFile,
  CCardBody,
  CForm,
  CRow,
  CCol,
  CCard,
  CButton,
  CCardFooter,
  CCardHeader,
} from "@coreui/react";
import { useFormik } from "formik";
import { addCoachValidation } from "../../reusable/validation";
import PhoneInput from "react-phone-input-2";
import { useHistory, useParams, useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import {
  getRequest,
  postRequest,
  putRequest,
  uploadFile,
} from "src/utils/AxiosUtils";

export default function AddCoach() {
  const location = useLocation();
  const params = useParams();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [emp, setEmp] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [showError, setShowError] = useState("");
  const [disable, setDisable] = useState(false);
  const [filename, setFileName] = useState("Choose file...");
  const [coachCategories, setCoachCategories] = useState([]);
  const [ranks, setRankList] = useState([]);

  const optionStyles = {
    input: (styles) => ({ ...styles, width: "8rem", zIndex: "100" }),
  };

  const initialValues = {
    name: emp ? emp.name : "",
    email: emp ? emp.email : "",
    phone: emp ? emp.country_code.concat(emp.phone_number) : "",
    description: emp ? emp.description : "",
    url: emp ? emp.image : "",
    coach_specialization_category_ids: emp
      ? emp.coach_specialization_categories
      : [],
    employee_rank_ids: emp ? emp.employee_ranks : [],
  };

  const onSubmit = async (values, actions) => {
    const coachspecialization = [];
    const rankspecialization = [];

    values.coach_specialization_category_ids.forEach((item) => {
      coachspecialization.push(item.id);
    });

    values.employee_rank_ids.forEach((item) => {
      rankspecialization.push(item.id);
    });

    const data = {
      coach_id: params?.id,
      name: values.name,
      email: values.email,
      country_code: countryCode ? countryCode : emp.country_code,
      phone_number: values.phone.substr(
        countryCode ? countryCode.length : emp.country_code.length,
        values.phone.length
      ),
      description: values.description,
      image: values.url,
      fileName: filename,
      coach_specialization_category_ids: coachspecialization,
      employee_rank_ids: rankspecialization,
    };

    if (!params.id) {
      delete data.coach_id;
    }

    if (!values.url) {
      delete data.image;
    }

    try {
      setLoading(true);
      let func;
      let text;
      if (params.id) {
        func = putRequest;
        text = "editCoach";
      } else {
        func = postRequest;
        text = "addCoach";
      }
      const response = await func(text, data);

      if (response) {
        setLoading(false);
        const msg = params.id
          ? "Coach updated successfully"
          : "Coach added successfully";

        let pathname;
        if (location.state && location.state.prevPath) {
          pathname = location.state.prevPath;
        } else {
          pathname = `/coachadministration`;
        }
        history.push({
          search: location.state ? location.state.prevSearch : "",
          pathname,
          state: { data: msg, type: "edit" },
        });
      }
    } catch (error) {
      actions.setFieldError("description", error.response.data.message);
      setLoading(false);

      console.log(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema: addCoachValidation,
  });

  const showFile = async (event) => {
    event.preventDefault();

    if (event.target.files.length < 1) {
      return;
    }

    if (
      event.target.files[0].type !== "image/png" &&
      event.target.files[0].type !== "image/jpeg"
    ) {
      setShowError("Only Image files are allowed");
      return;
    }
    setShowError("");
    setFileName(event.target.files[0].name);

    try {
      const bodyFormData = new FormData();

      bodyFormData.append("file", event.target.files[0]);
      setDisable(true);
      const result = await uploadFile(bodyFormData);
      setDisable(false);

      formik.setFieldValue("url", result.body.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getCoachSpecializationList = async () => {
      try {
        const data = await getRequest(`listCoachSpecializationCategories`);

        if (data.data.status === 200) {
          setCoachCategories(data.data.body.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getEmployeeRankList = async () => {
      try {
        const data = await getRequest(`listEmployeeRanks`);

        if (data.status === 200) {
          setRankList(data.data.body.data.rows);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {}
    };

    const GetCoachDetails = async () => {
      const data = await getRequest(`getCoachDetails?coach_id=${params.id}`);
      setEmp(data.data.body.data);
    };

    if (params.id) {
      GetCoachDetails();
    }

    getCoachSpecializationList();
    getEmployeeRankList();
  }, [params?.id]);

  useEffect(() => {
    if (emp && emp.fileName) {
      setFileName(emp.fileName);
    }
  }, [emp]);

  return (
    <div>
      <CRow>
        <CCol xl="12" lg="12">
          <CForm onSubmit={formik.handleSubmit} autoComplete="off">
            <CCard>
              <CCardHeader 
              className='add-role-ccard-header-inline'
              
              >
                <h3>
                  {params.id ? (
                    <strong>Edit Coach</strong>
                  ) : (
                    <strong>Add Coach</strong>
                  )}
                </h3>
                <CButton
                className='width-8rem'
                 
                  color="primary"
                  onClick={() => {
                    history.push({
                      search: location.state ? location.state.prevSearch : "",
                      pathname: location.state.prevPath,
                      state: { data: null, type: null },
                    });
                  }}
                >
                  <strong>Back</strong>
                </CButton>
              </CCardHeader>
              <CCardBody>
                <CFormGroup>
                  <CLabel htmlFor="name">Coach Name</CLabel>
                  <CInput
                    autoComplete="new-password"
                    type="name"
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    placeholder="Enter Name.."
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="email-validate">{formik.errors.name}</div>
                  ) : null}
                </CFormGroup>
                <CFormGroup>
                  <CLabel htmlFor="custom-file-input">Image Upload</CLabel>

                  <CCol xs="6" md="12">
                    <CInputFile
                      custom
                      id="custom-file-input"
                      onChange={(e) => showFile(e)}
                    />
                    <CLabel
                      style={{ zIndex: "0" }}
                      htmlFor="custom-file-input"
                      variant="custom-file"
                    >
                      {filename}
                    </CLabel>
                    {formik.touched.url && formik.errors.url ? (
                      <div className="email-validate">{formik.errors.url}</div>
                    ) : null}
                     {showError? (
                      <div className="email-validate">{showError}</div>
                    ) : null}
                  </CCol>
                </CFormGroup>
                <CFormGroup>
                  <CLabel htmlFor="phone">Phone Number</CLabel>
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                    }}
                    type="phone"
                    id="phone"
                    name="phone"
                    country={"us"}
                    onBlur={(e) =>
                      formik.setTouched({ ...formik.touched, phone: true })
                    }
                    onChange={(val, data) => {
                      setCountryCode(data.dialCode);
                      formik.setFieldValue("phone", val);
                    }}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="email-validate">{formik.errors.phone}</div>
                  ) : null}
                </CFormGroup>
                <CFormGroup>
                  <CLabel htmlFor="email">Email Address</CLabel>
                  <CInput
                    autoComplete="off"
                    type="email"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    placeholder="Enter Email.."
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="email-validate">{formik.errors.email}</div>
                  ) : null}
                </CFormGroup>
                <CFormGroup>
                  <CLabel htmlFor="phone">Specialization category</CLabel>
                  <Select
                    styles={optionStyles}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "coach_specialization_category_ids",
                        e
                      );
                    }}
                    value={formik.values.coach_specialization_category_ids}
                    name="coach_specialization_category_ids"
                    isSearchable={true}
                    isClearable={true}
                    autocomplete="off"
                    isMulti="true"
                    placeholder="Select Specialization category"
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option.id}`}
                    options={coachCategories && coachCategories.rows}
                  />
                  {formik.touched.coach_specialization_category_ids &&
                  formik.errors.coach_specialization_category_ids ? (
                    <div className="email-validate">
                      {formik.errors.coach_specialization_category_ids}
                    </div>
                  ) : null}
                </CFormGroup>
                <CFormGroup>
                  <CLabel htmlFor="phone">Coach Team Level</CLabel>
                  <Select
                    styles={optionStyles}
                    onChange={(e) => {
                      formik.setFieldValue("employee_rank_ids", e);
                    }}
                    value={formik.values.employee_rank_ids}
                    name="employee_rank_ids"
                    isSearchable={true}
                    isClearable={true}
                    autocomplete="off"
                    isMulti="true"
                    placeholder="Select Coach Team Level"
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option.id}`}
                    options={ranks }
                  />
                  {formik.touched.employee_rank_ids &&
                  formik.errors.employee_rank_ids ? (
                    <div className="email-validate">
                      {formik.errors.employee_rank_ids}
                    </div>
                  ) : null}
                </CFormGroup>
                <CFormGroup>
                  <CLabel htmlFor="description">Description</CLabel>
                  <CInput
                    type="text"
                    id="description"
                    name="description"
                    autocomplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    placeholder="Enter Area of expertise.."
                  />

                  {formik.touched.description &&
                  formik.errors.description === "Description is required" ? (
                    <div className="email-validate">
                      {formik.errors.description}
                    </div>
                  ) : null}
                </CFormGroup>
                <div 
                className="add-coach-div-cfromgroup"
                
                >
                  {formik.touched.description &&
                  formik.errors.description !== "Description is required" ? (
                    <div className="email-validate">
                      {formik.errors.description}
                    </div>
                  ) : null}
                </div>{" "}
              </CCardBody>

              <CCardFooter 
               className="addcoachbios-footer"
              
              >
                {loading ? (
                  <div className="spinner-border text-success" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <CButton
                    type="submit"
                    disabled={disable}
                    className='width-5rem'
                   
                    color="success"
                  >
                    {params.id ? <strong>Update</strong> : <strong>Add</strong>}
                  </CButton>
                )}

                <CButton
                 className='width-5rem'
                
                  color="danger"
                  onClick={() => {
                    history.push({
                      search: location.state ? location.state.prevSearch : "",
                      pathname: location.state.prevPath,
                      state: { data: null, type: null },
                    });
                  }}
                >
                  <strong>Cancel</strong>
                </CButton>
              </CCardFooter>
            </CCard>
          </CForm>
        </CCol>
      </CRow>
    </div>
  );
}
