import { useSelector } from 'react-redux';

export default function usePermissions(currentTab) {


    const permissions = useSelector(state => state.permissionReducer.permissions);

    let updatedTabs = [];
    let permissionArray;
    permissions?.forEach(perm => {
        updatedTabs.push(perm.module.replace(/ /g, '').toLowerCase());
        if (perm.module.replace(/ /g, '').toLowerCase() === currentTab) {
            permissionArray = perm.permissions;
        }
    })
    

    if (updatedTabs.includes(currentTab)) {
        return {
            status: true,
            pushto: null,
            permissionArray
        }
    } else {
        return {
            status: false,
            pushto: `/${updatedTabs[0]}`,
            permissionArray
        }
    }

}
