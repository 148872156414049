import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CPagination,
  CRow,
  CSwitch,
  CTooltip,
} from "@coreui/react";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

import usePermissions from "src/hooks/usePermissions";
import { deleteRequest, getRequest, putRequest } from "src/utils/AxiosUtils";

const getBadge = (status) => {
  switch (status) {
    case "Active":
      return "success";
    case "Inactive":
      return "secondary";
    case "Pending":
      return "warning";
    case "Banned":
      return "danger";
    default:
      return "primary";
  }
};

const fields = [
  { key: "name", label: "Name", _style: { fontFamily: "Poppins" } },
  { key: "email", label: "Email", _style: { fontFamily: "Poppins" } },
  { key: "phone_number", label: "Mobile", _style: { fontFamily: "Poppins" } },
  {
    key: "coach_specialization_categories",
    label: "Category",
    _style: { fontFamily: "Poppins" },
  },
  {
    key: "employee_ranks",
    label: "Team Level",
    _style: { fontFamily: "Poppins" },
  },
  { key: "status", label: "Status", _style: { fontFamily: "Poppins" } },
  {
    key: "show_details",
    label: "Action",
    _style: {
      minWidth: "12rem",
      height: "4.2rem",
      justifyContent: "space-evenly",
      alignItems: "center",
      fontFamily: "Poppins",
    },
    sorter: false,
    filter: false,
  },
];
const CoachAdministartion = () => {
  const history = useHistory();
  const isPermited = usePermissions("coachadministration");
  if (!isPermited.status) {
    history.push(isPermited.pushto);
  }

  const token = localStorage.getItem("s_token");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const queryPageSEarch = useLocation().search.match(
    /search=([A-Za-z0-9 _@]+)/,
    ""
  );
  const queryRankName = useLocation().search.match(
    /employeeRankName=([0-9]+)/,
    ""
  );
  const queryCoachSpecializtion = useLocation().search.match(
    /coachspecialization=([0-9]+)/,
    ""
  );
  const queryCoachRating = useLocation().search.match(/rating=([0-9]+)/, "");
  const queryStatus = useLocation().search.match(/status=([0-9-]+)/, "");
  const currentRankName =queryRankName && queryRankName[1] ? queryRankName[1] : "";
  const currentCoachSpecialization =queryCoachSpecializtion && queryCoachSpecializtion[1] ? queryCoachSpecializtion[1]: "";
  const currentCoachRating =queryCoachRating && queryCoachRating[1] ? queryCoachRating[1] : "";
  const currentStatus = queryStatus && queryStatus[1] ? queryStatus[1] : "";
  const currentPageSearch =queryPageSEarch && queryPageSEarch[1] ? queryPageSEarch[1] : "";
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [active, setActive] = useState(null);
  const [enableModal, setEnableModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ranks, setRankList] = useState([]);
  const [rankinputval, setrankinputval] = useState("");
  const [statusInputval, setStatusInputval] = useState("");

  const [onInputChange, setOnInputChange] = useState({
    onsearchCHange: currentPageSearch ? currentPageSearch : "",
    onRankChange: currentRankName ? currentRankName : "",
    onCoachSpecializationChagne: currentCoachSpecialization
      ? currentCoachSpecialization
      : "",
    onRatingChange: currentCoachRating ? currentCoachRating : "",
    onStatusChange: currentStatus ? currentStatus : "",
  });

  const pageChange = (newPage) => {
    let newPage1 = newPage;
    if (newPage1 === 0) {
      newPage1 = 1;
    }
    currentPage !== newPage &&
      history.push(
        `/coachadministration?search=${onInputChange.onsearchCHange}&&employeeRankName=${onInputChange.onRankChange}&&page=${newPage1}&&coachspecialization=${onInputChange.onCoachSpecializationChagne}&&status=${onInputChange.onStatusChange}`
      );
  };

  const toggleEnable = (id, Active) => {
    setUserId(id);

    setActive(Active);
    setEnableModal(!enableModal);
  };

  const handleEnable = async () => {
    try {
      setEnableModal(!enableModal);
      let pass;
      if (active) {
        pass = 0;
      } else {
        pass = 1;
      }
      await putRequest("blockUnblockCoach", {
        coach_id: userId,
        status: pass,
      });
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setRefresh(!refresh);
    }
  };

  const toggleDelete = (id) => {
    setUserId(id);
    setDeleteModal(!deleteModal);
  };

  const handleDelete = async () => {
    try {
      setDeleteModal(!deleteModal);

      await deleteRequest(`deleteCoach/${userId}`);
      toast.success("Coach Deleted Successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setRefresh(!refresh);
    }
  };

  const handleSearchChange = (e) => {
    setOnInputChange((preState) => {
      return {
        ...preState,
        onsearchCHange: e.target.value,
      };
    });
  };

  const handleRankChange = (e) => {
    setrankinputval(e);
    setOnInputChange((preState) => {
      return {
        ...preState,
        onRankChange: e?.id || "",
      };
    });
  };

  const handleSearch = async () => {
    (currentPageSearch !== onInputChange.onsearchCHange ||
      currentRankName !== onInputChange.onRankChange ||
      currentCoachSpecialization !==
        onInputChange.onCoachSpecializationChagne ||
      currentStatus !== onInputChange.onStatusChange ||
      currentCoachRating !== onInputChange.onRatingChange) &&
      history.push(
        `/coachadministration?search=${onInputChange.onsearchCHange}&&employeeRankName=${onInputChange.onRankChange}&&page=${page}&&coachspecialization=${onInputChange.onCoachSpecializationChagne}&&status=${onInputChange.onStatusChange}`
      );
  };



  const handleReset = () => {
    setrankinputval(null);
    setStatusInputval(null);

    setOnInputChange((preState) => {
      return {
        ...preState,
        onsearchCHange: "",
        onRankChange: "",
        onCoachSpecializationChagne: "",
        onRatingChange: "",
        onStatusChange: null,
      };
    });

    let newPage = page;
    if (newPage === 0) {
      newPage = 1;
    }
    history.push(`/coachadministration?search=&&page=${newPage}`);
  };

  useEffect(() => {
    if (currentStatus) {
      if (currentStatus === "0") {
        setStatusInputval({
          name: "Inactive",
          id: 0,
        });
      } else if (currentStatus === "1") {
        setStatusInputval({
          name: "Active",
          id: 1,
        });
      }
    }

    const getEmployeeRankList = async () => {
      try {
        const datas = await getRequest(`listEmployeeRanks`);
       
        if (datas.data.status === 200) {
          datas.data.body.data.rows.map((item) => {
            if (currentRankName) {
              if (Number(item.id) === Number(currentRankName)) {
                setrankinputval(item);
              }
            }
            item._classes = "catTableItem";
            return item;
          });
          setRankList(datas.data.body.data.rows);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {}
    };

    const getData = async () => {
      let rankText;
      let searchText;
      let statusText;
      if (currentPageSearch) {
        searchText = `searchKey=${currentPageSearch}`;
      } else {
        searchText = "";
      }
      if (currentRankName) {
        rankText = `employee_rank_id=${currentRankName}`;
      } else {
        rankText = "";
      }
      if (currentStatus) {
        statusText = `status=${currentStatus}`;
      } else {
        statusText = "";
      }

      try {
        setLoading(true);
        const datas = await getRequest(
          `getCoachList?offset=${currentPage}&&limit=${10}&&${searchText}&&${rankText}&&${statusText}`
        );

        datas.data.body.data.rows.map((item) => {
          item._classes = "catTableItem";
          return item;
        });

        setData(datas.data.body.data);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getData();
    getEmployeeRankList();

    currentPage !== page && setPage(currentPage);
  }, [
    currentPage,
    currentPageSearch,
    refresh,
    token,
    page,
    currentRankName,
    currentCoachSpecialization,
    currentCoachRating,
    currentStatus,
  ]);

  const optionStyles = {
    input: (styles) => ({
      ...styles,
      width: "6rem",
      zIndex: "100",
      marginRight: "4rem",
    }),
  };

  return (
    <div>
      <CRow>
        <CModal
          show={location.state && location.state.data ? true : false}
          size="sm"
          backdrop={false}
          color="warning"
        >
          <CModalHeader closeButton>
            {location.state && location.state.data !== null
              ? location.state.data
              : ""}
          </CModalHeader>
        </CModal>
        <CModal
          show={enableModal}
          centered={true}
          color="warning"
          onClose={setEnableModal}
          backdrop={true}
          style={{ fontFamily: "Poppins" }}
        >
          <CModalHeader style={{ height: "3rem" }}>
            <CModalTitle>
              {active ? "Deactivate User?" : "Activate User?"}
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            {active
              ? "Are you sure you want to Deactivate the Coach?"
              : "Are you sure you want to Activate the Coach?"}
          </CModalBody>
          <CModalFooter  
          className='height-4rem'
        
          >
            <CButton color="success" onClick={handleEnable}>
              Yes
            </CButton>
            <CButton color="secondary" onClick={() => setEnableModal(false)}>
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
        <CModal
          show={deleteModal}
          centered={true}
          backdrop={true}
          color="warning"
          onClose={setDeleteModal}
        >
          <CModalHeader>
            <CModalTitle>Delete Coach?</CModalTitle>
          </CModalHeader>
          <CModalBody>
            Are you sure you want to Delete the Coach? This coach bios may get
            deleted along with it.
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" onClick={handleDelete}>
              Yes
            </CButton>{" "}
            <CButton color="secondary" onClick={() => setDeleteModal(false)}>
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
        <CCol xxl={12}>
          <CCard>
            <CCardHeader
            className='admin-management-ccardheader'
            
            >
              <h2>
                <strong>Coach Administration</strong>
              </h2>
              {isPermited.permissionArray.includes(1) && (
                <CButton 
                className='width-8rem'
               
                  color="success"
                  onClick={() => {
                    history.push({
                      pathname: `/coachadministration/addcoach`,
                      state: { prevPath: location.pathname },
                    });
                  }}
                >
                  <strong>Add New Coach</strong>
                </CButton>
              )}
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={data.rows}
                fields={fields}
                hover
                border
                addTableClasses="table-class"
                striped
                loading={loading}
                noItemsViewSlot={
                  !loading ? (
                    <div 
                    className="admin-management-no-result"
                  
                    >
                      No Results
                    </div>
                  ) : (
                    <div  className="height-14"
               
                    ></div>
                  )
                }
                clickableRows
                overTableSlot={
                  <CCol 
                  className='admin-management-table'
                  
                    md="14"
                  >
                    <div  className="display-flex"
                 
                    >
                      <CInput
                      className='minHeightMarginright'
                      
                        value={onInputChange.onsearchCHange}
                        onChange={handleSearchChange}
                        autocomplete="off"
                        id="input1-group1"
                        name="input1-group1"
                        placeholder="Search by Coach Name, Email, Mobile"
                      />
                      <Select
                        styles={optionStyles}
                        onChange={(e) => {
                          handleRankChange(e);
                        }}
                        value={rankinputval}
                        name="employee_rank_ids"
                        isSearchable={true}
                        isClearable={false}
                        placeholder="Select Team Level"
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => `${option.id}`}
                        options={ranks }
                      />
                    </div>

                    <div>
                      <CButton
                        onClick={handleSearch}
                        disabled={
                          !onInputChange.onsearchCHange &&
                          !rankinputval &&
                          !statusInputval
                        } 
                        className='margin-left-1rem'
                    
                        color="info"
                      >
                        Search
                      </CButton>
                      <CButton
                        onClick={() => {
                          handleReset();
                        }} 
                        className='margin-left-1rem'
                   
                        color="info"
                      >
                        Reset
                      </CButton>
                    </div>
                  </CCol>
                }
                scopedSlots={{
                  status: (item) => (
                    <td>
                      {item.status === 1 ? (
                        <CBadge 
                        className='admin-management-badget'
                       
                          shape="pill"
                          color={getBadge("Active")}
                        >
                          Active
                        </CBadge>
                      ) : item.status === 2 ? (
                        <CBadge 
                         className='admin-management-badget'
                        
                          shape="pill"
                          color={getBadge("Banned")}
                        >
                          Deleted
                        </CBadge>
                      ) : item.status === 0 ? (
                        <CBadge 
                         className='admin-management-badget'
                    
                          shape="pill"
                          color={getBadge("Banned")}
                        >
                          Inactive
                        </CBadge>
                      ) : null}
                    </td>
                  ),
                  employee_ranks: (item) => {
                    let str = "";
                    item.employee_ranks.forEach((special, index) => {
                      str =
                        index > 0
                          ? str + `, ${special.name}`
                          : str + ` ${special.name}`;
                    });
                    return str ? (
                      <CTooltip content={`${str}`} placement={"top-start"}>
                        <td>
                          {item?.employee_ranks.length > 0 ? (
                            item.employee_ranks.length > 1 ? (
                              <div  
                              className="color-0d86ff"
                          
                              >
                                {item.employee_ranks.length}
                              </div>
                            ) : (
                              item.employee_ranks[0].name
                            )
                          ) : (
                            ""
                          )}
                        </td>
                      </CTooltip>
                    ) : (
                      <div></div>
                    );
                  },
                  coach_specialization_categories: (item) => {
                    let str = "";
                    item.coach_specialization_categories.forEach(
                      (special, index) => {
                        str =
                          index > 0
                            ? str + `, ${special.name}`
                            : str + ` ${special.name}`;
                      }
                    );
                    return str ? (
                      <CTooltip content={`${str}`} placement={"top-start"}>
                        <td>
                          {item?.coach_specialization_categories.length > 0 ? (
                            item.coach_specialization_categories.length > 1 ? (
                              <div  
                               className="color-0d86ff"
                            
                               >
                                {item.coach_specialization_categories.length}
                              </div>
                            ) : (
                              item.coach_specialization_categories[0].name
                            )
                          ) : (
                            ""
                          )}
                        </td>
                      </CTooltip>
                    ) : (
                      <td></td>
                    );
                  },

                  show_details: (item, index) => {
                    return (
                      <td>
                        <div
                        
                          className="py-2  coach-bios"
                        >
                          <CTooltip content={`Chat `} placement={"top-start"}>
                            <FontAwesomeIcon
                              color="green"
                              size="lg"
                              className="cursor-pointer"
                           
                              onClick={() =>
                                history.push({
                                  pathname: `/coachadministration/chatcoach/${item.id}`,
                                })
                              }
                              icon={faFacebookMessenger}
                            />
                          </CTooltip>
                          {isPermited.permissionArray.includes(2) && (
                            <CTooltip content={`View `} placement={"top-start"}>
                              <FontAwesomeIcon
                                color="green"
                                size="lg"
                                className="cursor-pointer"
                              
                                onClick={() =>
                                  history.push({
                                    pathname: `/coachadministration/viewcoach/${item.id}`,
                                    state: {
                                      item,
                                      prevPath: location.pathname,
                                      prevSearch: location.search,
                                    },
                                  })
                                }
                                icon={faEye}
                              />
                            </CTooltip>
                          )}
                          {isPermited.permissionArray.includes(3) && (
                            <CTooltip content={"Edit "} placement={"top-start"}>
                              <CIcon 
                              className="admin-management-ctooltip"
                              
                                size="lg"
                                onClick={() =>
                                  history.push({
                                    pathname: `/coachadministration/editcoach/${item.id}`,
                                    state: {
                                      item,
                                      prevPath: location.pathname,
                                      prevSearch: location.search,
                                    },
                                  })
                                }
                                content={freeSet.cilPencil}
                              />
                            </CTooltip>
                          )}
                          {isPermited.permissionArray.includes(3) && (
                            <CTooltip
                              content={
                                item.status === 1
                                  ? "Deactivate User"
                                  : "Activate User"
                              }
                              placement={"top-start"}
                              interactive={true}
                              trigger="mouseenter"
                            >
                              <div>
                                <CSwitch
                                  onChange={() =>
                                    toggleEnable(item.id, item.status === 1)
                                  }
                                  size="sm"
                                  variant={"3d"}
                                  color={"success"}
                                  checked={item.status === 1}
                                />
                              </div>
                            </CTooltip>
                          )}
                          {isPermited.permissionArray.includes(4) && (
                            <CTooltip
                              content={`Delete `}
                              placement={"top-start"}
                              interactive={true}
                              trigger="mouseenter"
                            >
                              <CIcon
                                onClick={() => toggleDelete(item.id)}
                                size="lg"

                                className="admin-management-cion-trash"
                               
                                content={freeSet.cilTrash}
                              />
                            </CTooltip>
                          )}
                        </div>
                      </td>
                    );
                  },
                }}
              />
              {data.count ? (
                <CPagination
                  activePage={page}
                  pages={Math.ceil(data.count / 10)}
                  onActivePageChange={pageChange}
                  doubleArrows={true}
                  align="start"
                />
              ) : null}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};

export default CoachAdministartion;
