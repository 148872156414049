import React, { Component } from "react";

import "./scss/style.scss";
import "./App.css";
import Main from "./Main";



const App = () => {

    return (
      <div className="App">
        <Main />
      </div>
      
    );
}

export default App;
